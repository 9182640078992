<template>
  <div class="dropdown">
    <input type="text" v-model="searchText" @input="filterOptions" @click="toggleDropdown">
    <ul v-show="isDropdownOpen">
      <li v-for="option in filteredOptions" :key="option.id" @click="toggleSelection(option)">
        <input type="checkbox" :value="option.id" v-model="selectedOptions">
        {{ option.name }}
      </li>
    </ul>
    <div v-if="selectedOptions.length > 0" class="selected-options">
      {{ selectedOptions.length > 1 ? `${selectedOptions.length} selected` : selectedOptions[0].name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDownAutoComplete',
  data() {
    return {
      searchText: '',
      isDropdownOpen: false,
      options: [
        { id: 1, name: 'Option 1' },
        { id: 2, name: 'Option 2' },
        { id: 3, name: 'Option 3' },
        // Add more options as needed
      ],
      selectedOptions: []
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(option =>
          option.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    filterOptions() {
      // Implement debounce or throttle if necessary
      // For simplicity, debounce is not included in this example
      this.isDropdownOpen = true; // Open dropdown when typing
    },
    toggleSelection(option) {
      const index = this.selectedOptions.findIndex(item => item.id === option.id);
      if (index === -1) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  max-height: 200px; /* Set a max height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
}

li {
  padding: 8px 12px;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}

.selected-options {
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
}
</style>
