<template>
  <label :for="labelId">
    <span v-if="label">{{label}}</span>
    <input :id="labelId" :value="modelValue" :disabled="disabled" :class="{disabled}" @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs"/>
    <small class="note">{{note}}</small>
    <small class="error" v-if="errorNote !== null">{{errorNote}}</small>
  </label>
</template>

<script>
export default {
  name: "MBPInput",
  props: ['label', 'disabled', 'modelValue', 'labelId', 'errorNote', 'note', 'hasError']
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";

label {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input {
  @extend .inputBase;
  cursor: text;
  -webkit-appearance: none;
  border: 2px solid var(--notficationBorderColor);
  text-align: unset;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: var(--darkerBorder);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .8;
  }
}

small {

  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;

  &.error {
    color: var(--red);
    background: var(--opacityRed);
  }

  &.note {
    font-weight: 600;
    color: var(--lightYellow);
    background: var(--lightYellowOpacity);
  }
}


</style>
