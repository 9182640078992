<template>
  <div class="sort" @click="$emit('sort')" :dir="uiDirection
">
    <i class="ri-arrow-down-s-line sortSign" :class="{active: currentSortValue === sortValue}" v-if="currentSortDirection === -1"/>
    <i class="ri-arrow-up-s-line sortSign" :class="{active: currentSortValue === sortValue}" v-if="currentSortDirection === 1"/>
  </div>

</template>

<script>
export default {
  name: "TableSortTH",
  props: ['sectorSymbol', 'currentSortValue', 'currentSortDirection', 'sortValue', 'showSign']
}
</script>

<style scoped lang="scss">

.sort {
  cursor: pointer;
}

.sortSign {
  color: #ccc;
  opacity: .5;
}

.active {
  color: var(--activeSortSignColor);
  opacity: 1;
}
</style>
