<template>

  <section>
    <div class="searchContainer">
      <div id="closeSearchBar" @click="$emit('close')"><i class="ri-close-line"/></div>

      <div class="tabs round scroll border" style="margin-top: 0">
        <div v-for="(tab, tIndex) in tabs" class="tab" :class="{active: active === tab.key}" @click="goToTab(tab.key)">
          {{tab.label}}
        </div>
      </div>

        <section id="trending" v-show="active === 'trending'">
          <SocialTrend @close="$emit('close')" :is-partial="true"/>
        </section>

      <keep-alive>
        <section id="filter" v-show="active === 'filter'">
          <SymbolFilterPanel/>
        </section>
      </keep-alive>

      <keep-alive>
        <section id="freeSearch" v-show="active === 'freeSearch'">
          <symbols-search :with-preview="true" :as-page="true" :overflow="true" :big="true"/>
        </section>
      </keep-alive>
    </div>
    <OverlayUI>
    </OverlayUI>
  </section>
</template>

<script>
import OverlayUI from "./Overlay";
import SymbolsSearch from "./SymbolsSearch";
import SymbolFilterPanel from "./SymbolFilterPanel";
import SocialTrend from "../../views/SocialTrend";

export default {
  name: "SearchBar",
  components: {SocialTrend, SymbolFilterPanel, SymbolsSearch, OverlayUI},
  data() {
    return {
      tabs: [{
        label: this.dictionary.search.tabs.freeSearch,
        key: 'freeSearch'
      }, {
        label: this.dictionary.search.tabs.trending,
        key: 'trending'
      }, {
        label: this.dictionary.search.tabs.filter,
        key: 'filter'
      }],
      active: 'freeSearch'
    }
  },
  methods: {
    goToTab(tab) {

        this.active = tab
      // if (tab === 'trending') {
      //
      //   this.$router.push('/social');
      //   this.$router.go(1);
      //   this.$emit('close');
      // } else {
      // }
    }
  }
}
</script>

<style scoped lang="scss">

.searchContainer {
  position: absolute;
  left: 50%;
  top: 10%;
  background: var(--bgColor);
  padding: 1.5rem;
  border-radius: 12px;
  transform: translateX(-50%);
  z-index: 999999999;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  height: calc(93vh - 10%);
  overflow: hidden;
  box-sizing: border-box;

  @media(min-width: 1460px) {
    width: 70%;
  }
  @media (max-width: 700px) {
    width: 95%;
    top: 5%;
  height: calc(99vh - 5%);
  }
}

#closeSearchBar {
  position: absolute;
  padding: 1rem;
  margin: -2rem;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: var(--tableHoverColor);
    border-radius: 50%;
  }
}
</style>
