import {onBeforeUnmount, onUnmounted, getCurrentInstance} from "vue";
import {useRoute, onBeforeRouteLeave} from "vue-router";

export default function quoteStreaming() {

    const { appContext } = getCurrentInstance();
    const route = useRoute();
    const Socket =  appContext.app.config.socket;
    let symbolsList = new Set();

    onBeforeRouteLeave(() => {
        Socket.leaveStream(route.name);

    });

    function updateStream (symbols, location = null) {

        symbolsList = new Set();


        if (symbols instanceof Set) symbols = Array.from(symbols);
        symbols.forEach(symbol => symbolsList.add(symbol.toUpperCase().trim()));

        Socket.updateStream(Array.from(symbolsList), location || route.name);
    }

    function leaveStream(location = null) {
        Socket.leaveStream(location || route.name);

    }

    return {
        updateStream,
        leaveStream
    }
}
