<template>
  <div class="table">
    <table cellpadding="0" cellspacing="0">
      <thead>
        <HeadCell v-for="(th, thIndex) in thead" :cell="th"/>
      </thead>

      <tbody>
      <tr v-for="(row, rowIndex) in tbody">
        <td v-for="(cell, cellIndex) in row">
          <component :is="cell.type" :value="cell.value"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import HeadCell from "./HeadCell";
import SymbolLabel from "../symbols/symbol";
import StringCell from "./StringCell";
import PriceCell from "./PriceCell";

export default {
  name: "DataGrid",
  components: {SymbolLabel, StringCell, PriceCell, HeadCell},
  props: ['thead', 'tbody'],
  data () {
    return {
      sortBy: null,
      sortDir: null
    }
  },
  computed: {
    tableData () {

    }
  }
}
</script>

<style scoped lang="scss">

table {
  width: 100%;
}
</style>
