<template>
  <div>
    <div class="row calendarHeadersRow">
      <div class="cell"> {{ dictionary.calendars.headers.symbol }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.reportTime }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.eps }}</div>
    </div>

    <section v-for="(day, dayIndex) in calendar">
      <div class="calendarDateRow">
        {{ day.format }}
      </div>
      <div v-for="(event, eventIdx) in day.events" :key="event._id" class="row">
        <div class="cell">
          <router-link :to="`/symbol/${event.symbol}`">{{ event.symbol }}</router-link>
        </div>
        <div class="cell center" :class="{preMarket: event.releaseTime === 'bmo', afterMarket: event.releaseTime === 'amc'}">
          <i class="ri-sun-line" v-if="event.releaseTime === 'bmo'"/>
          <i class="ri-moon-line" v-else />
          {{ dictionary.calendars.labels[event.releaseTime === 'bmo' ? 'preMarket' : 'afterMarket'] }}</div>
        <div class="cell ltr center">{{ event.epsEstimate }}</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "EarningsCalendar",
  props: ['calendar'],
  data() {
    return {}
  },
  computed: {
    earnings() {

      let earnings = {};

      for (let k in this.calendar) {
        earnings[k] = this.calendar[k];
        earnings[k].events = earnings[k].events.sort((a, b) => {
          return a.releaseTime.localeCompare(b.releaseTime);
        });
      }

    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">

@import "../../assets/style/calendar";

.preMarket {
  //color: var(--highlightColor);
}

.afterMarket {
  color: var(--afterMarketColor);
}

.cell {
  width: 33%;

  a{
    text-decoration: none;
    color: black;
  }
}
</style>
