<template>
  <div class="loaderWrapper" :class="{transparentBg}">
    <div class="loader"/>
  </div>
</template>

<script>
export default {
  name: "BigLoader",
  props: ['transparentBg']
}
</script>

<style scoped lang="scss">


.loaderWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  background: var(--bgColor);
  z-index: 9;
}

.loaderWrapper.transparentBg {
  background: transparent;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(var(--highlightColor), 10%, transparent);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn)
  }
}
</style>
