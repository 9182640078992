<template>
  <div style="margin-top: -2rem;" class="wrapper" :dir="user.systemLanguage === 'en' ? 'ltr' : 'rtl'">
    <h1 v-if="!isBetaOver">{{ title[systemLanguage || 'en'] }}</h1>
    <p v-if="!isBetaOver" style="font-size: 18px;" v-html="thankYou[user.systemLanguage || 'en']">
    </p>

    <p class="notification" v-if="!isBetaOver" v-html="body[user.systemLanguage || 'en']"></p>
    <p class="notification" v-if="isBetaOver" v-html="trialEnded[user.systemLanguage || 'en']"></p>


    <div class="offers" v-if="user.specialOffer">
      <div class="pricingOffer">
        <div class="title">{{ user.specialOffer.name }}</div>
        <div class="title" v-if="user.specialOffer.description">{{ user.specialOffer.description }}</div>
        <div class="price">${{ user.specialOffer.price}} {{ user.specialOffer.billingPeriod}}</div>

        <div class="note" style="margin-bottom: .5rem;">
          ${{user.specialOffer.price * 12}} {{betaOffer.yearly[systemLanguage || 'en']}}
        </div>

        <PaymentButton :subscription-type="'monthly'" :is-settings="true" :user="user" :price-id="user.specialOffer.priceId"/>
      </div>
    </div>

    <div class="offers" v-if="isBetaOver && !user.specialOffer">
      <div class="pricingOffer" v-for="(offer, oIdx) in offers">
        <div class="title">{{ offer.title[user.systemLanguage] }}
          <div class="discount" :class="{none: !offer.discount}">
            {{ offer.discount?.[user.systemLanguage || 'en'] || '&nbsp;' }}
          </div>
        </div>
        <div class="price" :dir="uiDirection" v-html="offer.price[user.systemLanguage || 'en']"></div>

        <div class="note" style="margin-bottom: .75rem; ">
          {{ offer.yearlyTotal?.[user.systemLanguage || 'en'] || '&nbsp;' }}
        </div>


        <PaymentButton :subscription-type="offer.subscriptionType" :is-settings="true" :user="user"
                       :price-id="offer.priceId"/>
      </div>
    </div>

    <div class="offers" v-if="!isBetaOver && !user.specialOffer">
      <div class="pricingOffer cross">
        <div class="title">{{ betaOffer.monthly[user.systemLanguage || 'en'] }}<br/>&nbsp;</div>
        <div class="price">39.99</div>
        <div class="note">
          $479 {{ betaOffer.yearly[user.systemLanguage || 'en'] }}
        </div>
      </div>

      <div class="pricingOffer selected">
        <div class="title">
          {{ betaOffer.monthly[user.systemLanguage || 'en'] }}<br/>{{ betaOffer.oneTIme[user.systemLanguage || 'en'] }}
        </div>
        <div class="price">$14.99</div>
        <div class="note">
          $180 {{ betaOffer.yearly[user.systemLanguage || 'en'] }}
        </div>
      </div>
    </div>

    <p style="font-weight: bold; font-size: 20px;"  v-if="!isBetaOver">{{ activate[user.systemLanguage || 'en'] }}</p>

    <div style="display: block; margin: 0 auto; text-align: center" v-if="!isBetaOver">
      <PaymentPartial @close="$emit('close')" :largeCTA="true" :user="user"/>
    </div>
    <br/><br/>
    <p style="font-size: 20px;" v-if="isBetaOver" v-html="contactUs[user.systemLanguage || 'en']"></p>
    <p style="font-size: 20px;" v-if="!isBetaOver" v-html="closing[user.systemLanguage || 'en']">
    </p>

    <div style="display: block; margin: 0 auto; text-align: center"  v-if="!isBetaOver">

      <MBPButton class="action mbp" @click="$emit('close')" :label="dictionary?.buttons?.continueToApp || 'en' "/>
      </div>

  </div>
</template>

<script>
import MBPButton from "../../components/ui/controls/MBPButton";
import PaymentPartial from "./PaymentPartial";
import PaymentButton from "./PaymentButton";

export default {
  name: "TrialEnded",
  props: ['user'],
  components: {PaymentButton, PaymentPartial, MBPButton},
  methods: {
    checkIfBetaIsOver() {
      if (this.user?.status === 'trialEnded') return true;

      const now = new Date();
      const targetDate = new Date('2024-05-09T12:00:00');
      return now > targetDate;
    }
  },
  mounted() {
    this.isBetaOver = this.checkIfBetaIsOver()
  },
  data() {
    return {
      isBetaOver: false,
      title: {
        en: 'Level Up Your Trades: Marketbit.pro Exits Beta!',
        he: 'עולים רמה במסחר: Marketbit.pro יוצאים מבטא!'
      },
      activate: {
        en: `I'd like to activate my special offer instead of paying
      $39.99/mo:`,
        he: 'אשמח לממש את ההצעה המיוחדת במקום לשלם $39.99 לחודש:'
      },
      thankYou: {
        en: 'Thank you for being a beta tester!<br>Together, we\'re making Marketbit.pro the ultimate platform for traders and investors.',
        he: 'תודה לכם שלקחתם חלק בבטא!' +
            '<br/>' +
            'ביחד, אנחנו הופכים את Marketbit.pro לפלטפורמה האולטימטיבית לסוחרים ולמשקיעים.'
      },
      body: {
        en: `As of <b>May 9th</b>, Marketbit.pro will be available to premium users only.<br/>
        Please accept our special one-time beta offer! <br/><br/>A massive discount for your first year:<b style="font-size: 24px;">$14.99</b> per month for
        instead of $39.99! <br/>The offer will expire on May 9th - don't miss out!<br/>`,
        he: `החל מ<b>התשיעי במאי</b>, Marketbit.pro תהיה זמינה רק למשתמשי פרימיום בלבד.<br>בבקשה קבלו הטבת בטא חד פעמית!<br/><br/>
הנחה ענקית לשנה הראשונה שלכם: <b style="font-size: 24px">$14.99</b>
 לחודש במקום $39.99!
<br/>
ההצעה תקפה עד התשיעי במאי - אל תפספסו!`
      },
      closing: {
        en: `You can secure the offer anytime in your <a href="/settings">settings</a> page.<br/>
        We're here to help you reach your trading goals, so feel free to contact us at <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a> for any question.<br/><br/>
        Thank you,<br/>
        Marketbit.pro team`,
        he: `
        ניתן לממש את ההטבה שלכם בכל רגע ב<a href="/settings">עמוד ההגדרות</a> שלכם.<br/>
        אנחנו כאן כדי לעזור לכם להגיע למטרות המסחר וההשקעה שלכם, אז הרגישו בנוח לפנות אלינו באימייל <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>
         לכל שאלה או בעיה<br/><br/>

        תודה,<br/>
        צוות Marketbit.pro
        `
      },
      betaOffer: {
        monthly: {
          he: 'חודשי',
          en: 'Monthly'
        },
        oneTIme: {
          he: 'הצעה חד-פעמית',
          en: 'A ONE TIME OFFER'
        },
        yearly: {
          he: 'שנתי',
          en: 'yearly'
        }
      },
      trialEnded: {
        he: `תקותקופת הניסיון שלך הסתיימה, אבל ההזדמנות לשדרג את המסחר שלך רק מתחילה.<br/>אנחנו מקווים שגילית איך המערכת שלנו יכולה להיות שותפה חשובה בהצלחה שלך.`,
        en: 'Your trial with us is over, but the chance to elevate your trading is just beginning.<br/>We hope you\'ve discovered how our system can be a valuable partner in your success.'
      },
      contactUs: {
        he: ` אנחנו כאן כדי לעזור לכם להגיע למטרות המסחר וההשקעה שלכם,<br/>אז הרגישו בנוח לפנות אלינו באימייל <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>
         לכל שאלה או בעיה<br/><br/>

        תודה,<br/>
        צוות Marketbit.pro`,
        en: `We're here to help you reach your trading goals,<br/>so feel free to contact us at <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a> for any question.<br/><br/>
        Thank you,<br/>
        Marketbit.pro team`
      },
      offers: [{
        subscriptionType: 'monthly',
        priceId: process.env.VUE_APP_MONTHLY_PRICE_ID,
        title: {
          he: 'חודשי',
          en: 'Monthly'
        },
        price: {
          he: '<br/><small>&nbsp;</small>$39.99 לחודש',
          en: '$39.99/per month<br/><small>&nbsp;</small></small>'
        },
        discount: null,
        yearlyTotal: {
          he: 'סה״כ $479 לשנה',
          en: 'Total of $479 per year'
        }
      }, {
        subscriptionType: 'binannual',
        priceId: process.env.VUE_APP_BIANNUAL_PRICE_ID,
        title: {
          he: 'דו-שנתי',
          en: 'Bi-annual'
        },
        price: {
          he: '$168<br/>ל-6 חודשים<br/><small>($28/לחודש)</small>',
          en: '$168<br>per 6 months<br/><small>($28/month)</small>'
        },
        discount: {
          he: '30% הנחה',
          en: '30% off'
        },
        yearlyTotal: {
          he: 'סה״כ $336 לשנה',
          en: 'Total of $336 per year'
        }
      }, {
        subscriptionType: 'annual',
        priceId: process.env.VUE_APP_ANNUAL_PRICE_ID,
        title: {
          he: 'שנתי',
          en: 'Annual'
        },
        price: {
          he: '$240<br/>לשנה<br/><small>($20/לחודש)</small>',
          en: '$240<Br/>per year<br><small>($20/month)</small>'
        },
        discount: {
          he: '50% הנחה',
          en: '50% off'
        },
        yearlyTotal: null
      }],
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";


.wrapper[dir="rtl"] {
  direction: rtl;

  * {
    direction: rtl;
  }
}

h1, p {
  color: white;
  direction: ltr;
  text-align: center;
}

p {
  font-size: 18px;
  line-height: 26px;
  direction: ltr;
  text-align: center;
}

.notification {
  font-size: 20px;
  background: var(--mbpGreenOpacity);
  color: var(--mbpGreen);
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 8px
}

::v-deep {
  a {
    color: var(--mbpGreen);
  }
}

.pricingOffer {
  padding: 1rem;
  margin: .5rem;
  box-sizing: border-box;
  border-radius: 8px;
  background: linear-gradient(45deg, rgba(64, 58, 61, 0.15) 6%, rgba(51, 47, 49, 1) 83%);
  font-size: 18px;
  color: white;
  width: 100%;

  .title {
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .discount {
    border-radius: 8px;
    padding: 5px 10px;
    background: var(--purple);
    width: fit-content;
    margin-bottom: 10px;

    &.none {
      opacity: 0;
    }
  }

  .price {
    font-size: 26px;
    font-weight: bold;
    margin: 1rem 0;

    &[dir="rtl"] {
      text-align: center;
      direction: rtl;
    }
  }
}

.cross {
  opacity: .7;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 105%;
    height: 3px;
    background-color: rgba(255, 0, 0, .4);
    transform: rotate(-45deg);
    transform-origin: center;
    top: 50%;

  }
}

.selected {
  border: 2px solid var(--purple);
  box-shadow: 0 5px 25px 0 var(--opacityPurple);
}

.offers {
  margin: .5rem auto;
  justify-self: center;
  max-width: 990px;
  width: 740px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 660px) {
    flex-direction: column;
    max-width: unset;
    width: 100%;
  }
}

.special {

  .smaller {
    display: block;
    margin-bottom: .5rem;
    font-size: 18px;
  }

  padding: 1rem;
  margin: .5rem;
  border-radius: 8px;
  background: var(--mbpGreen);
  font-size: 22px;
  font-weight: bold;
  color: #111111 !important;
  display: block;
}


</style>
