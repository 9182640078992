<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
import {DateTime} from "luxon";

export default {
  name: "PieChart",
  props: ['series', 'labels', 'formatter'],
  data() {
    return {
      options: {}
    }
  },
  methods: {},
  beforeUnmount() {
    echarts.dispose(this.$refs.chart);
  },
  mounted() {

    const colors = ['#2079f2', 'rgb(227, 158, 0)', '#8843F2', '#0db980', 'rgba(201, 1, 31, 1)']

    if (this.$refs.chart && this.series) {

      let chart = echarts.init(this.$refs.chart);

      this.options = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: {
            color: this.systemTheme === 'darkMode' ? '#ccc' : '#333'
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '60%'],
            avoidLabelOverlap: true,
            label: {
              position: 'outer',
              margin: 100,
            },
            labelLine: {
              length: 55,
              length2: 15,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < chart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: this.series.map((s, index) => {

              if (colors[index]) s.color = colors[index];
              return s;
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      new ResizeObserver(() => {
        chart.resize();
      }).observe(this.$refs.chart);
      chart.setOption(this.options);

    }
  }
}


</script>

<style scoped>
.chart {
  height: 360px;
}
</style>
