<template>
  <section class="sectionPartial">

    <Toast :message="dictionary.settingsPage.notes.dataUpdate" ref="updated"/>
    <VerifyAccountClosingModal v-if="verifyClosingOfAccountModal"
                               @approve="closeAccountApproved"
                               @close="verifyClosingOfAccountModal = false"
    />

    <chart-note :note="dictionary.settingsPage.notes.account"/>

    <form @submit.prevent="void(0)">
      <h4>{{ dictionary.settingsPage.titles.userSettings }}</h4>

      <div class="row mobile">
        <MBPInput :label="dictionary.settingsPage.account.labels.firstName" :error-note="userDetailsErrors.firstName"
                  labelId="firstName" v-model="userSettings.firstName" autocomplete="false" type="text"/>

        <MBPInput :label="dictionary.settingsPage.account.labels.lsatName" :error-note="userDetailsErrors.lastName"
                  labelId="lastName" v-model="userSettings.lastName" autocomplete="false" type="text"/>
      </div>

      <div class="row mobile">
        <MBPInput :label="dictionary.settingsPage.account.labels.email" :error-note="userDetailsErrors.email"
                  :note="userSettings.isGoogleAuth === true ? dictionary.settingsPage.notes.googleAuth : null"
                  labelId="email" v-model="userSettings.email" autocomplete="false" type="email"/>
        <MBPInput :label="dictionary.settingsPage.account.labels.password" :error-note="userDetailsErrors.password"
                  v-model="userSettings.password"
                  labelId="password" autocomplete="false"
                  :note="mustUpdatePassword ? dictionary.settingsPage.notes.updatePassword : null" type="password"/>
      </div>

      <div class="" style="margin-bottom: 1rem">
        <label style="display: block; margin-bottom: .5rem">{{ dictionary.settingsPage.account.labels.systemTheme }}</label>
        <div class="row">
        <toggle-switch
            :small="false" :state="userSettings.systemTheme === 'darkMode'" @stateChanged="userSettings.systemTheme === 'darkMode' ? userSettings.systemTheme = 'lightMode' : userSettings.systemTheme = 'darkMode'; updateTheme(userSettings.systemTheme)"
            :off-text="dictionary.settingsPage.account.labels.lightMode"
            :text="dictionary.settingsPage.account.labels.darkMode"/>
        </div>
      </div>

      <MBPButton :label="dictionary.settingsPage.buttons.saveAccount" :disabled="mustUpdatePassword || invalidEmail"
                 @click="updateUserSettings" class="action"/>
    </form>

    <form @submit.prevent="updateLanguageSettings" v-if="userSettings">
      <h4>{{ dictionary.settingsPage.titles.languageSettings }}</h4>

      <div class="mobile row" :style="systemLanguage === 'en' ? {marginBottom: '10px'} : {}">
        <DropDown
            v-if="systemLanguage"
            @select="systemLanguage = $event.value"
            :min-width="'160px'"
            :label="dictionary.settingsPage.titles.systemLanguage"
            :multi="false"
            :closeOnSelect="true"
            :full-width="false"
            :default-selected="systemLanguage"
            :options="systemLanguages"/>

        <div v-if="systemLanguage !== 'en'">
          <div style="margin: 0rem 0 .5rem">
            {{ dictionary.settingsPage.titles.newsLanguages }}
          </div>

          <div class="checkboxGrid">
            <MBPCheckbox v-for="(lang, lIdx) in systemLanguages"
                         :key="`newslang-${lang.value}`"
                         :value="lang.value"
                         :name="'newslang'"
                         :values="selectedNewsLanguages"
                         @change="changedNewsLanguages"
                         :labelId="`news-${lang.value}`" :label="lang.label"/>
          </div>
        </div>
      </div>


      <MBPButton :label="dictionary.settingsPage.buttons.saveLanguageSettings" class="action"/>
    </form>


    <form @submit.prevent="void(0);">
      <h4>{{ dictionary.settingsPage.titles.subscriptionSettings }}</h4>

      <div v-if="userSettings.status === 'subscribed'">
        <div class="subscriptionBlock">
          <label>{{ dictionary.settingsPage.account.labels.nextPayment }}</label>
          {{ userSettings.subscription?.nextPaymentDate || '--' }}
        </div>

        <div class="subscriptionBlock">
          <label>{{ dictionary.settingsPage.account.labels.subscriptionPrice }}</label>
          ${{ userSettings.subscription?.amount || '0.00' }}
        </div>

        <a :href="userSettings.subscription.updateLink" v-if="userSettings.subscription.updateLink"
           style="color: var(--highlightColor)"
           target="_blank">{{ dictionary.settingsPage.account.labels.updatePaymentCard }}</a>
      </div>

      <div v-if="userSettings.status === 'trial'">
        <div id="trialExpiration">
<!--          <i class="ri-alert-line"/>-->
<!--          <p v-html="dictionary.settingsPage.notes.expiration(userSettings.trialExpirationDate, userSettings.trialExpirationDateDays)"-->
<!--             style="padding: 0; margin: 0; color: var(&#45;&#45;red)"/>-->
        </div>


        <!-- Offers -->

        <div class="offers" v-if="!userSettings.specialOffer">
          <div class="pricingOffer" v-for="(offer, oIdx) in offers">
            <div class="title">{{ offer.title[systemLanguage] }} <div class="discount" :class="{none: !offer.discount}">
              {{offer.discount?.[systemLanguage] || '&nbsp;'}}
            </div></div>
            <div class="price" v-html="offer.price[systemLanguage]"></div>

            <div class="note" style="margin-bottom: .5rem;" v-html="offer.yearlyTotal?.[systemLanguage] || '&nbsp;'">
            </div>

            <PaymentButton :subscription-type="offer.subscriptionType" :is-settings="true" :user="userSettings" :price-id="offer.priceId"/>
          </div>
        </div>

        <!-- End offers -->

        <div class="offers" v-if="userSettings.specialOffer">
          <div class="pricingOffer">
            <div class="title">{{ userSettings.specialOffer.name }}</div>
            <div class="title" v-if="userSettings.specialOffer.description">{{ userSettings.specialOffer.description }}</div>
            <div class="price">${{ userSettings.specialOffer.price}} {{ userSettings.specialOffer.billingPeriod}}</div>

            <div class="note" style="margin-bottom: .5rem;">
              ${{userSettings.specialOffer.price * 12}} {{betaOffer.yearly[systemLanguage || 'en']}}
            </div>

            <PaymentButton :subscription-type="'monthly'" :is-settings="true" :user="userSettings" :price-id="userSettings.specialOffer.priceId"/>
          </div>
        </div>

        <!-- Beta offers -->
<!--        <div v-if="!userSettings.specialOffer">-->
<!--        <div class="offers">-->
<!--          <div class="pricingOffer cross">-->
<!--            <div class="title">{{ betaOffer.monthly[systemLanguage || 'en'] }}<br/>&nbsp;</div>-->
<!--            <div class="price">$39.99</div>-->
<!--            <div class="note">-->
<!--              $479 {{ betaOffer.yearly[systemLanguage || 'en'] }}-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="pricingOffer selected">-->
<!--            <div class="title">{{ betaOffer.monthly[systemLanguage || 'en'] }}<br/>{{ betaOffer.oneTIme[systemLanguage || 'en'] }}</div>-->
<!--            <div class="price">$14.99</div>-->
<!--            <div class="note">-->
<!--              $180 {{ betaOffer.yearly[systemLanguage || 'en'] }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div style="margin: 2rem auto; display: block; text-align: center">-->
<!--          <PaymentPartial :is-settings="true" :user="userSettings"/>-->
<!--        </div>-->
<!--        </div>-->
        <!-- End beta offers -->

      </div>
    </form>

    <form @submit.prevent="void(0)">
      <h4>{{ dictionary.settingsPage.titles.dangerZone }}</h4>
      <MBPButton :label="dictionary.settingsPage.buttons.logout" @click="logout()" class="danger"/>
      <br/><br/>
      <MBPButton :label="dictionary.settingsPage.buttons.closeAccount"
                 @click="verifyClosingOfAccount()"
                 class="danger"/>
    </form>
  </section>
</template>

<script>

import UserService from '../../services/user';

import ChartNote from "../../components/charts/ChartNote";
import MBPButton from "../../components/ui/controls/MBPButton";
import MBPInput from "../../components/ui/controls/MBPInput";
import DropDown from "../../components/ui/DropDown";
import MBPCheckbox from "../../components/ui/controls/MBPCheckbox";
import VerifyAccountClosingModal from "./modals/VerifyAccountClosingModal";

import eventBus from '../../services/events'
import Toast from "../../components/ui/Toast";
import PaymentPartial from "./PaymentPartial";
import ToggleSwitch from "../../components/ui/ToggleSwitch";
import PaymentButton from "./PaymentButton";

export default {
  name: "AccountPartial",
  components: {
    PaymentButton,
    ToggleSwitch,
    PaymentPartial, Toast, VerifyAccountClosingModal, MBPCheckbox, DropDown, MBPInput, MBPButton, ChartNote},
  props: ['userSettings'],
  data() {
    return {
      betaOffer: {
        monthly: {
          he: 'חודשי',
          en: 'Monthly'
        },
        oneTIme: {
          he: 'הצעה חד-פעמית',
          en: 'A ONE TIME OFFER'
        },
        yearly: {
          he: 'שנתי',
          en: 'yearly'
        }
      },
      oneTimeOffer: {
        en: `As of <b>May 1st</b>, Marketbit.pro will be available to premium users only.<br/>
        Please accept our special one-time beta offer! <br/><br/>A massive discount for your first year:<b style="font-size: 24px;">$14.99</b> per month for
        instead of $34.99! <br/>The offer will expire on May 7th - don't miss out!<br/>`,
        he: `החל מ<b>הראשון במאי</b>, Marketbit.pro תהיה זמינה רק למשתמשי פרימיום בלבד.<br>בבקשה קבלו הטבת בטא חד פעמית!<br/><br/>
הנחה ענקית לשנה הראשונה שלכם: <b style="font-size: 24px">$14.99</b>
 לחודש במקום $34.99!
<br/>
ההצעה תקפה עד השביעי במאי - אל תפספסו!`
      },
      offers: [{
        subscriptionType: 'monthly',
        priceId: process.env.VUE_APP_MONTHLY_PRICE_ID,
        title: {
          he: 'חודשי',
          en: 'Monthly'
        },
        price: {
          he: '<br/><small>&nbsp;</small>$39.99 לחודש',
          en: '$39.99/per month<br/><small>&nbsp;</small></small>'
        },
        discount: null,
        yearlyTotal: {
          he: 'סה״כ $479 לשנה',
          en: 'Total of $479 per year'
        }
      }, {
        subscriptionType: 'binannual',
        priceId: process.env.VUE_APP_BIANNUAL_PRICE_ID,
        title: {
          he: 'דו-שנתי',
          en: 'Bi-annual'
        },
        price: {
          he: '$168<br/>ל-6 חודשים<br/><small>($28/לחודש)</small>',
          en: '$168<br>per 6 months<br/><small>($28/month)</small>'
        },
        discount: {
          he: '30% הנחה',
          en: '30% off'
        },
        yearlyTotal: {
          he: 'סה״כ $336 לשנה',
          en: 'Total of $336 per year'
        }
      }, {
        subscriptionType: 'annual',
        priceId: process.env.VUE_APP_ANNUAL_PRICE_ID,
        title: {
          he: 'שנתי',
          en: 'Annual'
        },
        price: {
          he: '$240<br/>לשנה<br/><small>($20/לחודש)</small>',
          en: '$240<Br/>per year<br><small>($20/month)</small>'
        },
        discount: {
          he: '50% הנחה',
          en: '50% off'
        },
        yearlyTotal: null
      }],
      invalidEmail: false,
      mustUpdatePassword: false,
      mustChoosePasswordNote: false,
      originalEmailAddress: null,
      verifyClosingOfAccountModal: false,
      selectedNewsLanguages: [],
      systemLanguages: [{
        value: 'en',
        label: this.dictionary.languages.en
      }, {
        value: 'he',
        label: this.dictionary.languages.he
      }],
      systemLanguage: null,
      userDetailsErrors: {
        firstName: null,
        lastName: null,
        email: null,
        password: null
      }
    }
  },
  watch: {
    'userSettings.email'(newVal) {
      this.checkPasswordRequirement(newVal);
    },
    'userSettings.password'(newVal) {
      this.checkPasswordRequirement(newVal);
    }
  },
  methods: {
    updateTheme(theme) {
      eventBus.emit('themeChange', theme);
    },
    checkPasswordRequirement(emailValue) {
      if (this.userSettings.isGoogleAuth && emailValue !== this.originalEmailAddress) {

        if (!this.userSettings.password || (this.userSettings.password.trim().length === 0)) {
          this.mustUpdatePassword = true;
        } else {
          this.mustUpdatePassword = false;
        }
      } else {
        this.mustUpdatePassword = false;
      }
    },

    async updateLanguageSettings() {

      await UserService.saveUserSettings({
        systemLanguage: this.systemLanguage,
        newsLanguages: this.selectedNewsLanguages,
      }, 'languages');

      this.$refs.updated.show(() => {
      });
      eventBus.emit('changeUiLanguage', this.systemLanguage)
    },
    async updateUserSettings() {

      await UserService.saveUserSettings({
        firstName: this.userSettings.firstName,
        lastName: this.userSettings.lastName,
        email: this.userSettings.email,
        systemTheme: this.userSettings.systemTheme,
        password: this.userSettings.password
      }, 'details');

      this.$refs.updated.show();

    },
    async logout() {
      await UserService.logoutUser();
      location.href = '/'
    },
    verifyClosingOfAccount() {

      this.verifyClosingOfAccountModal = true;
    },
    changedNewsLanguages(lang) {

      const isChecked = lang.target.checked;
      if (isChecked) {
        if (!this.selectedNewsLanguages.includes(lang.target._value)) this.selectedNewsLanguages.push(lang.target._value)
      } else {
        const indexOfLang = this.selectedNewsLanguages.indexOf(lang.target._value);
        if (indexOfLang > -1) this.selectedNewsLanguages.splice(indexOfLang, 1);
      }
    },
    async closeAccountApproved() {

      try {
        await UserService.cancelSubscription();
        location.reload();
      } catch (e) {
        alert('Error. Please try again later.');
      }
    },

  },
  mounted() {
    this.originalEmailAddress = this.userSettings.email;
    this.selectedNewsLanguages = this.userSettings.newsLanguages;
    this.systemLanguage = this.userSettings.systemLanguage;
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general.scss";
@import "../../assets/style/controls.scss";

form {
  background: var(--bgColor);
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  padding: $minSpacing;
  box-shadow: 0 2px 2px -10px rgba(0, 0, 0, 0.02), 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  h4 {
    margin: ($minSpacing * -1) -5px 0;
    transform: translateY(-55%);
    background: var(--bgColor);
    width: fit-content;
    padding: 0 5px;
  }
}

.subscriptionBlock {
  &:last-of-type {
    margin-bottom: 1rem;

  }

  padding: 10px;
  border-bottom: 1px solid var(--notficationBorderColor);

  label {
    font-weight: 500;
  }
}

#trialExpiration {
  color: var(--red);
  font-size: 20px;
  margin-bottom: 1rem;
  gap: 10px;
  display: flex;
  align-items: center;
}

.pricingOffer {
  padding: 1rem;
  margin: .5rem;
  border-radius: 8px;
  background: linear-gradient(45deg, rgba(64,58,61,0.15) 6%, rgba(51,47,49,1) 83%);
  font-size: 18px;
  color: white;
  width: 100%;

  .title {
    font-weight: bolder;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .discount {
    border-radius: 8px;
    padding: 5px 10px;
    background: var(--purple);
    width: fit-content;
    margin-bottom: 10px;
    &.none{
      opacity: 0;
    }
  }

  .price {
    font-size: 26px;
    font-weight: bold;
    margin: 1rem 0;
  }
}

.cross {
  opacity: .7;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 10%;
    width: 85%;
    height: 3px;
    background-color: rgba(255, 0, 0, .4);
    transform: rotate(-45deg);
    transform-origin: center;
    top: 50%;

  }
}

.selected {
  border: 2px solid var(--lightYellow);
  box-shadow: 0 5px 25px 0 var(--lightYellowOpacity);
}

.offers {
  margin: .5rem auto;
  justify-self: center;
  max-width: 940px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 660px) {
    flex-direction: column;
  }
}

</style>
