<template>
  <div class="chartWrapper" ref="chartWrapper">
    <div class="chartToolbox" v-if="periods || chartOptions?.includes('toolbar')">

      <div class="options" v-if="toolbar">
        <div class="chartType" v-if="toolbar?.includes('candles') || toolbar.includes('line')">
          <div class="toolboxButton"
               v-if="toolbar.includes('candles')"
               :class="{active: type === 'candlestick'}" @click="type = 'candlestick'; loadChart()">
            <i class="ri-stock-line"/></div>
          <div class="toolboxButton"
               v-if="toolbar.includes('line')"
               :class="{active: type === 'line'}" @click="type = 'line'; loadChart()"><i
              class="ri-line-chart-line"/></div>
        </div>

        <div class="chartDropdown">
          <DropDown :dropdownLabel="dictionary.chart.ma"
                    @select="toggleMA"
                    :clear="selectedMAs.length > 0" @clear="clearMAs"
                    :multi="true" :options="maOptions"/>
        </div>

        <div class="chartDropdown">
          <DropDown :dropdownLabel="dictionary.chart.indexCompare"
                    @select="compareToggle"
                    :multi="true" :clear="selectedCompareSymbols.length > 0" @clear="clearIndexCompare"
                    :options="indices"/>
        </div>
      </div>


      <div class="periods" :dir="uiDirection" v-if="periods || chartOptions">
        <div v-for="(period, pIdx) in periodsButtons"
             :class="{active: selectedPeriod === period.value}"
             class="toolboxButton" @click="selectPeriod(period.value)">
          {{ period.label }}
        </div>
      </div>

    </div>


    <div class="chart" ref="chart" @mouseup="mouseUp()"
         @mousedown="mouseDown()" style="height: 100%; width: 100%;"></div>
  </div>
</template>

<script>

import * as echarts from 'echarts';
import PerformanceService from '../../services/performance';
import DropDown from "../ui/DropDown";
import numeral from 'numeral';

export default {
  name: "SymbolBigPriceChart",
  components: {DropDown},
  props: ['data', 'symbol', 'period', 'toolbar', 'chartOptions', 'defaultChart', 'currentPrice', 'showLegend', 'periods', 'chartHeight'],
  data() {
    return {
      type: 'candlestick',
      selectedMAs: [],
      selectedCompareSymbols: [],
      isMouseDown: false,
      periodStartPoint: null,
      dataZoom: {
        start: 0,
        end: 100
      },
      compareCharts: [],
      periodsButtons: ['1d', '5d', '1m', '6m', 'ytd', '1y', '3y', '5y', 'max'].map(period => ({
        label: this.dictionary.chart.periods[period],
        value: period
      })),
      selectedPeriod: null,
      indices: [{
        label: 'S&P500',
        value: 'SPY'
      }, {
        label: 'NASDAQ 100',
        value: 'QQQ'
      }, {
        label: 'Dow Jones',
        value: 'DIA'
      }],
      maOptions: [{
        label: '5',
        value: 5
      }, {
        label: '10',
        value: 10
      }, {
        label: '20',
        value: 20
      }, {
        label: '50',
        value: 50
      }, {
        label: '100',
        value: 100
      }, {
        label: '150',
        value: 150
      }, {
        label: '200',
        value: 200
      }],
      prices: [],
      initChart: false,
      options: {},
      wasInit: false,
      chart: null,
      legend: {},
      colors: [
        '#7743DB',
        '#F9B572',
        '#98EECC',
        '#F875AA',
        '#00A9FF',
        '#8EACCD',
        '#FF6969',
        '#FFEA20',
      ],
      mouseDownTimeout: null
    }
  },
  watch: {
    dataZoom(o, n) {

    }
  },
  methods: {
    mouseUp() {

      this.isMouseDown = false;
      this.periodStartPoint = null;
      this.loadChart();
    },
    mouseDown() {

      this.isMouseDown = true;
      this.periodStartPoint = null
    },
    async refresh () {

      await this.loadData();
      await this.loadChart();
    },
    async loadData() {

      const chart = await PerformanceService.getSymbolPriceChart({
        symbol: this.symbol,
        period: this.selectedPeriod,
        compare: this.selectedCompareSymbols
      });
      this.prices = chart.prices.data;
      this.compareCharts = chart.compare;
    },
    calculateMA(dayCount, data) {
      let result = [];
      for (let i = 0, len = data.length; i < len; i++) {

        if (i < dayCount) {
          result.push('-');
          continue;
        }
        let sum = 0;
        for (let j = 0; j < dayCount; j++) {
          sum += +data[i - j][1];
        }
        result.push(sum / dayCount);
      }
      return result;
    },
    async selectPeriod(value) {
      this.selectedPeriod = value;
      await this.loadData();
      await this.loadChart();
    },
    async compareToggle(symbol) {

      const indexOf = this.selectedCompareSymbols.indexOf(symbol.value);
      if (indexOf === -1) {

        this.selectedCompareSymbols.push(symbol.value);
      } else {

        this.selectedCompareSymbols.splice(indexOf, 1);
      }

      if (this.selectedCompareSymbols) this.selectedMAs = [];

      await this.loadData();
      await this.loadChart();
    },
    async clearIndexCompare() {
      this.selectedCompareSymbols = []
      this.compareCharts = [];
      await this.loadChart();
    },
    toggleMA(value) {

      const indexOf = this.selectedMAs.indexOf(value.value);
      if (indexOf === -1) {

        this.selectedMAs.push(value.value);
      } else {

        this.selectedMAs.splice(indexOf, 1);
      }

      this.selectedMAs = this.selectedMAs.sort((a, b) => a - b);
      if (this.selectedMAs.length > 0) {
        this.compareCharts = [];
        this.selectedCompareSymbols = [];
      }
      this.loadChart();
    },
    clearMAs() {
      this.selectedMAs = [];
      this.loadChart();
    },
    calculateChangeOverTime(prices) {

      const compoundedChange = [];
      let accumulatedChange = 0;

      for (let i = 1; i < prices.length; i++) {
        const change = Math.log(prices[i] / prices[i - 1]) * 100;
        accumulatedChange += change;
        compoundedChange.push(accumulatedChange);
      }

      return compoundedChange;
    },
    getCandleStick() {

      if (this.selectedCompareSymbols.length > 0) {

        return this.getLine(null, null, null, 0, true);
      }
      return {
        markLine: {
          symbol: 'none',
          data: [{
            symbol: 'none',
            yAxis: this.currentPrice || 0,
            label: {
              show: true,
              padding: 4,
              position: 'end', // or any other valid position
              distance: '15',
              color: '#fff', // Label text color
              fontSize: 12, // Label text size
              borderRadius: 4,
              backgroundColor: 'rgb(38,44,40)'
            },
            lineStyle: {
              normal: {
                color: this.systemTheme === 'darkMode' ? '#ccc' : '#333',
                opacity: 0.8,
                type: 'dashed'
              }
            },
          }],
        },
        draggable: false,
        silent: true,
        roam: false,
        name: this.symbol,
        type: 'candlestick',
        data: this.prices.map(price => [price[1], price[2], price[3], price[4]]),
        itemStyle: {
          color: 'rgb(13, 185, 128)',
          color0: 'rgba(201, 1, 31, 1)',
          borderColor: undefined,
          borderColor0: undefined
        }
      }
    },
    getLine(symbol = null, prices = null, colorIndexStart = null, index = 0, withoutArea = false) {

      let originalPrices = (prices || this.prices);
      let priceData = (prices || this.prices).map(price => price[2]);
      if (this.selectedCompareSymbols.length > 0) priceData = this.calculateChangeOverTime(priceData);


      let todayDataPoint = null;
      if (this.selectedPeriod === '1d') {

        const firstDataPoint = originalPrices[0][7];
        const firstDataPointDay = firstDataPoint.split(' ')[0];
        todayDataPoint = originalPrices.find(point => {

          return point[7].split(' ')[0] !== firstDataPointDay;
        });
      }

      let lineData = {
        data: priceData,
        type: 'line',
        symbolSize: 0,
        draggable: false,
        silent: true,
        roam: false,
        name: symbol || this.symbol,
        smooth: true,
      }

      if (todayDataPoint) {
        lineData.markArea = {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: 'rgba(100, 100, 100, .05)'
              },
              {
                offset: 1,
                color: 'rgba(100, 100, 100, .02)'
              }
            ])
          },
          data: [
            [
              {
                xAxis: originalPrices[0][7]
              },
              {
                xAxis: todayDataPoint[7]
              }
            ]
          ]
        }
      }

      if (symbol === null) {

        lineData.markLine = {
          symbol: 'none',
          data: [{
            symbol: 'rect',
            symbolSize: 0,
            yAxis: this.currentPrice || 0,
            label: {
              show: true,
              padding: 4,
              position: 'end',
              distance: '15',
              color: '#fff',
              fontSize: 12,
              borderRadius: 4,
              backgroundColor: 'rgb(38,44,40)'
            },
            lineStyle: {
              symbol: 'rect',
              color: this.systemTheme === 'darkMode' ? '#ccc' : '#333',
              opacity: 0.8,
              type: 'dashed'
            },
          }],
        }

        if (todayDataPoint) {
          lineData.markLine.data.push({
            symbolSize: 0,
            symbol: 'none',
            xAxis: todayDataPoint[7],
            label: {
              show: false,
            },
            lineStyle: {
              symbol: 'none',
              color: '#666',
              opacity: 0.5,
              type: 'dashed'
            },
          });

        }
      }

      if (symbol === null) {
        lineData.color = priceData[0] < priceData[priceData.length - 1] ? 'rgb(13, 185, 128)' : 'rgba(201, 1, 31, 1)';
      } else {
        lineData.color = this.colors[colorIndexStart + index]
      }
      if (!withoutArea) {
        lineData.areaStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: priceData[0] < priceData[priceData.length - 1] ? 'rgba(13, 185, 128, .2)' : 'rgba(201, 1, 31, .2)'
            },
            {
              offset: 1,
              color: priceData[0] < priceData[priceData.length - 1] ? 'rgba(13, 185, 128, .01)' : 'rgba(201, 1, 31, .01)'
            }
          ])
        };
      }

      return lineData
    },
    mouseMove(params) {
    },
    getMALine(ma, maIndex) {

      return {
        name: `MA${ma}`,
        type: 'line',
        color: this.colors[maIndex],
        showSymbol: false,
        draggable: false,
        roam: false,
        silent: true,
        data: this.calculateMA(ma, this.prices),
        smooth: true,
        width: 2,
        show: false,
        lineStyle: {
          opacity: 0.8,
          type: 'dashed'
        }
      }
    },
    getVolume(index) {
      return {
        name: 'Volume',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        silent: true,
        draggable: false,
        roam: false,
        data: this.prices.map(price => {
          return {
            value: price[5],
            itemStyle: {
              color: price[1] < price[2] ? 'rgba(13, 185, 128, 0.2)' : 'rgba(201, 1, 31, 0.2)'
            }
          }
        })
      }
    },
    setInitialOptions(chart) {


      let grid = {}
      const screenWidth = window.innerWidth;

      if (screenWidth <= 660) {
        grid.left = '0%';
        grid.right = '5%'
        grid.top = '90%'
      } else {
        grid.left = '1.5%';
        grid.right = '5%'
        grid.top = '84%'
      }
      const legendLabels = [this.symbol, ...this.selectedMAs.map(ma => `MA${ma}`), ...this.selectedCompareSymbols.map(symbol => symbol)];

      legendLabels.forEach(legend => {

        if (this.legend[legend] === undefined) this.legend[legend] = true;
      });

      let dataZoom = [
        {
          type: 'slider',
          show: false,
          xAxisIndex: [0, 1],
          zoomOnMouseWheel: true,
          start: this.dataZoom.start,
          end: this.dataZoom.end,
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: grid.top,
          left: '1%',
          width: '94%',
          start: this.dataZoom.start,
          end: this.dataZoom.end
        }
      ];

      let series = []

      series.push(this.type === 'candlestick' ? this.getCandleStick() : this.getLine(null, null, null, 0, this.compareCharts.length > 0));
      this.selectedMAs.forEach((ma, maIndex) => series.push(this.getMALine(ma, maIndex)))
      this.compareCharts.forEach((compare, cIndex) => {

        let lineChart = this.getLine(compare.symbol, compare.data, this.selectedMAs.length, cIndex, true);
        series.push(lineChart)
      });


      // let legendStatus = chart.getOption().legend.selected;

      series.push(this.getVolume(legendLabels.length))
      this.options = {
        animation: false,
        legend: this.showLegend === false ? false : {
          top: 10,
          left: 'center',
          data: legendLabels,
          selected: this.legend
        },
        tooltip: {
          // show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          formatter: (params) => {


            let changePercent = null;
            const pricesSeries = params.find(series => series.componentIndex === 0);
            const MAs = params.filter(series => series.seriesName.match(/MA(\d+)/));
            const compares = params.filter(series => this.selectedCompareSymbols.includes(series.seriesName));
            let tooltip = '<div style="direction: rtl; color: black; unicode-bidi: plaintext; font-family: \'Assistant\', sans-serif; min-width: 150px">';

            if (this.isMouseDown && this.periodStartPoint === null) {

              this.periodStartPoint = {
                value: this.prices[pricesSeries.dataIndex][2],
                dataIndex: pricesSeries.dataIndex,
                date: pricesSeries.axisValue
              };

              delete series[0].markArea;
            } else if (this.isMouseDown && this.periodStartPoint !== null) {

              let startPrice = this.periodStartPoint.dataIndex < pricesSeries.dataIndex ? this.periodStartPoint.value : this.prices[pricesSeries.dataIndex][2];
              let endPrice = this.periodStartPoint.dataIndex < pricesSeries.dataIndex ? this.prices[pricesSeries.dataIndex][2] : this.periodStartPoint.value;
              changePercent = numeral(((endPrice - startPrice) / startPrice)).format('0,0.00%');

              let changePNum = parseFloat(changePercent.replace('%', ''))
              series[0].markArea = {
                data: [
                  [{
                    xAxis: this.periodStartPoint.dataIndex // Start of the emphasized area
                  }, {
                    xAxis: pricesSeries.dataIndex // End of the emphasized area
                  }]
                ],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                    {
                      offset: 0,
                      color: changePNum > 0 ? 'rgba(13, 185, 128, .2)' : 'rgba(201, 1, 31, .2)'
                    },
                    {
                      offset: 1,
                      color: changePNum > 0 ? 'rgba(13, 185, 128, .02)' : 'rgba(201, 1, 31, .02)'
                    }
                  ])
                  // color: 'rgba(200, 200, 200, 0.15)' // Fill color of the emphasized area
                }
              }
            }


            if (changePercent !== null) {

              tooltip += `<div style="text-align: center"><span><b>${this.dictionary.chart.tooltip.change}:</b><br/>${this.periodStartPoint.date} - ${pricesSeries.axisValue}<br/></span><span style="display: block; font-size: 16px; unicode-bidi: plaintext; direction: rtl; width: 100%; font-weight: 500; color: ${parseFloat(changePercent.replace('%', '')) > 0 ? 'rgba(13, 185, 128, 1)' : 'rgba(201, 1, 31, 1)'}">${changePercent}</span></div> `
              setTimeout(() => {
                chart.setOption(this.options, false);
              }, 1);
            } else {

              if (pricesSeries?.axisValue) tooltip += `<b style="font-size: 16px; direction: rtl; display: block; width: 100%;">${pricesSeries.axisValue}</b>`;
              if (['1m', '5d'].includes(this.period)) {
                if (this.prices[pricesSeries?.dataIndex]?.[7]) tooltip += `<span style="opacity: 0.8; direction: rtl; display: block; width: 100%;">${this.prices[pricesSeries.dataIndex][7]}</span>`
              }


              if (this.selectedCompareSymbols.length === 0) {
                if (this.type == 'candlestick') {
                  if (this.prices[pricesSeries.dataIndex][1]) tooltip += `<b style="width: 70px;">${this.dictionary.chart.tooltip.open}</b> ${this.prices[pricesSeries.dataIndex][1]}<br/>`
                  if (this.prices[pricesSeries.dataIndex][2]) tooltip += `<b>${this.dictionary.chart.tooltip.close}:</b> ${this.prices[pricesSeries.dataIndex][2]}<br/>`
                  if (this.prices[pricesSeries.dataIndex][3]) tooltip += `<b>${this.dictionary.chart.tooltip.low}:</b> ${this.prices[pricesSeries.dataIndex][3]}<br/>`
                  if (this.prices[pricesSeries.dataIndex][4]) tooltip += `<b>${this.dictionary.chart.tooltip.high}:</b> ${this.prices[pricesSeries.dataIndex][4]}<br/>`
                } else {
                  if (this.prices[pricesSeries.dataIndex][2]) tooltip += `<b>${this.dictionary.chart.tooltip.price}:</b> ${this.prices[pricesSeries.dataIndex][2]}<br/>`
                }


                if (this.prices[pricesSeries.dataIndex][5]) tooltip += `<b>${this.dictionary.chart.tooltip.volume}:</b> ${numeral(this.prices[pricesSeries.dataIndex][5]).format('0,0')}<br/>`

                MAs.forEach(ma => {
                  tooltip += `<table cellspacing="0" cellpadding="0"><tr>
                    <td style="padding: 0 2px;"><div style="width: 8px; height: 8px; background: ${ma.color}; border-radius: 3px;"/></td>
                    <td style="padding: 0 2px;">${ma.seriesName}</td>
                    <td style="padding: 0 2px;">${numeral(ma.value).format('0,0.00')}</td></tr>`
                });


                compares.forEach(ma => {
                  tooltip += `<table cellspacing="0" cellpadding="0"><tr>
                    <td style="padding: 0 2px;"><div style="width: 8px; height: 8px; background: ${ma.color}; border-radius: 3px;"/></td>
                    <td style="padding: 0 2px;">${ma.seriesName}</td>
                    <td style="padding: 0 2px;">${numeral(ma.value).format('0,0.00')}</td></tr>`
                });
              } else {

                if (this.prices[pricesSeries.dataIndex][2]) {
                  tooltip += '<span style=" width: 100%; display: block">';
                  tooltip += `<b>${this.dictionary.chart.tooltip.price}:</b> ${this.prices[pricesSeries.dataIndex][2]}<br/>`
                  tooltip += `<b>${this.dictionary.chart.tooltip.change}:</b> <span style="unicode-bidi: bidi-override; direction: ltr !important;">${numeral(pricesSeries.value).format('0,0.00')}%</span><br/>`
                  tooltip += '</span>';
                }
                compares.forEach(ma => {
                  tooltip += `<table cellspacing="0" cellpadding="0"><tr>
                    <td style="padding: 0 2px;"><div style="width: 8px; height: 8px; background: ${ma.color}; border-radius: 3px; direction: ltr;"/></td>
                    <td style="padding: 0 2px;">${ma.seriesName}</td>
                    <td style="padding: 0 2px; unicode-bidi: bidi-override;direction: ltr !important;">${numeral(ma.value).format('0,0.00')}%</td></tr>`
                });
              }
            }


            tooltip += '</div>';
            return tooltip;
          },
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ],
          label: {
            backgroundColor: 'rgb(38,44,40)',
            position: 'insideEnd'
          }
        },
        grid: [
          {
            left: grid.left,
            right: grid.right,
            height: '70%',
            top: '8%',
            containLabel: true
          },
          {
            left: grid.left,
            right: '7.9%',
            top: '49.5%',
            height: '25%',
            containLabel: false
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: this.prices.map(price => this.selectedPeriod === '1d' ? price[7] : price[6]),
            boundaryGap: false,
            axisLine: {show: false},
            splitLine: {show: false},
            min: 'dataMin',
            roam: false,
            max: 'dataMax',
            offset: 10,
            axisLabel: {
              interval: 'auto',
              minInterval: 30,
              maxInterval: 50,
              showMinLabel: false,
              showMaxLabel: false,
              margin: 10, // Adjust label margin
            },
            axisPointer: {
              z: 100
            }
          },
          {
            type: 'category',
            roam: false,
            gridIndex: 1,
            axisPointer: {
              label: {
                show: false
              }
            },
            data: this.prices.map(price => price[0]),
            boundaryGap: false,
            axisLine: {show: false},
            axisTick: {show: false},
            splitLine: {show: false},
            axisLabel: {show: false},
            min: 'dataMin',
            max: 'dataMax'
          }
        ],
        yAxis: [
          {
            axisLabel: {
              formatter: (value) => {

                let label = value;
                if (this.compareCharts.length > 0) {
                  if (label < 0) {
                    label = this.uiDirection === 'rtl' ? `%\u200E(${label})` : `(${label})%`;
                  } else {
                    label = `${label}%`
                  }
                }
                return label
              }
            },
            textStyle: {
              writingMode: 'tb-lr', // Set the text direction to right-to-left
            },
            type: 'value',
            scale: true,
            position: 'right',
            offset:  20,
            splitLine: {
              lineStyle: {
                color: this.systemTheme === 'darkMode' ? '#333' : '#eee'
              }
            },
            axisLine: {show: false, onZero: true},
            axisTick: {show: true},
            splitArea: { // Customize split area to set background color for yAxis
              show: false,
            }
          },
          {
            position: 'right',
            axisPointer: {
              label: {
                show: false // Hide the axis label on mouse hover
              }
            },
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: {show: false},
            axisLine: {show: false},
            axisTick: {show: false},
            splitLine: {show: false}
          }
        ],
        dataZoom,
        series,
        rtl: true,
        ...(Array.isArray(this.symbol) ? null : {graphic: [{
          type: 'text',
          left: 'center',
          top: 'middle',
          style: {
            text: this.symbol,
            fill: this.systemTheme === 'darkMode' ? 'rgba(250, 250, 250, 0.1)' : 'rgba(0, 0, 0, 0.1)', // Text color
            fontSize: window.innerWidth <= 660 ? 40 : 120, // Font size
          }
        }]})
      }
    },
    resize(chart) {
      chart.resize()
    },
    loadChart() {

      if (this.$refs.chart && this.prices && this.prices.length > 0) {

        let chart = echarts.init(this.$refs.chart);

        this.setInitialOptions(chart);

        if (!this.initChart) {
          chart.on('dataZoom', (params) => {

            if (params.start) {
              this.dataZoom.start = params.start;
              this.dataZoom.end = params.end;
            }
          });


          chart.on('legendselectchanged', (params) => {

            this.legend = params.selected;
          });
        }

        chart.setOption(this.options, true);

        if (!this.initChart) {

          new ResizeObserver(() => {
            chart.resize();
          }).observe(this.$refs.chartWrapper);

          // window.addEventListener('resize', () => this.resize(chart));
        }
        this.initChart = true;
      }
    }
  },
  beforeUnmount() {
    // window.removeEventListener('resize');
    echarts.dispose(this.$refs.chart);
  },
  async mounted() {



    this.selectedPeriod = this.period || '1m';
    if (this.defaultChart) this.type = this.defaultChart;
    if (this.chartHeight !== undefined) {
      this.$refs.chartWrapper.style.height = `${this.chartHeight}px`
    }
    await this.loadData();

    try {
      this.loadChart();
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/vars";

.chartWrapper {
  height: 600px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  @media(max-width: 660px) {

    height: 330px;
    padding: 1rem;

    .chart {
      //height: 220px !important;
    }
  }
}

.chartToolbox {
  display: flex;
  align-items: center;
  padding: 5px $minSpacing;
  gap: 10px;


  @media(max-width: 660px) {
    display: block;
    padding: 0;
    overflow-x: auto;
    white-space: nowrap;
  }


  .chartType {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--notficationBorderColor);
    padding: 4px;
    box-sizing: content-box;
  }

  .toolboxButton {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background: var(--tableHoverColor);
    }

    &.active {
      color: var(--highlightColor);
      background: var(--highlightColorOpacity);
    }
  }
}

.options {
  display: flex;
  align-items: center;
  gap: 5px;
  @media(max-width: 660px) {
    margin-bottom: 1rem;
    gap: 5px;

    * {
      font-size: 14px;
    }
  }

}

.periods {
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  padding: 4px;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;

  @media(max-width: 660px) {
    font-size: 12px;
    justify-content: space-between;
  }

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }
}

</style>
