<template>
  <small-loader v-if="!data"/>
  <div class="chartArea" v-if="data">

    <h2>{{ data.labels.title }}</h2>
    <Barchart :series="displayData" :labels="data.labels"
              :tool-tip-formatter="toolTipFormatter"
              :yAxisFormat="'${value}'"
              :legend="legend"
              :xAxisLabels="data.xAxisLabels" v-if="data"/>


    <div class="extraData" v-if="false">
    <div class="extraDataLine">
      <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.epsBeatPercent.label}}</div>
      <div class="value">{{data.extraData.epsBeatPercent.value}}</div>
    </div>

    <div class="extraDataLine" v-if="data.extraData.epsIncreaseQsInARow.value > 0">
      <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.epsIncreaseQsInARow.label}}</div>
      <div class="value">{{data.extraData.epsIncreaseQsInARow.value}}</div>
    </div>

    <div class="extraDataLine" v-if="data.extraData.lastQChange">
            <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.lastQChange.label}}</div>
      <div class="value" :style="setValueColor(data.extraData.lastQChange.value)">{{data.extraData.lastQChange.value}}%</div>
    </div>

    <div class="extraDataLine" v-if="data.extraData.yearChange">
            <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.yearChange.label}}</div>
      <div class="value" :style="setValueColor(data.extraData.yearChange.value)">{{data.extraData.yearChange.value}}%</div>
    </div>

    <div class="extraDataLine" v-if="data.extraData.threeYearsChange">
      <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.threeYearsChange.label}}</div>
      <div class="value" :style="setValueColor(data.extraData.threeYearsChange.value)">{{data.extraData.threeYearsChange.value}}%</div>
    </div>

    <div class="extraDataLine" v-if="data.extraData.fiveYearsChange">
      <div class="bullet">&bull;</div>
      <div class="label">{{data.extraData.fiveYearsChange.label}}</div>
      <div class="value" :style="setValueColor(data.extraData.fiveYearsChange.value)">{{data.extraData.fiveYearsChange.value}}%</div>
    </div>
    </div>


  </div>
</template>

<script>
import PerformanceService from "../../services/performance";
import Barchart from "./BarChart";
import SmallLoader from "../ui/SmallLoader";

export default {
  name: "ReportEstimatesChart",
  props: ['symbol'],
  components: {SmallLoader, Barchart},
  data() {
    return {
      data: null,
      displayType: 'eps'
    }
  },
  methods: {
    setValueColor (value) {
      if (value < 0) return {color: 'var(--red)'};
      if (value > 0) return {color: 'var(--green)'};
    }
  },
  computed: {
    toolTipFormatter() {
      return (params) => {
        return `
                <div style="color: black; unicode-bidi: plaintext; font-family: 'Assistant', sans-serif; min-width: 150px">
                <div style="text-align: center"><b>${params[0].name}</b></div>

                <table cellpadding="0" cellspacing="0">
                <tr>
                    <td style="width: 18px;"><div class="legendTooltip" style="background: ${params[0].color}"/></td>
                    <td>${this.data.labels.eps}</td>
                    <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">$${params[0].value}</span></td>
                  </tr>
                </table>

                     <table cellpadding="0" cellspacing="0">
                      <tr>
                          <td style="width: 18px;"><div class="legendTooltip" style="background: ${params[1].color}"/></td>
                          <td>${this.data.labels.epsEstimate}</td>
                          <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">$${params[1].value}</span></td>
                      </tr>
                  </table>
                </div>
                `;
      }
    },
    legend () {

      return [this.data.labels.eps, this.data.labels.epsEstimate];
    },
    displayData() {

      if (this.displayType === 'eps') {
        return [this.data.series.epsSeries.map(point => point.eps), this.data.series.epsEstimateSeries.map(point => point.epsEstimated)];
      } else if (this.displayType === 'revenue') {
        return [this.data.series.revenueSeries, this.data.series.revenueEstimateSeries];
      }
    }
  },
  async mounted() {
    this.data = await PerformanceService.getCompanyEarnings({symbol: this.symbol, query: {}})
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/performance";

</style>
