
const PATH = '/symbols';

export default {
    async querySymbols({ query}) {

        return axios.get(`${PATH}/query`, {params: {query}});
    },
    async querySymbolsPreview() {

        return axios.get(`${PATH}/query/preview`);
    },
    async filterSymbols(parameters) {

        return axios.post(`${PATH}/filter`, parameters);
    },
    async getSymbolPeers(symbol) {

        return axios.get(`${PATH}/${symbol}/info/peers`);
    },
    async getSymbolPeersDev(symbol) {

        return axios.get(`${PATH}/${symbol}/info/peers/d`);
    },
    async getSymbolInfo(symbol) {

        return axios.get(`${PATH}/${symbol}/info`);
    },
    async getWatchlistSymbols () {

    }
}
