<template>
  <section>
    <DataGridTable :data="data" :defaultSort="'price'" :default-sort-direction="1" :fixedCol="1">
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{th.label}}
        </HeadCell>
      </template>

      <template #tbody="{rows,}">
        <tr v-for="(row, rowIndex) in rows">
          <td>{{row.symbol}}</td>
          <td>{{row.price}}</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>fdsf dsaf jdsakf hdajksf hadjksfh dajks</td>
        </tr>
      </template>
    </DataGridTable>

  </section>
</template>

<script>
import DataGrid from "../components/dataGrid/DataGrid";
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";

export default {
  name: "TopTrending",
  components: {HeadCell, DataGridTable, DataGrid},
  data() {
    return {
      headers: [{
        key: 'symbol',
        label: 'Symbol',
      }, {
        key: 'price',
        label: 'Price',
        align: 'center',
        sortValue: 'price'
      },{
        key: 'price',
        label: 'Price'
      },{
        key: 'price',
        label: 'Price'
      },{
        key: 'price',
        label: 'Price'
      },{
        key: 'price',
        label: 'Price'
      }],
      data: [{
        symbol: 'TSLA',
        price: 200
      }, {
        symbol: 'MSFT',
        price: 230
      }, {
        symbol: 'SOFI',
        price: 50
      }]
    }
  }
}
</script>

<style scoped>

</style>
