<template>
  <Panel :title="title" @close="$emit('close')">

    <div class="filters">
      <component :is="filter.component"
                 :sectors="sectorsTree"
                 :industries="industries"
                 :clear="filter.clear"
                 :multi="filter.multi"
                 :label="filter.label"
                 :ref="`filter-${filter.key}`"
                 :filterKey="filter.key"
                 @clear="$emit('clear', $event)"
                 @select="$emit('select', $event)"
                 v-for="(filter, fIndex) in filters" :key="fIndex + 'f'"
                 :full-width="filter.fullWidth"
                 :autocomplete="filter.autocomplete"
      />
    </div>

    <div class="filterButtons">
      <MBPButton class="action" @click="$emit('applyFilter')" :label="dictionary.filters.applyFilter"/>
      <MBPButton class="transparent" @click="$emit('clearFilter'); clearAll()" :label="dictionary.filters.resetFilter"/>
    </div>
  </Panel>

</template>

<script>
import SectorService from "../../services/sectors";

import SectorFilter from "./SectorFilter";
import IndustryFilter from "./IndustryFilter";
import OverlayUI from "../ui/Overlay";
import DividendYieldFilter from "./DividendYieldFilter";
import DividendFrequencyFilter from "./DividendFrequencyFilter";
import MBPButton from "../ui/controls/MBPButton";
import DividendConsecutiveYearsPayout from "./DividendConsecutiveYearsPayout";
import DividendPayoutRatio from "./DividendPayoutRatio";
import MarketCapsFilter from "./MarketCapsFilter";
import Panel from "../ui/Panel";

export default {
  name: "FilterPanel",
  components: {
    Panel,
    MarketCapsFilter,
    DividendPayoutRatio,
    DividendConsecutiveYearsPayout,
    MBPButton, DividendFrequencyFilter, DividendYieldFilter, OverlayUI, IndustryFilter, SectorFilter
  },
  props: ['filters', 'title'],
  data() {
    return {
      sectorsTree: null,
      industries: null,
    }
  },
  methods: {
    async getSectorsAndIndustries() {

      const {tree, industries} = await SectorService.getSectorsTree();
      this.sectorsTree = tree;
      this.industries = industries;
    },
    clearAll () {

      for (let filter of Object.keys(this.$refs)) {

        if (this.$refs[filter]) {

          this.$refs[filter][0].clearFilter();
        }
      }
    }
  },
  async mounted() {

    await Promise.all([this.getSectorsAndIndustries()])
  }
}
</script>

<style scoped lang="scss">

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:deep(.outerLabel) {
    display: block;
    margin-bottom: 0.3rem;
    padding: 0 0.3rem;
  }
}

.filterButtons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
