<template>
  <main id="watchlistPage">

    <p class="expl">
      {{ dictionary.messages.watchlist }}
    </p>
    <!--    <symbols-search :as-page="true"/>-->

    <section id="emptyPortfolio">
      <EmptyPortfolio v-if="!isLoading && watchlist.length === 0"/>
    </section>



    <!-- Symbol row -->
    <div class="symbolRow header" v-if="isProUI && !isLoading && watchlist.length > 0">
      <div style="width: 100%; position: relative" class="symbolLink">
        <div class="watchlistSize" :class="{listFull: watchlist.length === (isProUI ? 200 : 50)}" v-if="isProUI && !isLoading && watchlist.length > 0">
          {{dictionary.watchlist.numberOfSymbols}}: {{watchlist.length}}/{{isProUI ? 200 : 50}}
        </div>
        </div>
      <div class="remove symbolOption"/>

      <div class="symbolOption toggle"
           @click="updateSymbolOptionForAll('emailPush')"
           :class="{optionOn: allOn('emailPush')}">
        <i class="ri-mail-line"/>
      </div>

      <div class="symbolOption toggle"
           @click="updateSymbolOptionForAll('soundNotification')"
           :class="{optionOn: allOn('soundNotification')}">
        <i class="ri-volume-up-line"/>
      </div>
    </div>

    <div v-for="(symbol, symbolIdx) in watchlist" class="symbolRow">
      <div style="width: 100%; position: relative" class="symbolLink">
        <symbol-link :symbol="symbol.symbol" :disable-preview="true"/>
<!--        <router-link :to="`/symbol/${symbol.symbol}`"/>-->
        <div class="symbolContainer">
          <div style="width: 1rem;"/>
          <SymbolLogo :symbol="symbol.symbol"/>

          <div>
            <div class="symbolName">
              <SymbolLabel :symbol="symbol" :disable-preview="true" :prices="{price: symbol.price}"/>
            </div>
            <div class="companyName" :dir="uiDirection">{{ symbol.name }}</div>

            <div class="notificationTags" v-if="symbol.events.length > 0">
              <div class="eventRow">
                <div v-for="(event, eIndx) in symbol.events" class="tag event">
                  <i class="ri-calendar-line"/>{{ event.label }}{{ extraEventData(event) }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- action buttons -->
      <div class="remove symbolOption" @click="removeSymbol(symbol, symbolIdx)">
        <i class="ri-folder-reduce-line"/>
      </div>

      <div class="symbolOption toggle" v-if="isProUI" :class="{optionOn: symbol.emailPush}"
           @click="updateSymbolOption(symbol, 'emailPush')">
        <i class="ri-mail-line"/>
      </div>

      <div class="symbolOption toggle" v-if="isProUI" :class="{optionOn: symbol.soundNotification}"
           @click="updateSymbolOption(symbol, 'soundNotification')">
        <i class="ri-volume-up-line"/>
      </div>

      <!--      <ToggleSwitch/>-->

    </div>
  </main>
</template>

<script>

import {useWatchlist} from "../stores/watchlist";
import {getCurrentInstance, ref} from "vue";
import UserService from '../services/user';
import SymbolLabel from "../components/symbols/symbol";
import eventBus from "../services/events";
import SymbolView from "./SymbolView";
import SymbolsSearch from "../components/ui/SymbolsSearch";
import ToggleSwitch from "../components/ui/ToggleSwitch";
import SymbolLogo from "../components/symbols/symbolLogo";
import EmptyPortfolio from "../components/ui/EmptyPortfolio";
import SymbolLink from "../components/symbols/symbolLink";

export default {
  name: "CalendarsView",
  components: {SymbolLink, EmptyPortfolio, SymbolLogo, ToggleSwitch, SymbolsSearch, SymbolView, SymbolLabel},
  setup() {

    const app = getCurrentInstance();
    const watchlistStore = useWatchlist();
    const wl = ref([]);

    watchlistStore.$subscribe((mutation, state) => {

      app.ctx.loadWatchlist();
      if (app.data.isInitialLoad) {
        app.data.isInitialLoad = false;
      } else {
      }
    });
  },
  data() {
    return {
      watchlist: [],
      isLoading: true,
      isInitialLoad: true
    }
  },
  methods: {
    allOn(option) {

      if (!this.isInitialLoad) return false;
      return this.watchlist.every(symbol => symbol[option] === true);
    },
    async updateSymbolOptionForAll(option) {

      const isAllOn = this.allOn(option);

      await UserService.updateSymbolOptionForAll(option, !isAllOn);
      this.watchlist = this.watchlist.map(symbol => {

        symbol[option] = !isAllOn;
        return symbol;
      });
    },
    searchSymbolClick() {

      eventBus.emit('searchSymbolClick')
    },
    async loadWatchlist() {

      this.isLoading = true;
      let watchlist = await UserService.getUsersWatchlist();
      watchlist = watchlist.map(symbol => {

        symbol.isLoading = false;
        return symbol;
      });

      this.watchlist = watchlist;
      this.isLoading = false;
    },
    async updateSymbolOption(symbol, option) {

      symbol[option] = !symbol[option];
      await UserService.updateSymbolOption(symbol.symbol, option, symbol[option]);
    },
    extraEventData(event) {
      if (event.ratio) return ` (${event.ratio})`
      if (event.epsEstimate) return ` (EPS est. $${event.epsEstimate})`
      if (event.dividendRate) return ` (Div. $${event.dividendRate})`
    },
    async removeSymbol(symbol, index) {

      try {

        if (!symbol.isLoading) {
          symbol.isLoading = true;
          await UserService.removeSymbolFromWatchlist(symbol.symbol)
          useWatchlist().removeSymbol(symbol.symbol);
          symbol.isLoading = false;
        }
      } catch (e) {

      }
    }
  },
  async mounted() {
    await this.loadWatchlist();
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/notification";

.symbolRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--notficationBorderColor);

  &:hover {
    background: var(--tableHoverColor);
  }

  &.header {
    &:hover {
      background: var(--bgColor);
    }
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .symbolName {
    font-weight: 600;
    font-size: 18px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .companyName {
    padding: 0 1rem;
    unicode-bidi: plaintext;
  }

  .notificationTags {
    padding: 5px;
    background: black;
    width: fit-content;
    color: white;
    border-radius: 5px;
    margin: 5px 1rem 10px;
  }

  .companyName[dir="rtl"] {
    text-align: right;
  }

  .remove {
    padding: 1rem;
    cursor: pointer;
  }
}

.symbolContainer {
  display: flex;
  align-items: center;
  gap: 0;
}

#emptyPortfolio {
  max-width: 720px;
  margin: 0 auto;
  @media (max-width: 660px) {
    width: 90%;
  }
}

#searchSymbol {
  cursor: pointer;
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  border-radius: 8px;
  color: var(--highlightColor);
  text-align: center;
  width: fit-content;
  font-weight: 500;
  margin: 5px auto;
}

.expl {
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
}

.watchlistSize {
  padding: $smallGap;
  font-weight: 600;
  text-align: center;
  &.listFull {
    color: var(--red);
  }
}


.symbolOption {
  padding: 1rem;
  cursor: pointer;
  text-align: center;

  i {
    display: block;
  }

  label {
    font-size: 12px;
  }

  &.toggle {
    color: var(--gray);
    background: var(--bgColor);
    transition: $toggleTransition;
    opacity: .5;
  }

  &.optionOn {
    opacity: 1;
    color: var(--green);
    background: var(--opacityGreen);
  }
}


</style>
