<template>
<label>
  <span class="outerLabel" v-if="label">{{label}}</span>
  <DropDown
      :full-width="fullWidth"
      :options="options"
      :clear="clear"
      ref="filter"
      @clear="$emit('clear', filterKey)"
      @select="$emit('select', {filter: filterKey, data: $event})"
    />
</label>
</template>

<script>
import DropDown from "../ui/DropDown";
import DropDownAutoComplete from "../ui/DropdownAutoComplete";
export default {
  name: "DividendYieldFilter",
  components: {DropDownAutoComplete, DropDown},
  props: ['autocomplete', 'multi', 'clear', 'fullWidth', 'industries', 'label', 'filterKey'],
  data () {
    return {
      options: [{
        label: `2% ${this.dictionary.filters.andMore}`,
        value: '+2'
      }, {
        label: `4% ${this.dictionary.filters.andMore}`,
        value: '+4'
      }, {
        label: `6% ${this.dictionary.filters.andMore}`,
        value: '+6'
      }, {
        label: `8% ${this.dictionary.filters.andMore}`,
        value: '+8'
      }, {
        label: `10% ${this.dictionary.filters.andMore}`,
        value: '+10'
      }]
    }
  },
  methods: {
    clearFilter() {
      this.$refs.filter.clearSelection(false);
    }
  }
}
</script>

<style scoped>

</style>
