<template>
  <small-loader v-if="!data"/>
  <div class="chartArea" v-if="data">
    <h2>{{ data.labels.title }}</h2>
    <ChartNote :note="data.labels.note" v-if="data.labels.note"/>
    <Barchart :series="displayData" :labels="data.labels"
              :tool-tip-formatter="toolTipFormatter"
              :yAxisFormat="yAxisFormat"
              :xAxisLabels="data.xAxisLabels" v-if="data"/>


  </div>
</template>

<script>
import PerformanceService from "../../services/performance";
import Barchart from "./BarChart";
import ChartNote from "./ChartNote";
import SmallLoader from "../ui/SmallLoader";

export default {
  name: "CashEquivalentsChart",
  props: ['symbol'],
  components: {SmallLoader, ChartNote, Barchart},
  data() {
    return {
      data: null,
      displayType: 'eps',
      yAxisFormat: (value) => {
        if (isNaN(value)) return value;

        const absoluteValue = Math.abs(value);
        if (absoluteValue < 1000) {
          return value; // Return the number as is if it's less than 1000
        } else if (absoluteValue < 1000000) {
          return (value / 1000).toFixed(1) + 'K'; // Convert to K (thousands)
        } else if (absoluteValue < 1000000000) {
          return (value / 1000000).toFixed(1) + 'M'; // Convert to M (millions)
        } else {
          return (value / 1000000000).toFixed(1) + 'B'; // Convert to B (billions) or higher
        }
      }
    }
  },
  methods: {
    setValueColor(value) {
      if (value < 0) return {color: 'var(--red)'};
      if (value > 0) return {color: 'var(--green)'};
    }
  },
  computed: {
    toolTipFormatter() {
      return (params) => {
      const dataIndex = params[0].dataIndex;
        return `
                <div style="color: black; unicode-bidi: plaintext; font-family: 'Assistant', sans-serif; min-width: 150px">
                <div style="text-align: center"><b>${params[0].name}</b></div>

                <table cellpadding="0" cellspacing="0">
                <tr>
                    <td style="width: 18px;"><div class="legendTooltip" style="background: ${params[0].color}"/></td>
                    <td>${params[0].name}</td>
                    <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">${this.data.series[dataIndex].formattedValue}</span></td>
                  </tr>
                </table>
                </div>
                `;
      }
    },
    displayData() {

      let data = this.data.series.map(point => point.value);
      return [data];
    }
  },
  async mounted() {
    this.data = await PerformanceService.getCompanyCash({symbol: this.symbol, query: {}})
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/performance";

.col {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  @media(max-width: 660px) {
    flex-direction: column;
    gap: 2rem;
  }
}

</style>
