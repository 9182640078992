<template>

  <div class="symbolFilterPanel" ref="container">
    <div class="row"/>

    <div id="panel" v-if="!isLoading && !doneFiltering" :key="filterKey">
      <div class="row">

        <div class="grow">
          <label>{{ dictionary.search.filter.industry }}</label>
          <div class="div"/>
          <DropDown :options="industriesList" @clear="industries = []"
                    :autocomplete="true"
                    :full-width="true"
                    :alternative-options="alternativeList"
                    ref="industries"
                    :dropdown-label="dictionary.search.filter.industrySelection" :multi="true"
                    :clear="this.industries.length > 0" @select="industriesSelected"/>
        </div>

        <div class="grow">
          <label>{{ dictionary.search.filter.sector }}</label>
          <div class="div"/>
          <DropDown
              ref="sector"
              :full-width="true"
              :options="sectorsList" @clear="sector = null;" :clear="this.sector"
              :dropdown-label="dictionary.search.filter.sectorSelection"
              @select="sectorSelected"/>
        </div>


      </div>
      <chart-note v-if="!sector" :note="dictionary.search.filter.mustSelectASector"/>


      <div class="row mobileWrap">

        <div class="grow mobileFull">
          <label>{{ dictionary.search.filter.marketCap }}</label>
          <div class="div"/>
          <DropDown
              ref="marketCap"
              :options="dictionary.search.filter.marketCapRangeValues"
              :dropdown-label="dictionary.search.filter.marketCapSelection"
              @select="marketCap = $event.value"/>
        </div>

        <div class="grow mobileFull">
          <label>{{ dictionary.search.filter.priceChange }}</label>
          <div class="div"/>
          <div class="row" style="margin-bottom: 0">
            <DropDown ref="priceSign"
                      :options="moreLessOptions" :multi="false" :default-selected="priceSignDefault"
                      @select="priceChange.sign = $event.value"/>

            <DropDown ref="priceChangeValue"
                      :multi="false"
                      :options="priceChangeValues" :default-selected="priceChangeValueDefault"
                      @select="priceChange.value = $event.value"/>
            <!--            <div class="number">-->
            <!--              <input type="number" v-model="priceChange.value" style="width: 50px;"/>-->
            <!--              <div class="sign" :dir="uiDirection">%</div>-->
            <!--            </div>-->
            <DropDown
                ref="pricePeriod"
                :options="dictionary.search.filter.periods"
                :default-selected="pricePeriodDefault"
                :startAtEnd="true"
                @select="priceChange.period = $event.value"
            />
          </div>
        </div>
      </div>

      <div class="grow mobileFull">
        <label>{{ dictionary.search.filter.pe }}</label>
        <div class="div"/>
        <div class="row" style="margin-bottom: 0">
          <DropDown ref="peSign" :options="moreLessOptions" :default-selected="peSignDefault"
                    @select="pe.sign = $event.value"/>
          <div class="number">
            <input type="number" v-model="pe.value"/>
            <div class="sign" :dir="uiDirection" style="width: 15px;">&nbsp;&nbsp;</div>
          </div>
        </div>
      </div>

      <div class="mobileFull">
      <div class="row" style="margin-top: 1rem;">
        <button @click="filterSymbols()" :disabled="isLoading || !sector">{{ dictionary.search.filter.search }}</button>
        <button id="reset" @click="resetFilter()">{{ dictionary.search.filter.resetFilter }}</button>
      </div>
      </div>
    </div>

    <div id="filterSummary" ref="filterSummary" :dir="uiDirection" v-if="!isLoading && doneFiltering">

      <div class="summaryInfo">
        <label>{{ dictionary.search.filter.results }}</label>
        <div class="summarySelection">{{ dictionary.search.filter.found }} {{ results.length }}</div>
      </div>

      <div class="summaryInfo" v-if="selectedSector">
        <label>{{ dictionary.search.filter.sector }}</label>
        <div class="summarySelection">{{ selectedSector }}</div>
      </div>

      <div class="summaryInfo" v-if="selectedIndustries">
        <label>{{ dictionary.search.filter.industry }}</label>
        <div class="summarySelection">{{ selectedIndustries }}</div>
      </div>

      <div class="summaryInfo" v-if="selectedMarketCap">
        <label>{{ dictionary.search.filter.marketCap }}</label>
        <div class="summarySelection">{{ selectedMarketCap }}</div>
      </div>

      <div class="summaryInfo" v-if="selectedPriceChange">
        <label>{{ dictionary.search.filter.priceChange }}</label>
        <div class="summarySelection">{{ selectedPriceChange }}</div>
      </div>

      <div class="summaryInfo" v-if="selectedPe">
        <label>{{ dictionary.search.filter.pe }}</label>
        <div class="summarySelection">{{ selectedPe }}</div>
      </div>

      <button id="newFilter" :dir="uiDirection" @click="isLoading = false; doneFiltering = false;">
        {{ dictionary.search.filter.newFilter }}
      </button>

    </div>

    <big-loader v-if="isLoading"/>

    <section v-if="!isLoading && doneFiltering">
      <div id="results" ref="results">
        <table cellspacing="0" cellpadding="0">
          <thead>
          <tr>
            <th style="width: 18%">{{ dictionary.search.filter.name }}</th>
            <th>{{ dictionary.search.filter.symbol }}</th>
            <th>{{ dictionary.search.filter.price }}</th>
            <th></th>
            <th>{{ dictionary.search.filter.industry }}</th>
            <th style="width: 100px;     white-space: pre;">{{ dictionary.search.filter.pe }}</th>
            <th>{{ dictionary.search.filter.marketCap }}</th>
            <th v-if="selectedPriceChange">{{ dictionary.search.filter.priceChange }}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="result" v-for="(symbol, sIdx) in results">
            <td class="linkable companyName" style="width: 18%" @click="emitCloseEvent()">
              <router-link :to="`/symbol/${symbol.symbol}`"/>
              {{ symbol.name }}
            </td>
            <td class="symbolCell linkable" @click="emitCloseEvent()">
              <router-link :to="`/symbol/${symbol.symbol}`"/>
              {{ symbol.exchange ? symbol.exchange + ':' : '' }}{{ symbol.symbol }}
            </td>
            <td class="linkable" @click="emitCloseEvent()">
              <router-link :to="`/symbol/${symbol.symbol}`"/>
              <SymbolLabel
                  :pre-market-quote="symbol.preMarket"
                  :post-market-quote="symbol.postMarket"
                  :symbol="{symbol: symbol.symbol, price: symbol.price, changeP: symbol.changeP}"
                  :prices="{price: {price: symbol.price, changeP: symbol.changeP}}"
                  :always-on="true" :price-only="true"/>
            </td>
            <td>

              <div class="addRemoveSymbol add" @click="addRemoveWatchlistSymbol(symbol.symbol)"
                   v-if="!watchlist || !watchlist.watchlist.includes(symbol.symbol)">
                {{ dictionary.buttons.addToPortfolio }}
              </div>
              <div class="addRemoveSymbol remove" @click="addRemoveWatchlistSymbol(symbol.symbol)"
                   v-if="watchlist && watchlist.watchlist.includes(symbol.symbol)">
                {{ dictionary.buttons.removeFromPortfolio }}
              </div>


            </td>
            <td>
              <div class="tdFullWidth">{{ symbol.industry }}</div>
            </td>
            <td style="unicode-bidi: plaintext; width: 100px;">{{ symbol.pe }}</td>
            <td>{{ symbol.marketCapFormat }}</td>
            <td v-if="selectedPriceChange">{{ symbol.priceChangeFormat }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button id="seeFeed" :dir="uiDirection" @click="createFeed()">
        {{ dictionary.search.filter.seeNews }}
      </button>
    </section>


  </div>
</template>

<script>
import DropDown from "./DropDown";

import SectorService from '../../services/sectors'
import NewsService from '../../services/news';

import ChartNote from "../charts/ChartNote";

import SymbolService from '../../services/symbols'
import BigLoader from "./BigLoader";
import SymbolLabel from "../symbols/symbol";
import UserService from "../../services/user";

import {useWatchlist} from "../../stores/watchlist";
import eventBus from "../../services/events";

import quoteStreaming from "../../composables/quoteStreaming";

export default {
  name: "SymbolFilterPanel",
  components: {SymbolLabel, BigLoader, ChartNote, DropDown},
  setup() {

    const {updateStream, leaveStream} = quoteStreaming();
    return {updateStream, leaveStream};
  },
  data() {
    return {
      filterKey: 0,
      tree: [],
      isLoading: false,
      sector: null,
      doneFiltering: false,
      industries: [],
      peSignDefault: 'moreThan',
      priceSignDefault: 'moreThan',
      pricePeriodDefault: '1Y',
      completeIndustriesList: [],
      priceChangeValueDefault: 0,
      priceChangeValues: [{
        value: 80,
        label: '80%'
      }, {
        value: 70,
        label: '70%'
      }, {
        value: 60,
        label: '60%'
      }, {
        value: 50,
        label: '50%'
      }, {
        value: 40,
        label: '40%'
      }, {
        value: 30,
        label: '30%'
      }, {
        value: 20,
        label: '20%'
      }, {
        value: 10,
        label: '10%'
      }, {
        value: 0,
        label: '0%'
      }, {
        value: -10,
        label: '-10%'
      }, {
        value: -20,
        label: '-20%'
      }, {
        value: -30,
        label: '-30%'
      }, {
        value: -40,
        label: '-40%'
      }, {
        value: -50,
        label: '-50%'
      }, {
        value: -60,
        label: '-60%'
      }, {
        value: -70,
        label: '-70%'
      }, {
        value: -80,
        label: '-80%'
      }],
      watchlist: useWatchlist(),
      results: [],
      pe: {
        sign: 'moreThan',
        value: null
      },
      marketCap: null,
      priceChange: {
        sign: 'moreThan',
        value: null,
        period: '1Y'
      },
      moreLessOptions: [{
        value: 'moreThan',
        label: this.dictionary.search.filter.moreThan
      }, {
        value: 'lessThan',
        label: this.dictionary.search.filter.lessThan
      }]
    }
  },
  computed: {
    alternativeList () {

      if (!this.sector) return this.completeIndustriesList;

      const selectedIndustries = this.tree.find(sector => sector.value === this.sector)?.industries.map(industry => industry.value);
      return this.completeIndustriesList.filter(industry => selectedIndustries.includes(industry.value))
    },
    selectedPe() {

      const hasPe = this.pe.value !== null && this.pe.value !== undefined && this.pe.value.toString().trim().length > 0;
      if (!hasPe) return null;

      const sign = this.dictionary.search.filter[this.priceChange.sign];
      return `${sign} ${this.pe.value}`;
    },
    selectedPriceChange() {

      const hasPriceChange = this.priceChange.value !== null && this.priceChange.value !== undefined && this.priceChange.value.toString().trim().length > 0;
      if (!hasPriceChange) return null;

      const sign = this.dictionary.search.filter[this.priceChange.sign];
      const period = this.dictionary.search.filter.periods.find(period => period.value === this.priceChange.period)?.label;
      const within = this.$.appContext.app.config.globalProperties.systemLanguage === 'he' ? 'בתוך' : 'within';
      return `${sign} ${this.priceChange.value}% ${within} ${period}`
    },
    selectedMarketCap() {

      return this.marketCap ? this.dictionary.search.filter.marketCapRangeValues.find(marketCap => marketCap.value === this.marketCap)?.label : null;
      // return this.marketCap ? this.dictionary.search.filter.marketCapValues.find(marketCap => marketCap.value === this.marketCap)?.label : null;
    },
    selectedIndustries() {

      if (!this.sector) return null;
      const selectedIndustries = this.tree.find(sector => sector.value === this.sector)?.industries.filter(industry => this.industries.includes(industry.value)).map(industry => industry.label);
      return selectedIndustries.length > 0 ? selectedIndustries.join(', ') : null;
    },
    selectedSector() {

      return this.tree.find(sector => sector.value === this.sector)?.label;
    },
    sectorsList() {

      return this.tree.map(sector => (
          {
            value: sector.value,
            label: sector.label
          }
      ));
    },
    industriesList() {
      if (this.sector) {
        return this.tree.find(sector => sector.value === this.sector)?.industries;
      }
    }
  },
  methods: {
    emitCloseEvent() {
      this.leaveStream('symbolsFilter');
      eventBus.emit('closeSearchPanel')
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    resetFilter() {

      this.sector = null;
      this.industries = [];
      this.pe = {
        sign: 'moreThan',
        value: null
      };
      this.marketCap = null;
      this.priceChange = {
        sign: 'moreThan',
        value: null,
        period: '1Y'
      }

      this.$refs.sector.clearSelection(false);
      this.$refs.industries.clearSelection(false);
      this.$refs.marketCap.clearSelection(false);
      this.$refs.peSign.clearSelection(false);
      this.$refs.pricePeriod.clearSelection(false);
      this.$refs.priceSign.clearSelection(false);

      this.peSignDefault = 'moreThan';
      this.priceSignDefault = 'moreThan';
      this.pricePeriodDefault = '1Y';
      this.priceChangeValueDefault = 0;

      this.filterKey++;
      this.leaveStream('symbolsFilter');

    },
    async createFeed() {

      const filterObject = {
        pe: this.pe,
        marketCap: this.marketCap,
        sector: this.sector,
        industries: this.industries,
        priceChange: this.priceChange
      }

      this.leaveStream('symbolsFilter');
      const feedId = await NewsService.createFilteredFeed(filterObject);
      eventBus.emit('closeSearchPanel');

      this.$router.push(`/feed/filtered/${feedId.feedId}`);
      this.$router.go(1);

    },
    async filterSymbols() {

      const filterObject = {
        pe: this.pe,
        marketCap: this.marketCap,
        sector: this.sector,
        industries: this.industries,
        priceChange: this.priceChange
      }


      this.isLoading = true;
      this.doneFiltering = false;
      this.results = await SymbolService.filterSymbols(filterObject);

      this.updateStream(this.results.map(result => result.symbol), 'symbolsFilter');

      this.isLoading = false;
      this.doneFiltering = true;
      this.$nextTick(() => {


        const container = document.querySelector('.searchContainer');
        const filterSummary = this.$refs.filterSummary;

        const heightOfDiv1 = container.clientHeight;
        const heightOfDiv2 = filterSummary.clientHeight;

        const maxHeightDifference = heightOfDiv1 - heightOfDiv2;
        this.$refs.results.style.maxHeight = `${maxHeightDifference - 150}px`
      });
    },
    sectorSelected(value) {

      if (this.sector !== null) {
        this.industries = [];
        // this.$refs.industries.setSelected([]);
        this.$refs.industries.clearSelection();
      }

      this.sector = value.value;
    },
    industriesSelected(data) {

      // console.log(data);
      let value = data.value;
      let isFreeType = data.isFreeType;
      const indexOf = this.industries.indexOf(value);
      if (indexOf < 0) {

        // if (isFreeType) {

          // console.log('free typing')
          const findSector = this.tree.find(sector => {

            return sector.industries.find(industry => industry.value === value);
          });

          // console.log(findSector, 'sec')

          if (findSector) {
            this.sector = findSector.value;
            this.$refs.sector.setSelected(findSector.value);
          }
          this.industries.push(value);
        // } else {
        //
        // }
      } else {

        this.industries.splice(indexOf, 1);
      }
    },
    async getSectorsTree() {

      const {tree, industries} = await SectorService.getSectorsTree();
      this.tree = tree;
      this.completeIndustriesList = industries;
    }
  },
  async mounted() {

    await this.getSectorsTree();
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/controls";

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: .5rem;
  @media(max-width: 660px) {
    display: block;
    margin-bottom: 1rem;
  }
}

.grow {
  flex: 0 1 50%;

  label {
    font-weight: 600;
  }
}

.div {
  height: 5px;
}

.number {
  position: relative;

  .sign {
    position: absolute;
    padding: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    user-select: none;
    background: var(--bgColor);

    &[dir="rtl"] {
      right: unset;
      left: 5px;
    }
  }
}

#filterSummary {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 1rem;
  border-radius: 8px;
  background: var(--highlightColorOpacity);
  flex-wrap: wrap;
  gap: 5px;

  &[dir="rtl"] {
    padding: 5px 1rem 5px 5px;
  }
}

.summaryInfo {
  display: flex;
  align-items: flex-start;
  gap: 5px;

  &:after {
    content: '•';
    padding: 0 10px;
  }

  &:last-of-type {
    &:after {
      padding: 0;
      content: '';
    }
  }

  label {
    color: var(--gray);
  }

  .summarySelection {
    color: var(--highlightColor);
    font-weight: 500;
  }
}

button {
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  background: var(--highlightColor);
  color: var(--bgColor);
  cursor: pointer;
  padding: 7px 23px;
}

button#reset {
  color: var(--highlightColor);
  background: var(--bgColor);
  border: 1px solid var(--highlightColor);
}

button#newFilter {
  font-size: 16px;
  padding: 5px 10px;
  margin-left: auto;

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }
}

#results {
  overflow-y: auto;
  padding: 0;
  margin: 1rem 0;
  box-sizing: border-box;
}

#seeFeed {
  display: block;
  margin: auto;
}

table {
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    background: var(--bgColor);
    z-index: 1;
  }

  th {
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid var(--notficationBorderColor);
    padding: 5px 10px;
    vertical-align: middle;

    &:first-of-type {
      text-align: unset;
    }
  }

  td {
    border-bottom: 1px solid var(--notficationBorderColor);
    padding: 5px 10px;
    text-align: center;
    vertical-align: middle;

    &:first-of-type {
      text-align: unset;
    }
  }

  tr {
    &:hover {
      td {
        background: var(--tableHoverColor);
      }
    }
  }
}

.symbolCell {
  white-space: pre;
}

.mobileFull {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  gap: 10px;
  @media (max-width: 660px) {
    display: block;
  }

  .row{
    @media (max-width: 660px) {
      display: flex;
    }
  }
}

.mobileWrap {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.linkable {
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.tdFullWidth {
  width: fit-content;
  display: inline-flex;
  flex: 1 0 fit-content;
  white-space: pre;
}

.companyName {
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
