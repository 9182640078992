<template>
  <small-loader v-if="!data"/>

  <div class="chartArea" v-if="data && data.series">
    <h2>{{ data.labels.title }}</h2>

    <PieChart :series="displayData" :formatter="formatter" v-if="data"/>

  </div>
</template>

<script>
import PerformanceService from "../../services/performance";
import Barchart from "./BarChart";
import PieChart from "./PieChart";
import SmallLoader from "../ui/SmallLoader";

export default {
  name: "RevenueProductChart",
  props: ['symbol'],
  components: {SmallLoader, PieChart},
  data() {
    return {
      data: null,
      yAxisFormat: (value) => {

        if (isNaN(value)) return value;

        const absoluteValue = Math.abs(value);
        if (absoluteValue < 1000) {
          return value; // Return the number as is if it's less than 1000
        } else if (absoluteValue < 1000000) {
          return (value / 1000).toFixed(1) + 'K'; // Convert to K (thousands)
        } else if (absoluteValue < 1000000000) {
          return (value / 1000000).toFixed(1) + 'M'; // Convert to M (millions)
        } else {
          return (value / 1000000000).toFixed(1) + 'B'; // Convert to B (billions) or higher
        }
      }
    }
  },
  methods: {

  },
  computed: {
    formatter() {
      return (params) => {
        return `
              ${params.data.name}\n$${params.data.formattedValue}
                `;
      }
    },
    displayData() {

      return this.data.series;
    }
  },
  async mounted() {
    this.data = await PerformanceService.getCompanyProducts({symbol: this.symbol, query: {}})
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/performance";

.col {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  @media(max-width: 660px) {
    flex-direction: column;
    gap: 2rem;
  }
}

</style>
