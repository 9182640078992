<template>

  <div class="symbolsTag" :class="{inWatchlist: symbol.inWatchlist}" ref="symbolWrapper">
    <!--    <router-link :to="`/symbol/${symbol.symbol}`" v-if="!dontLink"/>-->
    <symbol-link :symbol="symbol.symbol" :disable-preview="true" v-if="!dontLink"/>

    <div class="symbol" :style="isSmall()">
      <i v-if="symbol.inWatchlist" style="color: #888;" class="ri-folder-line"/>
      {{ priceOnly ? '' : showName ? symbol.name : symbol.symbol }}

      <span ref="priceWrapper" class="symbolWrapper"
            :class="{priceUp: checkChangeP(symbol, 'prices', 'priceUp'), priceDown: checkChangeP(symbol, 'prices', 'priceDown'), marketClosed: isProUI && !nyTime.isMarketOpen && !alwaysColored}"
            style="unicode-bidi: plaintext !important;"
            :style="isSmallPrice()">{{ getPrice(symbol) }}</span>

      <div class="symbolFeatureTag topGainer" v-if="symbol.topGainer && nyTime.isMarketOpen"><i
          class="ri-arrow-right-up-line"/>
        {{ dictionary.tags.topGainers }}
      </div>
      <div class="symbolFeatureTag topLoser" v-if="symbol.topLoser && nyTime.isMarketOpen"><i
          class="ri-arrow-right-down-line"/>
        {{ dictionary.tags.topLosers }}
      </div>
      <div class="symbolFeatureTag topActive" v-if="symbol.topActive && nyTime.isMarketOpen"><i
          class="ri-arrow-left-right-line"/>
        {{ dictionary.tags.topActive }}
      </div>
    </div>
    <PrePostQuote v-if="((nyTime.isPreMarket || nyTime.isPostMarket) || forcePrePost || symbol.postMarket) && !hidePrePost"
                  :type="onlyPreMarket ? 'preMarket' : onlyPostMarket ? 'postMarket' : (nyTime.isPreMarket ? 'preMarket' : 'postMarket')"
                  :quotes="{
                    preMarket: equityPrices?.preMarketPrices?.[symbol.symbol] || preMarketQuote,
                    postMarket: equityPrices?.postMarketPrices?.[symbol.symbol] || postMarketQuote || symbol.postMarket,
                  }"
                  :symbol="symbol.symbol"/>

<!--    <PrePostQuote v-if="((nyTime.isPreMarket || nyTime.isPostMarket) && ((!alwaysOn && showPrePostQuote) || (alwaysOn && showPrePost && (nyTime.isPreMarket || nyTime.isPostMarket)))) || forcePrePost"-->
<!--                  :type="onlyPreMarket ? 'preMarket' : onlyPostMarket ? 'postMarket' : (nyTime.isPreMarket ? 'preMarket' : 'postMarket')"-->
<!--                  :quotes="{-->
<!--                    preMarket: this.equityPrices?.preMarketPrices?.[symbol.symbol] || preMarketQuote,-->
<!--                    postMarket: this.equityPrices?.postMarketPrices?.[symbol.symbol] || postMarketQuote,-->
<!--                  }"-->
<!--                  :symbol="symbol.symbol"/>-->
  </div>
</template>

<script>

import {useEquityPrices} from "../../stores/equityPrices";
import {useNewYorkTime} from "../../stores/newYorkTime";
import SymbolLink from "./symbolLink";
import {getCurrentInstance, watch} from "vue";
import {storeToRefs} from 'pinia'
import PrePostQuote from "./PrePostQuote";

export default {
  name: "SymbolLabel",
  components: {PrePostQuote, SymbolLink},
  props: ['symbol', 'prices', 'small', 'priceOnly', 'alwaysOn', 'dontLink', 'showName', 'changeOnly', 'forcePrePost', 'alwaysColored', 'disablePreview', 'hidePrePost', 'preMarketQuote', 'postMarketQuote', 'onlyPostMarket', 'onlyPreMarket', 'showPrePost'],
  data() {
    return {
      isInitialLoad: true,
      lastPrice: this.symbol.price,
      lastPrePostPrice: null
    }
  },
  setup() {
    const nyTime = useNewYorkTime()
    const equityPrices = useEquityPrices();
    const app = getCurrentInstance();
    try {


      if (!app.ctx.hidePrePost) {

      }
    } catch (e) {
      console.log(e)
    }
    return {nyTime, equityPrices};
  },
  mounted() {

    this.$nextTick(() => {
      this.setPrePostLastPrice();
    });
  },
  computed: {
    pricesKey() {

      if (this.onlyPreMarket) return 'preMarketPrices';
      if (this.onlyPostMarket) return 'postMarketPrices';
      return this.nyTime.isPreMarket ? 'preMarketPrices' : this.nyTime.isPostMarket ? 'postMarketPrices' : null;
    },
    showPrePostQuote() {

      return (
          (this.equityPrices.preMarketPrices?.[this.symbol.symbol] || this.equityPrices.postMarketPrices?.[this.symbol.symbol] ||
          this.preMarketQuote || this.postMarketQuote)
          &&
          (this.nyTime.isPreMarket || this.nyTime.isPostMarket)
      );
    },
    hasPrePostQuote() {

      try {
        if (!this.nyTime) return false;

        let key = this.nyTime.isPreMarket ? 'preMarketPrices' : this.nyTime.isPostMarket ? 'postMarketPrices' : null;
        if (!key) return false;

        const hasRealTimeQuote = this.equityPrices?.[key]?.[this.symbol.symbol]?.price !== null && this.equityPrices?.[key]?.[this.symbol.symbol]?.changeP !== 0
        const hasDBQuote = (this.preMarketQuote && ![0, null].includes(this.preMarketQuote.price) && this.preMarketQuote && [0, null].includes(this.preMarketQuote.changeP)) || (this.postMarketQuote && this.postMarketQuote.price !== null);

        return hasDBQuote || hasRealTimeQuote;
      } catch (e) {
        console.log(e)
        return false;
      }
    }
  },
  methods: {
    getPrePostQuote() {


      let price = this.equityPrices?.[this.pricesKey]?.[this.symbol.symbol]?.price;
      let changeP = this.equityPrices?.[this.pricesKey]?.[this.symbol.symbol]?.changeP;

      if (!price && !changeP) {
        if (this.preMarketQuote && this.nyTime.isPreMarket) {

          price = this.preMarketQuote.price;
          changeP = this.preMarketQuote.changeP;
        } else if (this.postMarketQuote && this.nyTime.isPostMarket) {

          price = this.postMarketQuote.price;
          changeP = this.postMarketQuote.changeP;
        }
      }

      return changeP === 0 || price === 0 ? 'N\\A' : `${price} (${changeP}%)`;
    },
    setPrePostLastPrice() {

      try {
        if (this.lastPrePostPrice === null) {
          if (this.nyTime.isPreMarket) {

            this.lastPrePostPrice = this.preMarketQuote?.price || null;
          }

          if (this.nyTime.isPostMarket) {

            this.lastPrePostPrice = this.postMarketQuote?.price || null;
          }
        }

      } catch (e) {
      }
    },
    isSmall() {
      return this.small ? {fontSize: '14px'} : {}
    },
    isSmallPrice() {
      return this.small ? {fontSize: '12px'} : {}
    },
    formatNumber(value) {

      // Take 2 digits after decimal point, without rounding.
      if (value === null || value === undefined) return null;
      if (!value.toString().includes('.')) return value;

      let toString = value.toString().split('.');
      let newValue = toString[0];
      if (toString[1].trim().length > 0) {
        newValue += `.${toString[1].substr(0, 2)}`
      }

      return newValue;
    },
    checkChangeP(symbol, pricesKey = 'prices', className) {

      if (this.nyTime.isMarketOpen === false && !this.alwaysColored && this.isProUI) {

        return false;
      }

      try {
        if (this.nyTime.isPreMarket || this.nyTime.isPostMarket) {
          if (this.equityPrices[pricesKey]?.[symbol.symbol] && this.equityPrices[pricesKey]?.[symbol.symbol]?.price !== this.lastPrePostPrice && this.lastPrePostPrice !== null) {
            // this.changePMovement(this.equityPrices[pricesKey]?.[symbol.symbol].changeP);
          }

          if (this.lastPrePostPrice === null && this.equityPrices[pricesKey]?.[symbol.symbol]?.price !== null) this.lastPrePostPrice = this.equityPrices[pricesKey]?.[symbol.symbol]?.price
          // this.lastPrePostPrice = this.equityPrices[pricesKey]?.[symbol.symbol]?.price;
          // if (this.equityPrices[pricesKey]?.[symbol.symbol]?.price !== this.lastPrePostPrice && this.lastPrePostPrice !== null) this.changePMovement(this.equityPrices[pricesKey]?.[symbol.symbol].changeP)
          // if (
          //     (this.lastPrePostPrice === null && this.equityPrices[pricesKey]?.[symbol.symbol]?.price !== null) ||
          //     (this.lastPrePostPrice !== null && this.equityPrices[pricesKey]?.[symbol.symbol]?.price !== null && this.lastPrePostPrice !== this.equityPrices[pricesKey]?.[symbol.symbol]?.price)
          // ) this.lastPrePostPrice = this.equityPrices[pricesKey]?.[symbol.symbol]?.price;
        }


        if (this.equityPrices[pricesKey]?.[symbol.symbol] && this.equityPrices[pricesKey]?.[symbol.symbol].price !== symbol.price) {

          if (parseFloat(this.equityPrices[pricesKey]?.[symbol.symbol].changeP) > 0) {
            return className === 'priceUp'
          } else {
            return className === 'priceDown'
          }
        } else {
          if (symbol.changeP !== null && symbol.changeP !== undefined) {

            if (parseFloat(symbol.changeP) > 0) {
              return className === 'priceUp'
            } else {
              return className === 'priceDown'
            }
          }
        }

      } catch (e) {
        console.log(e)
      }
    },
    changePMovement(changeP) {

      try {
        let refName = 'symbolWrapper';

        if (this.nyTime.isPreMarket || this.nyTime.isPostMarket) refName = 'prePostMarketQuote';

        if (!this.$refs[refName]) return;

        if (changeP !== null && changeP !== undefined) {


          if (this.isInitialLoad) {
            this.isInitialLoad = false;
            return;
          }

          if (
              this.lastPrice === this.symbol.price &&
              this.lastPrePostPrice === this.equityPrices.preMarketPrices?.[this.symbol.symbol]?.price
          ) return;

          if (this.$refs.symbolWrapper) {
            this.$refs[refName].classList.remove('symbolDown');
            this.$refs[refName].classList.remove('symbolUp');
          }

          if (parseFloat(changeP) < 0) {
            if (this.$refs[refName]) this.$refs[refName].classList.add('symbolDown');
            setTimeout(() => {
              if (this.$refs[refName]) this.$refs[refName].classList.remove('symbolDown');
            }, 750);
          } else {
            if (this.$refs[refName]) this.$refs[refName].classList.add('symbolUp');
            setTimeout(() => {
              if (this.$refs[refName]) this.$refs[refName].classList.remove('symbolUp');
            }, 750);
          }
        }
      } catch (e) {
      }
    },
    getPrice(symbol) {

      try {
        let symbolCopy = JSON.parse(JSON.stringify(symbol));
        if (this.equityPrices?.prices?.[symbol.symbol]) {

          let price = !this.changeOnly ? this.formatNumber(this.equityPrices?.prices?.[symbol.symbol].price) : '';

          if ((this.nyTime.isMarketOpen || this.alwaysOn) &&
              this.equityPrices?.prices?.[symbol.symbol].changeP !== undefined &&
              this.equityPrices?.prices?.[symbol.symbol].changeP !== null) {

            symbol.price = this.equityPrices?.prices?.[symbol.symbol].price;
            symbol.changeP = this.equityPrices?.prices?.[symbol.symbol].changeP;
            let changeP = this.equityPrices?.prices?.[symbol.symbol].changeP;
            price += !this.changeOnly ? ` (${changeP}%)` : `${changeP}%`;


            if (this.equityPrices?.prices?.[symbol.symbol].price !== symbolCopy.price) this.changePMovement(changeP)
          }
          return price;
        } else {
          let price = !this.changeOnly ? this.formatNumber(symbol.price) : '';
          if ((this.nyTime.isMarketOpen || this.alwaysOn) && symbol.changeP !== undefined && symbol.changeP !== null) {

            let changeP = symbol.changeP;
            price += !this.changeOnly ? ` (${changeP}%)` : `${changeP}%`;
            this.changePMovement(changeP)
          }

          return price;
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/notification";

.symbol {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  flex-wrap: nowrap;
}

.priceUp {
  color: var(--green);
  transition: all .2s linear;
}

.priceDown {
  color: var(--red);
  transition: all .2s linear;
}

.symbolUp {
  span {
    color: white;
    animation-name: greenChange;
    animation-duration: 1s;
  }
}

.symbolDown {
  span {
    color: white;
    animation-name: redChange;
    animation-duration: 1s;
  }
}

@keyframes greenChange {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: var(--green);
    color: white !important;
  }
  100% {
    background-color: transparent;
    color: black !important;
  }
}

@keyframes redChange {
  0% {
    background-color: transparent;
    color: inherit !important;
  }

  30% {
    background-color: var(--red);
    color: white !important;
  }

  100% {
    color: inherit !important;
    background-color: transparent;
  }
}

.symbolFeatureTag {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 14px;
  padding: 1px 4px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  gap: 5px;
  flex: 0 0 fit-content;

  i {
    margin-top: 2px;
  }

  &.topActive {
    color: orange;
  }

  &.topGainer {
    color: #12d712;
  }

  &.topLoser {
    color: #ff6767;
  }
}

.prePostMarketQuote {
  unicode-bidi: plaintext !important;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.noTrades {
  display: inline-flex;
  width: 15px;
  height: 3px;
  border-radius: 4px;
  background: var(--textColor);
}

.symbolWrapper {
  display: inline-flex;
  flex: 0 0 fit-content;
}

.marketClosed:not(.priceDown),
.marketClosed:not(.priceUp) {
  background: rgba(100, 100, 100, 0.18);
  padding: 2px 3px;
  border-radius: 4px;
}
</style>
