<template>

  <div class="dropdown" ref="dropdown" :class="{fullWidth, small}" :style="setWidth()">
    <div class="label">
      <span @click="showDropdown = !showDropdown; setOptionsPosition()" class="dropdownClickable">
        <span v-if="selectedLabels.length > 0" class="selectedLabel">{{
            selectedLabels.length === 1 ? selectedLabels[0] : selectedLabels.length + ' ' + selectedDictionary[systemLanguage]
          }}</span>
        <span v-else>{{
            freeTyping !== null || freeTyping?.toString().trim().length > 0 ? '' : !autocomplete || selected.length === 0 || selected === null ? displayDropDownLabel : ''
          }}</span>
      <i class="ri-arrow-down-s-line ddArrow"/>
      </span>

      <div class="clear" @click="clearSelection()" v-if="clear">
        <i class="ri-close-line"/>
      </div>
    </div>

      <div class="ddOptions" :class="{fullWidth, startAtEnd}" ref="options"
           v-if="showDropdown || freeTyping !== null && freeTyping.toString().trim().length > 0 || isInputFocus">

        <div class="inputWrapper" v-if="autocomplete">
          <i class="ri-search-line"/>
          <input v-model="freeTyping" class="option" ref="freeTyping" @focus="isInputFocus = true" @blur="blurInput">
        </div>

        <div class="placeholder" v-if="allowFreeTyping">
          <label v-show="freeTypingValue === null || freeTypingValue.toString().trim().length === 0">{{dictionary.filters.typeAndEnter}}</label>
          <input v-model="freeTypingValue" @keyup.enter="selectedOption(freeTypingValue)">
        </div>

        <div class="option"
             :class="{selected: isSelected(option)}"
             v-for="(option, oIdx) in optionsList"
             @click="selectedOption(option.value, option.label)">
          <i class="ri-check-line" v-if="isSelected(option)"/>

          {{ option.label }}
        </div>
      </div>

  </div>
</template>

<script>

import {onClickOutside} from "@vueuse/core";
import {getCurrentInstance, ref} from 'vue'

export default {
  name: "DropDown",
  props: ['options', 'allowFreeTyping', 'multi',  'small', 'dropdownLabel', 'defaultSelected', 'clear', 'startAtEnd', 'autocomplete', 'alternativeOptions', 'fullWidth', 'label', 'minWidth', 'closeOnSelect'],
  data() {
    return {
      selectedDictionary: {
        he: 'נבחרו',
        en: 'selected'
      },
      isInputFocus: false,
      showDropdown: false,
      selected: [],
      freeTypingValue: null,
      freeTyping: null,
      selectedLabels: []
    }
  },
  setup() {
    const dropdown = ref(null)
    const app = getCurrentInstance();

    onClickOutside(dropdown, (event) => {
      app.data.showDropdown = false;
    })

    return {dropdown}
  },
  computed: {
    defaultLabel() {
      if (this.dropdownLabel) return this.dropdownLabel;
      if (this.defaultSelected) {
        this.selected = [this.defaultSelected];

        if (!this.dropdownLabel) {
          return this.options.find(opt => opt.value === this.defaultSelected).label;
        }
      }
    },
    selectedLabel() {

      if (Array.isArray(this.selected) && this.selected.length === 0) {
        return null;
      } else if (!Array.isArray(this.selected) && (this.selected === null || this.selected.toString().trim().length === 0)) {
        return null;
      }
    },
    optionsList() {

      if (this.freeTyping !== null && this.freeTyping.toString().trim().length > 0) {

        let query = this.freeTyping.toLowerCase().replace('-', ' ').replace('"', '').trim();
        return !this.alternativeOptions ? [] : this.alternativeOptions.filter(selectOption => selectOption.terms.some(term => term.includes(query)));
      }

      if (this.isInputFocus && this.selectedLabels.length === 0) return this.alternativeOptions;
      return this.options;
    },
    displayDropDownLabel() {

      if (!this.multi && this.selected !== null && !Array.isArray(this.selected)) {
        return this.options.find(option => option.value === this.selected)?.label;
      } else {
        return this.dropdownLabel;
      }
    }
  },
  mounted() {

    if (this.defaultSelected !== null && this.defaultSelected !== undefined) {
      this.selected = this.defaultSelected;
    }

    this.setWidth();
  },
  methods: {
    blurInput() {
      setTimeout(() => {
        this.isInputFocus = false
      }, 100);
    },
    setOptionsPosition() {


      this.$nextTick(() => {
        if (this.$refs.freeTyping) {
          this.$refs.freeTyping.focus()
        }
      })
    },
    setWidth() {

      if (this.minWidth && this.$refs.dropdown) {

        this.$refs.dropdown.style.minWidth = this.minWidth;
      }
    },
    isSelected(option) {
      if (Array.isArray(this.selected)) {

        return this.selected.includes(option.value)
      } else {

        return this.selected === option.value;
      }
    },
    setSelected(selected) {
      this.selected = selected;
    },
    clearSelection(emit = true) {
      if (emit) this.$emit('clear');
      this.selectedLabels = [];
      this.freeTyping = null;
      this.selected = [];
      this.showDropdown = false;
      this.isInputFocus = false;
    },
    selectedOption(value, label) {
      this.$emit('select', {
        value,
        isFreeType: this.freeTyping !== null && this.freeTyping.toString().trim().length > 0
      });
      this.freeTyping = null;
      if (this.multi) {

        const indexOf = this.selected.indexOf(value);
        if (indexOf > -1) {

          this.selectedLabels.splice(indexOf, 1)
          this.selected.splice(indexOf, 1);
        } else {
          this.selectedLabels.push(label)
          this.selected.push(value);
        }


      } else {
        this.selected = value;
        this.selectedLabels = [label];
        this.showDropdown = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
label {
  width: fit-content;
}

label.fullWidth {
  width: 100%;
}

label span {
  width: fit-content;
  flex: 1 1 fit-content;
  justify-content: space-between;
  display: flex;
  align-items: center;

  i {
    //margin-top: 6px;
  }
}

.dropdown {
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  padding: 5px 10px;
  width: fit-content;
  min-width: 150px;
  box-sizing: border-box;

  &.fullWidth {
    min-width: unset;
    width: 100%;
  }

  &.small {
    padding: 1px 7px;
  }

  .label {
    display: flex;
    align-items: center;
    flex: 1 0 100%;
    height: 25px;
    user-select: none;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;


    .clear {
      margin: -5px 5px -5px -11px;
      padding: 5px 5px 5px 4px;
      border-right: 1px solid var(--notficationBorderColor);
      border-left: unset;
      &[dir="ltr"] {
        margin: -5px -11px -5px 5px;
        padding: 5px 4px 5px 5px;
        border-left: 1px solid var(--notficationBorderColor);
      border-right: unset;
      }
    }
  }

  .options {

    &[dir="rtl"] {
      right: 0;
    }

    left: 0;
    z-index: 999;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 40px;
    max-width: 100%;
    min-width: 190px;
    max-height: 220px;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    background: var(--bgColor);

    &.fullWidth {
      max-width: unset;
      width: calc(100% - 10px);
    }

    border: 1px solid var(--notficationBorderColor);

    .option {
      border-bottom: 1px solid var(--notficationBorderColor);
      padding: 5px 10px;
      cursor: pointer;
      background: var(--bgColor);

      &:hover {
        background: var(--tableHoverColor);
      }

      &:last-of-type {
        border-bottom: none;
      }

      &.selected {
        background: var(--highlightColorOpacity);
        color: var(--highlightColor);
      }
    }
  }
}

.clear {
  margin: -5px auto -5px -9px;
  padding: 5px 5px 5px 4px;
  border-right: 1px solid var(--notficationBorderColor);

  &[dir="ltr"] {
    margin: -5px -5px -5px 5px;
    padding: 5px 4px 5px 5px;
    border-left: 1px solid var(--notficationBorderColor);
  }
}

.ddArrow {
  margin-right: auto;

  &[dir="ltr"] {
    margin-right: unset;
    margin-left: auto;
  }
}

.startAtEnd {
  left: unset;
  right: 0;
  transform: translate(50%);

  &[dir="rtl"] {
    left: 0;
    right: unset;
  }
}

.dropdownClickable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 5px;

  input {
    position: absolute;
    z-index: 1;
    background: transparent;
    width: 100%;
  }
}

input {
  font-size: 14px;
  border: none;
  padding: 8px 10px !important;
  border-bottom: 1px solid var(--notficationBorderColor) !important;
  width: 100%;
  &:focus, &:active {
    outline: none;
  }
}

.selectedLabel {
  width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.placeholder {
  position: relative;
  height: 32px;
  background: var(--bgColor);
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    padding: 5px 10px;
    color: var(--textColor);
  }
}

.inputWrapper {
  position: relative;
  input {
    padding-left: 25px !important;
    &[dir="rtl"] {
      padding-right: 25px !important;
    }
  }
  i{
    position: absolute;
    color: var(--textColor);
    top: 50%;
    transform: translateY(-50%);
    margin: 0 5px;
  }
}
</style>
