import {defineStore} from 'pinia'

export const useWatchlist = defineStore('watchlist', {
    state: () => {
        return {
            watchlist: [],
            premiumWatchlist: []
        };
    },
    actions: {
        updateWatchlist(watchlist) {
            this.premiumWatchlist = [];

            if (watchlist) {
                this.watchlist = watchlist.map(symbol => {

                    if (symbol.symbol) {
                        this.premiumWatchlist.push(symbol);
                        return symbol.symbol;
                    }
                    return symbol;
                });
            }
        },
        addSymbol(symbol) {
            if (!this.watchlist.includes(symbol)) this.watchlist.push(symbol)
        },
        removeSymbol(symbol) {

            const indexOfSymbol = this.watchlist.indexOf(symbol);
            if (indexOfSymbol > -1) this.watchlist.splice(indexOfSymbol, 1);
        }
    },
})
