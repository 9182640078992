import { ref } from 'vue';

// Create a new app-level event bus
const eventBus = {};

// Create a reactive property to store the events and their listeners
const events = ref({});

// Method to emit an event
eventBus.emit = (eventName, ...args) => {
    const listeners = events.value[eventName];

    if (listeners) {
        listeners.forEach(listener => {
            listener(...args);
        });
    }
};

// Method to listen to an event
eventBus.on = (eventName, callback) => {
    if (!events.value[eventName]) {
        events.value[eventName] = [];
    }

    events.value[eventName].push(callback);
};

// Method to remove event listener
eventBus.off = (eventName, callback) => {
    const listeners = events.value[eventName];

    if (listeners) {
        const index = listeners.indexOf(callback);
        if (index !== -1) {
            listeners.splice(index, 1);
        }
    }
};

// Export the event bus
export default eventBus;
