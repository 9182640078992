<template>
  <section class="sectionPartial" v-if="userNotifications">
    <chart-note :note="dictionary.settingsPage.notes.notifications"/>

    <Toast :message="dictionary.settingsPage.notes.dataUpdate" ref="updated"/>

    <div id="notificationsList">

      <div class="notificationBlock">
        <toggle-switch :state="allowNotifications" :text="dictionary.settingsPage.notificationLabels.emailNotifications" @stateChanged="allowNotificationsChange"/>
      </div>

    <div class="notificationBlock" v-for="(type, typeIdx) of notificationsSettings">

      <label class="frequencyLabel">{{type.label}}</label>
      <DropDown
                @select="notificationFrequencySelected({frequency: $event, type: type.value})"
                :options="notificationTimes"
                :close-on-select="true"
                :multi="false"
                :default-selected="userNotifications[type.value]"/>
    </div>


    <MBPButton :label="dictionary.settingsPage.buttons.saveNotifications"
               @click="saveNotificationsSettings()"
               class="action"
               :disabled="isSaving" :is-loading="isSaving"/>
    </div>
  </section>
</template>

<script>
import ChartNote from "../../components/charts/ChartNote";
import DropDown from "../../components/ui/DropDown";
import MBPButton from "../../components/ui/controls/MBPButton";

import UserService from '../../services/user';
import ToggleSwitch from "../../components/ui/ToggleSwitch";
import Toast from "../../components/ui/Toast";
import eventBus from "../../services/events";

export default {
  name: "NotificationsPartial",
  components: {Toast, ToggleSwitch, MBPButton, DropDown, ChartNote},
  props: ['userSettings'],
  data () {
    return {
      notificationsAllowed: true,
      isSaving: false,
      userNotifications: null,
      notificationsSettings: [{
        label: this.dictionary.settingsPage.notificationLabels.portfolio,
        value: 'portfolio'
      }, {
        label: this.dictionary.settingsPage.notificationLabels.breakingNews,
        value: 'breaking'
      },

      //   {
      //   label: this.dictionary.settingsPage.notificationLabels.vip,
      //   value: 'vip'
      // }, {
      //   label: this.dictionary.settingsPage.notificationLabels.oilGas,
      //   value: 'oil'
      // }, {
      //   label: this.dictionary.settingsPage.notificationLabels.banks,
      //   value: 'banks'
      // }, {
      //   label: this.dictionary.settingsPage.notificationLabels.commodities,
      //   value: 'commodities'
      // }, {
      //   label: this.dictionary.settingsPage.notificationLabels.econData,
      //   value: 'econdata'
      // }

      ],
      notificationTimes: [{
        value: 'none',
        label: this.dictionary.settingsPage.notificationPeriodLabels.none
      },{
        value: 'realtime',
        label: this.dictionary.settingsPage.notificationPeriodLabels.realTime
      },{
        value: 'five_min',
        label: this.dictionary.settingsPage.notificationPeriodLabels.five_min
      },{
        value: 'fifteen_min',
        label: this.dictionary.settingsPage.notificationPeriodLabels.fifteen_min
      },{
        value: 'thirty_min',
        label: this.dictionary.settingsPage.notificationPeriodLabels.thirty_min
      },{
        value: 'fortyfive_min',
        label: this.dictionary.settingsPage.notificationPeriodLabels.fortyfive_min
      },{
        value: 'sixty_min ',
        label: this.dictionary.settingsPage.notificationPeriodLabels.sixty_min
      }],
    }
  },
  methods: {
    notificationFrequencySelected (frequency) {

      this.userNotifications[frequency.type] = frequency.frequency.value;
    },
    allowNotificationsChange() {

      this.notificationsAllowed = !this.notificationsAllowed;

    },
    async saveNotificationsSettings () {

      this.isSaving = true;
      const settings = {
        allowNotifications: this.notificationsAllowed,
        ...JSON.parse(JSON.stringify(this.userNotifications))
      }
      await UserService.updateNotificationsSettings(settings);

      this.isSaving = false;
      this.$refs.updated.show(() => {
      });
    }
  },
  computed: {
    allowNotifications () {

      return this.notificationsAllowed;
    }
  },
  mounted() {
    this.userNotifications = this.userSettings.notifications;

    this.notificationsAllowed = this.userSettings.allowNotifications;
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/vars";

#notificationsList {
  background: var(--bgColor);
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  padding: $minSpacing;
  box-shadow: 0 2px 2px -10px rgba(0, 0, 0, 0.02), 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;

}

.notificationBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid var(--notficationBorderColor);
  .frequencyLabel {
    min-width: 150px;
    display: block;
  }

  &:last-of-type {
    margin-bottom: 1rem;
    border-bottom: none;
  }
}
</style>
