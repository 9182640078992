<template>
  <label>
    <span class="outerLabel" v-if="label">{{label}}</span>
    <DropDown
        ref="filter"
        :full-width="fullWidth"
        :options="sectors"
        :clear="clear"
        :multi="multi"
        @clear="$emit('clear', filterKey)"
        @select="$emit('select', {filter: filterKey, data: $event})"
    />
  </label>
</template>

<script>


import DropDown from "../ui/DropDown";
export default {
  name: "SectorFilter",
  props: ['sectors', 'clear', 'fullWidth', 'label', 'multi', 'filterKey'],
  components: {DropDown},
  data () {
    return {
    }
  },
  methods: {
    clearFilter() {
      this.$refs.filter.clearSelection(false);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
