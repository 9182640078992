<template>
  <div class="overlay" ref="betaEndedNotification" style="display: none;">
    <div id="logoWrapper">
<!--      <span>BETA</span>-->
      <img src="../../assets/gfx/greenmbp.png" id="logo" alt="Marketbit.pro"/>
    </div>
    <h1>
      <!--          Marketbit.pro-->

    </h1>
    <h2 style="text-align: center">
    </h2>

    <TrialEnded @close="close()" :user="user"/>
<!--    <div class="notification" :dir="uiDirection">-->
<!--      <h1 style="margin-top: 0;">{{title[systemLanguage]}}</h1>-->
<!--      <p style="font-size: 18px;" v-html="thankYou[systemLanguage]">-->
<!--      </p>-->
<!--      <p style="" v-html="body[systemLanguage]"></p>-->

<!--      <p style="font-size: 18px;" v-html="closing[systemLanguage]">-->
<!--      </p>-->

<!--      <MBPButton class="action mbp" style="display: block; margin: 0 auto 1rem" :label="dictionary.buttons.close"/>-->
<!--    </div>-->
  </div>
</template>

<script>

import UserService from '../../services/user';
import MBPButton from "../ui/controls/MBPButton";
import TrialEnded from "../../views/settingsView/TrialEnded";
export default {
  name: "BetaOverNotification",
  props: ['user'],
  components: {TrialEnded, MBPButton},
  async beforeMount() {

    const shouldShow = await UserService.shouldShowNotification('betaEnded');
    if (shouldShow.status) {

      UserService.seenSystemNotification('betaEnded').then().catch();


      this.$nextTick(() => {

        this.$refs.betaEndedNotification.style.display = 'unset';
      });
    } else {
      this.$nextTick(() => {

        this.close();
      });
    }
  },
  created() {

  },
  methods: {
    close () {

      if (this.$refs.betaEndedNotification) {

      this.$refs.betaEndedNotification.parentNode.removeChild(this.$refs.betaEndedNotification);
      } else {
        this.$nextTick(() => {

          this.close();
        });
      }
    }
  },
  data () {
    return {
      title: {
        en: 'Level Up Your Trades: Marketbit.pro Exits Beta!',
        he: 'עולים רמה במסחר: Marketbit.pro יוצאים מבטא!'
      },
      thankYou: {
        en: 'Thank you for being a beta tester! Together, we\'re making Marketbit.pro the ultimate platform for traders in investors.',
        he: 'תודה לכם שלקחתם חלק בבטא! ביחד, אנחנו הופכים את Marketbit.pro לפלטפורמה האולטימטיבית לסוחרים ולמשקיעים.'
      },
      body: {
        en: `As of <b>May 1st</b>, Marketbit.pro will be available to premium users only.
        Please accept our special beta one-time offer! <br/><br/>A massive discount for your first year:<b style="font-size: 24px;">$14.99</b> per month for
        instead of $34.99! <br/>The offer will expire on May 7th - don't miss out!<br/>`,
        he: `החל מ<b>הראשון במאי</b>, Marketbit.pro תהיה זמינה רק למשתמשי פרימיום בלבד. בבקשה קבלו הטבת בטא חד פעמית!<br/><br/>
הנחה ענקית לשנה הראשונה שלכם: <b style="font-size: 24px">$14.99</b>
 לחודש במקום $34.99!
<br/>
ההצעה תקפה עד השביעי במאי - אל תפספסו!`
      },
      closing: {
        en: `Secure the offer now in your <a href="/settings">settings</a> page.<br/>
        We're here to help you reach your trading goals, so feel free to contact us at <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a> for any question.<br/><br/>
        Thank you,<br/>
        Marketbit.pro team`,
        he: `
        ממשו את ההטבה שלכם עכשיו ב<a href="/settings">עמוד ההגדרות</a> שלכם.<br/>
        אנחנו כאן כדי לעזור לכם להגיע למטרות המסחר וההשקעה שלכם, אז הרגישו בנוח לפנות אלינו באימייל <a href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>
         לכל שאלה או בעיה<br/><br/>

        תודה,<br/>
        צוות Marketbit.pro
        `
      }
    }
  }
}
</script>

<style scoped lang="scss">

.overlay {
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(90px);
  //background: var(--mbpGreenOpacity);
  background: linear-gradient(180deg, #1e1c1d 0, #232122 100%);
  z-index: 999999;


  .notification {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    background: var(--bgColor);
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid var(--tableBorderColor);
  }
}

:deep {
  a{
    color: var(--mbpGreen);
    font-weight: 500;
  }
}

#logoWrapper {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  text-align: center;

  @media(max-width: 660px) {
    width: 100%;
  }
  span {
    position: absolute;
    right: -35px;
    bottom: 0;
    transform: translateX(50%) translateY(-200%);
    display: block;
    font-weight: 600;
    font-size: 18px;
    background: #008c20;
    width: fit-content;
    border-radius: 8px;
    padding: 2px 5px;
    color: #1e1c1d;
    @media(max-width: 660px) {
      right: 0;
      font-size: 14px;
      bottom: 17%;
      transform: translateX(50%);
    }
  }
}

h2 {
  color: #fefefe;
  font-weight: 300;
  margin: 0 auto 5rem;
}


#logo {
  width: 360px;
  @media(max-width: 660px) {
    width: 80%;
  }
}
</style>
