<template>

  <main>

    <Toast ref="toastUpdated" :message="'חשבון עודכן'"/>
    <h3>{{ dictionary.settingsPage.titles.title }}</h3>
    <Tabs :tabs="settingsTabs" :border="true" :scroll="true" :round="true" :active-tab="currentTab"
          @tabClick="tabClick"/>
    <BigLoader v-if="!userSettings"/>

    <div id="settingsPage" v-if="userSettings">
      <AccountPartial v-if="currentTab === 'account'" :userSettings="userSettings"/>
      <NotificationsPartial :userSettings="userSettings" v-if="currentTab === 'notifications'"/>
    </div>
  </main>
</template>

<script>

// Services
import UserService from '../services/user';

// Components
import DropDown from "../components/ui/DropDown";
import ChartNote from "../components/charts/ChartNote";
import Tabs from "../components/ui/Tabs";
import NotificationsPartial from "./settingsView/NotificationsPartial";
import AccountPartial from "./settingsView/AccountPartial";
import BigLoader from "../components/ui/BigLoader";
import Modal from "../components/ui/Modal";
import MBPButton from "../components/ui/controls/MBPButton";
import Toast from "../components/ui/Toast";


export default {
  name: "SettingsView",
  components: {Toast, MBPButton, Modal, BigLoader, AccountPartial, NotificationsPartial, Tabs, ChartNote, DropDown},
  data() {
    return {
      userSettings: null,
      settingsTabs: [{
        value: 'account',
        label: this.dictionary.settingsPage.tabs.account
      }
      // , {
      //   value: 'notifications',
      //   label: this.dictionary.settingsPage.tabs.notifications
      // }
      ],
      currentTab: 'account'
    }
  },
  methods: {
    tabClick(value) {
      this.currentTab = value;
    },
    async loadUserSettings() {

      this.userSettings = await UserService.getUserSettings();
    }
  },
  created() {
    setTimeout(() => {

      this.$nextTick(() => {
        // this.$refs.toastUpdated.show();
      });
    }, 3000);
  },
  async mounted() {
    await this.loadUserSettings();
    this.isLoading = false;
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/vars";
@import "../assets/style/controls";

main {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  h3, h4 {
    padding: 0;
    margin: 0;
  }

  h3 {
    padding: 5px 1rem;
  }

  h4 {
    margin-bottom: $minSpacing;
  }
}

.sectionPartial {
  box-sizing: border-box;
  width: 100%;
  @media (min-width: 1540px) {
    width: 1440px;
    margin: 0 auto;
  }
}

#settingsPage {
  width: 100%;
  flex: 1 0 auto;
  gap: $minSpacing;

  section {
    border-radius: 8px;
    padding: 0 $minSpacing $minSpacing $minSpacing;
    background: var(--bgColor);
    min-height: 100%;
  }
}


</style>
