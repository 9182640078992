// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';



const firebaseConfig = {
    apiKey: 'AIzaSyA6UqnXHLhvrP5I2q0KpHG8P60oWyNxBxQ',
    authDomain: 'marketbit.firebaseapp.com',
    projectId: 'marketbit',
    storageBucket: 'marketbit.appspot.com',
    messagingSenderId: '388161874609',
    appId: '1:388161874609:web:c536a52f62acc43fdd5090',
    measurementId: 'G-F1XS6MNZ6K',
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
