import {createApp, reactive, ref} from 'vue'

import {createPinia} from 'pinia'
const pinia = createPinia();

import App from './App.vue'
import router from './router'
import 'remixicon/fonts/remixicon.css'
import VueMixpanel from 'vue-mixpanel'
import Popper from "vue3-popper";


window.BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
import './services/http';
import './assets/style/general.scss';

const app = createApp(App);

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

if (!inIframe()) {

    // document.body.innerHTML = '<h1>Not authorized</h1>';
}

let iframeParams = location.search.replace('?', '').split(/\&/g).reduce((final, current) => {

    const splitParam = current.split('=');
    if (splitParam[0] && splitParam[0].trim().length > 0) {
        final[splitParam[0]] = splitParam[0] === 'params' ? JSON.parse(decodeURIComponent(splitParam[1])) : splitParam[1];
    }
    return final;
}, {});

if (!iframeParams.params) {

    iframeParams.params = {
        defaultLang: 'he',
        defaultDir: 'rtl'
    }
}

if (!iframeParams.style) {
    iframeParams.style = {}
}



app.config.globalProperties.systemLanguage = 'he';
app.config.globalProperties.dateFormatLocale = 'he-IL';
app.config.globalProperties.uiDirection = 'rtl';
app.config.globalProperties.dictionary = {};
// //
// app.config.globalProperties.systemLanguage.value = iframeParams.params?.defaultLang;
// app.config.globalProperties.dateFormatLocale.value = iframeParams.params?.defaultLang === 'he' ? 'he-IL' : 'en-US';
// app.config.globalProperties.uiDirection.value = iframeParams.params.defaultDir;


for (let k in iframeParams.params.style) {
    document.documentElement.style.setProperty(k, iframeParams.params.style[k]);
}
// const dictionary = require(`./dictionary/he`);
// app.config.globalProperties.dictionary.value = dictionary.default;

app.config.productionTip = false;

const marketStatus = require('./stores/newYorkTime');

app.use(router)
    .provide('marketStatus', marketStatus)
    .use(pinia)
    .use(VueMixpanel, {
        token: "b99efa1c6abe520ba9d1d3b71e7c22a6",
        config: {
            debug: true
        }
    })
    .component("Popper", Popper)
    .mount('#app');


