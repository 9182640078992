import {ref} from 'vue';
import eventBus from './events';

export default function createWebSocketService() {
    let ws = null;
    let isConnected = ref(false);
    let reconnectInterval = null;

    let latestStreams = {}

    function connect() {
        let WS_URL = process.env.VUE_APP_SOCKET_BASE_URL;
        ws = new WebSocket(WS_URL);

        ws.onopen = () => {
            isConnected.value = true;
            eventBus.emit('socketConnected')
            updateNotificationRooms();
            clearInterval(reconnectInterval);
        };

        ws.onclose = () => {
            isConnected.value = false;
            reconnect();
        };

        ws.onerror = () => {
            isConnected.value = false;
        };

        ws.onmessage = (event) => {
            // Handle incoming messages
            const data = JSON.parse(event.data);
            if (data.symbol && data.event === 'price') {

                eventBus.emit('priceUpdate', data);
            } else if (data.symbol && (['preMarketPrice', 'postMarketPrice'].includes(data.event))) {

                eventBus.emit(data.event, data);
            } else {
                if (data.event === '_n') {
                    if (data.data.notificationtype === 'equity') {
                        eventBus.emit('newEquityNotification', data.data);
                    } else {
                        eventBus.emit('newNewsNotification', data.data);
                    }
                }
            }
        };
    }

    function reconnect() {
        if (!reconnectInterval) {
            reconnectInterval = setInterval(() => {
                if (!isConnected.value) {
                    connect();
                }
            }, 3000); // Retry every 3 seconds (adjust as needed)
        }
    }

    function send(message) {
        if (isConnected.value) {
            ws.send(JSON.stringify(message));
        } else {
            console.warn('Cannot send message. WebSocket is not connected.');
        }
    }

    function leaveStream(location) {

        if (isConnected.value) {
            delete latestStreams[location];
            send({
                _a: 'leaveStream',
                location
            });
        } else {
            setTimeout(() => {
                leaveStream();
            }, 1000);
        }
    }

    function updateStream(rooms, location = null, isReconnecting = false) {

        if (!isReconnecting) latestStreams[location] = rooms;
        if (isConnected.value) {
            send({
                _a: 'stream',
                _s: latestStreams[location],
                location: location
            });
        } else {
            setTimeout(() => {
                updateStream(null, location, true);
            }, 1000);
        }
    }

    function joinSocketRooms(rooms = [], _a = null) {

        if (isConnected.value) {
            send({
                _a: !_a ? 'j' : _a,
                _s: rooms
            });
        } else {
            setTimeout(() => {
                joinSocketRooms(rooms, _a);
            }, 1000);
        }
    }

    function updateNotificationRooms() {


        if (isConnected.value) {
            send({
                _a: 'f',
                _s: []
            });
        }
    }

    // Connect on initialization
    connect();

    return {
        reconnect,
        isConnected,
        joinSocketRooms,
        updateNotificationRooms,
        updateStream,
        leaveStream,
        send,
    };
}
