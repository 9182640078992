<template>

  <main>
    <symbols-search :asPage="true"/>
  </main>

</template>

<script>
import SymbolsSearch from "../components/ui/SymbolsSearch";
export default {
  name: "SearchView",
  components: {SymbolsSearch}
}
</script>

<style scoped lang="scss">


</style>
