<template>
  <div>
    <section class="notFound" style="height: unset; padding-bottom: 4rem">
      <div class="notFoundIcon">
        <i class="ri-error-warning-line"/></div>
      <h2>
        {{ dictionary.errors.emptyWatchlist }}
      </h2>
    </section>

    <p class="expl alert">
      {{ dictionary.messages.shortInstructions }}
      <router-link to="#" class="portfolioLink" @click="openSearchPanel(); clickEvent('watchlist')"><i
          class="ri-folder-line"/>
        {{ dictionary.buttons.moveToPortfolio }}
      </router-link>
    </p>
  </div>
</template>

<script>
import eventBus from "../../services/events";

export default {
  name: "EmptyPortfolio",
  methods: {
    openSearchPanel() {

      eventBus.emit('openSearchPanel');
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/vars";

.expl {
  background: var(--dailyBriefBgColor);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
}

.expl.alert {
  color: white;
  font-size: 18px;
}

.portfolioLink {
  background: var(--highlightColor);
  color: white;
  display: block;
  width: fit-content;
  text-align: center;
  margin: 25px auto;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

</style>
