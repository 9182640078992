<template>
  <p class="bigNote" :class="{small}">
    {{note}}
  </p>
</template>

<script>
export default {
  name: "BigNote",
  props: ['note', 'small']
}
</script>

<style scoped lang="scss">

@import "../../assets/style/vars";

.bigNote {
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
  font-size: 18px;
  &.small {
    font-size: 14px;
  }
  @media(max-width: 660px) {
    font-size: 14px;
  }
}
</style>
