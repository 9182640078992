<template>
  <div class="peers">
    <h3>{{ dictionary.symbolPage.moreStocks }}</h3>

    <chart-note :note="dictionary.messages.peersExpl(symbol)"/>
    <div class="peersScrollGrid">
      <div v-for="(peer, pIdx) in peers" class="peerCard">
        <symbol-link v-if="!isPreview && isProUI" :symbol="peer.symbol"/>
        <router-link v-if="isPreview || !isProUI" :to="`/${isDev ? 'devsymbol' : 'symbol'}/${peer.symbol}`"/>
        <div class="peerContainer">

          <div class="peerTitle">
          <symbol-logo :symbol="peer.symbol"/>
            <div class="peerName">
              {{ peer.name }}
            </div>
            <div class="peerSymbol">
              {{ peer.symbol }}
              <SymbolLabel
                  :symbol="{symbol: peer.symbol, price: peer.tradingData.price, changeP: peer.tradingData.changeP}"
                  :prices="{price: {price: peer.tradingData.price, changeP: peer.tradingData.changeP}}"
                  :dont-link="true" :always-on="true" :price-only="true"/>
            </div>
          </div>

          <div class="priceChangeRow">
            <div>{{dictionary.chart.periods['5d']}}</div>
            <div>{{dictionary.chart.periods['1m']}}</div>
            <div>{{dictionary.chart.periods['6m']}}</div>
            <div>{{dictionary.chart.periods['1y']}}</div>
          </div>
          <div class="priceChangeRow">
            <div class="bidi">{{peer.eodsMap['5D']}}</div>
            <div class="bidi">{{peer.eodsMap['1M']}}</div>
            <div class="bidi">{{peer.eodsMap['6M']}}</div>
            <div class="bidi">{{peer.eodsMap['1Y']}}</div>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.marketCap}}</b> <span class="bidi">{{peer.tradingData.formattedMarketCap}}</span>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.avgVolume}}</b> <span class="bidi">{{peer.tradingData.formattedAvgVolume}}</span>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.volume}}</b> <div class="unusualVolume" v-if="peer.tradingData.isAboveAvgVolume"/> <span class="bidi">{{peer.tradingData.formattedVolume}}</span>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.eps}}</b> <span class="bidi">{{peer.tradingData.formattedEps}}</span>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.TYRevenueChange}}</b> <span class="bidi">{{peer.financials.TYRevGrowth}}</span>
          </div>

          <div class="peerDataPoint">
            <b>{{symbolPageLabels.TYEPSChange}}</b> <span class="bidi">{{peer.financials.TYEPSGrowth}}</span>
          </div>


          <div class="peerDataPoint">
            <b>{{symbolPageLabels.pe}}</b> <span class="bidi">{{peer.tradingData.pe}}</span>
          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ChartNote from "../../components/charts/ChartNote";
import SymbolLogo from "../../components/symbols/symbolLogo";
import SymbolLabel from "../../components/symbols/symbol";
import SymbolLink from "../../components/symbols/symbolLink";
export default {
  name: "PeersPartial",
  components: {SymbolLink, SymbolLabel, SymbolLogo, ChartNote},
  props: ['symbol', 'peers', 'symbolPageLabels', 'isDev', 'isPreview']
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";


.peerSymbol {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 18px;
}


html[data-theme='dark'] {
  .peers {
    h3 {
      color: #fff !important;
    }

    p {
      color: #fff;
      background: var(--highlightColorOpacity);
    }
  }
}


.peers {

  box-sizing: content-box;
  padding: $minSpacing $minSpacing 0 $minSpacing;
  width: 100%;
  background: var(--accentBackground);
  margin: 2rem $minSpacing * -1 -1rem;

  h3 {
    color: var(--bgColor);
    margin: 0;
  }

  p {
    color: var(--bgColor);
    background: var(--highlightColorOpacity);
  }

  .peersGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @media (max-width: 660px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }


  .peerTitle {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
  }

  .peerDataPoint {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid var(--notficationBorderColor);
    padding: .5rem 0;

    &:last-of-type {
      border-bottom: none;
      padding: .5rem 0 0;
    }

    b{
      //margin-left: auto;
    }
  }

  .peer {
    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    position: relative;
    cursor: pointer;
    background: var(--bgColor);
    padding: $minSpacing;
    border-radius: 8px;
    box-shadow: 0 5px 11px -4px rgba(0, 0, 0, 0.15);


    .peerContainer {
      display: flex;
      align-items: center;
      gap: 10px;
    }


    .peerName {
      display: flex;
      align-items: center;
      gap: 5px;

      margin-bottom: .5rem;

    }
  }
}

.peersScrollGrid {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  .peerCard {
    position: relative;
    margin: 1rem 0;
    padding: $minSpacing;
    border-radius: 8px;
    background: var(--bgColor);
    flex: 1 0 270px;

    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.unusualVolume {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: var(--red);
  box-shadow: 0 0 10px 0 rgba(201, 1, 31, 0.35);
}

.priceChangeRow {
  display: flex;
  align-items: center;
  div {
    width: calc(100% / 4);
    text-align: center;
  }
  &:nth-of-type(2) {
    margin-top: .5rem;
    font-weight: 600;
  }
  &:nth-of-type(3) {
    margin-bottom: .5rem;
  }
}

</style>
