<template>
  <big-loader v-if="isLoading"/>
  <section v-else>
    <PageToolbar>

      <div class="row center">

        <!-- search bar -->
          <div id="searchWrapper" :dir="uiDirection">
            <i class="ri-search-2-line"/>
            <input type="search"
                   ref="searchInput"
                   :placeholder="dictionary.symbolSearch.inputLabel" v-model="searchQuery"/>

          </div>

        <label class="row center">
          Direction
          <DropDown :default-selected="'all'" :small="true"
                    @select="direction = $event.value"
                    :options="[{value: 'all', label: 'All'}, {value: 'long', label: 'Long'}, {value: 'short', label: 'Short'}, {value: 'review', label: 'Review'}]"/>
        </label>
      </div>
    </PageToolbar>
    <DataGridTable
        @scrollTopProgress="scrollTopPosition"
        @scrolled="scrolled"
        :data="list"
        :scrollPosition="scrollPosition"
        :is-compact="true"
        :hovered-columns="true"
        :key="tableKey"
        :default-sort-direction="sortDirection"
        :default-sort="sortKey"
        :height-elements="[]"
        :fixed-col="0"
    >
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  v-show="th.showCol"
                  :sortKey="sortKey"
                  :blockSort="true"
                  :tooltip="th.tooltip"
                  :key="`th-${thIndex}`"
                  :center="thIndex > 0"
                  :width="th.width"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortKey"
                  @sort="changeSort(th.sortKey)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows"
            :key="`row-${rowIndex}`">


          <td>
            <div class="symbolCell">
              <div class="symbolTd" style="margin-right: 5px;">
                <symbol-link :symbol="row.symbol"/>
                <symbol-logo :medium="true" :symbol="row.symbol"/>
                {{ row.symbol }} ({{row.numOfTrade}})
              </div>
              <short-long-label :direction="row.direction"/>
            </div>
          </td>
          <td class="hSpaceBetween">
            <div class="tradeLink" @click="showTrade(row._id)">
                <i class="ri-line-chart-line"/> See trade
<!--              <router-link :to='`/trade/${row._id}`'></router-link>-->

            </div>
            <span class="followupCount">
                <i class="ri-loop-left-line"/>
                {{ row.numberOfFollowups }}</span>
          </td>
          <td>
            <symbol-label :key="`s-${row.symbol}`"
                          :dont-link="true"
                          :price-only="true"
                          :pre-market-quote="row.tradingData?.preMarket"
                          :post-market-quote="row.tradingData?.postMarket"
                          :symbol="row.tradingData"
                          :show-prePost="true"/>
          </td>
          <td>
            <div :dir="uiDirection" class="addRemoveSymbol add fixedSixty"
                 @click="addRemoveWatchlistSymbol(row.symbol)"
                 v-if="!watchlist.watchlist.includes(row.symbol)">
              <span>{{ dictionary.buttons.addToPortfolioShort }}</span>
              <i class="ri-folder-add-line"/>
            </div>
            <div :dir="uiDirection" class="addRemoveSymbol remove fixedSixty"
                 @click="addRemoveWatchlistSymbol(row.symbol)"
                 v-if="watchlist.watchlist.includes(row.symbol)">
              <span>{{ dictionary.buttons.removeFromPortfolioShort }}</span>
              <i class="ri-folder-reduce-line"/>
            </div>
          </td>
                    <td :dir="uiDirection" class="priceChangeContainer linkable"
                        :class="{up: row.changeSincePublished > 0, down: row.changeSincePublished < 0}">
                      <router-link :to='`/trade/${row._id}`'/>
                      {{ row.changeSincePublishedFormat }}
                    </td>
          <!--          <td class="linkable">-->
          <!--            <router-link :to='`/trade/${row._id}`'/>-->
          <!--            {{ formatDate(row.dateAdded) }}-->
          <!--          </td>-->
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ formatDate(row.lastUpdate) }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.note }}
          </td>
          <td v-for="day in [1,2,3,4,5]" class="priceChangeContainer linkable"
              :class="{up: row.dailyChange?.[day] > 0, down: row.dailyChange?.[day] < 0}">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.dailyChange?.[day + 'Format'] ?? '-' }}
          </td>
          <td v-for="week in [1,2,3,4,5,6]" class="priceChangeContainer linkable"
              :class="{up: row.weeklyChange?.[week] > 0, down: row.weeklyChange?.[week] < 0}">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.weeklyChange?.[week + 'Format'] ?? '-' }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.tradingData?.formattedVolume ?? '-' }}
            <span v-if="row.tradingData.isAboveAvgVolume" class="unusualVolume">{{
                dictionary.symbolPage.unusualVolume
              }}</span>
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.tradingData.formattedMarketCap }}
          </td>
          <!--          <td :dir="uiDirection" class="priceChangeContainer linkable"-->
          <!--              :class="{up: row.eods[eod] > 0, down: row.eods[eod] < 0}"-->
          <!--              v-for="(eod, eodI) in eods">-->
          <!--            <router-link :to='`/trade/${row._id}`'/>-->
          <!--            {{ row.eods[eod] }}%-->
          <!--          </td>-->
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.sector }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.industry }}
          </td>

        </tr>
      </template>
    </DataGridTable>
  </section>
</template>

<script>
import TradesService from '../services/trades';
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";
import SymbolLabel from "../components/symbols/symbol";
import SymbolLink from "../components/symbols/symbolLink";
import SymbolLogo from "../components/symbols/symbolLogo";

import {DateTime} from "luxon";
import UserService from "../services/user";
import {useWatchlist} from "../stores/watchlist";
import ShortLongLabel from "../components/ui/ShortLongLabel";
import BigLoader from "../components/ui/BigLoader";
import PageToolbar from "../components/ui/PageToolbar";
import DropDown from "../components/ui/DropDown";

import eventBus from "../services/events";

export default {
  name: "TradesListView",
  components: {
    DropDown,
    PageToolbar, BigLoader, ShortLongLabel, SymbolLogo, SymbolLink, SymbolLabel, HeadCell, DataGridTable
  },
  data() {
    return {
      searchQuery: null,
      tableKey: 0,
      scrollPosition: null,
      direction: 'all',
      trades: [],
      isLoading: true,
      sortDirection: -1,
      sortKey: 'lastUpdate',
      watchlist: useWatchlist(),
      isScrollLoading: false,
      eods: ['1D', '5D', '1M', '6M', '1Y'],
      headers: [{
        label: this.dictionary.tables.symbol,
        sortKey: 'symbol',
        key: 'symbol',
        showCol: true
      }, {
        label: '',
        showCol: true
      }, {
        label: 'Price',
        sortKey: 'tradingData.changeP',
        showCol: true
      }, {
        label: '',
        showCol: true
      },
          {
          label: this.dictionary.trades.percentChange,
          sortKey: 'changeSincePublished',
          tooltip: this.dictionary.trades.percentChangeTooltip,
          showCol: true
        },
        //   {
        //   label: this.dictionary.trades.dateAdded,
        //   sortKey: 'dateAdded',
        //   showCol: true
        // },
        {
          label: this.dictionary.trades.lastUpdate,
          sortKey: 'lastUpdate',
          showCol: true
        },
        {
          label: 'Note',
          sortKey: 'industry',
          showCol: true
        },
        ...[1, 2, 3, 4, 5].map(day => ({
          label: `${day}D %`,
          sortKey: `dailyChange.${day}`,
          showCol: true
        })),
        ...[1, 2, 3, 4, 5, 6].map(week => ({
          label: `${week}W %`,
          sortKey: `weeklyChange.${week}`,
          showCol: true
        })),
        {
          label: this.dictionary.symbolPage.volume,
          sortKey: 'tradingData.volume',
          showCol: true
        }, {
          label: this.dictionary.symbolPage.marketCap,
          sortKey: 'tradingData.marketCap',
          showCol: true
        },
        //   {
        //   label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1d'],
        //   sortKey: 'eods.1D',
        //   showCol: true
        // }, {
        //   label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['5d'],
        //   sortKey: 'eods.5D',
        //   showCol: true
        // }, {
        //   label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['1m'],
        //   sortKey: 'eods.1M',
        //   showCol: true
        // }, {
        //   label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['6m'],
        //   sortKey: 'eods.6M',
        //   showCol: true
        // }, {
        //   label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['1y'],
        //   sortKey: 'eods.1Y',
        //   showCol: true
        // },
        {
          label: this.dictionary.tables.sector,
          sortKey: 'sector',
          showCol: true
        }, {
          label: this.dictionary.tables.industry,
          sortKey: 'industry',
          showCol: true
        }],
      filter: {},
      page: 0
    }
  },
  computed: {
    list() {

      const direction = this.direction === 'all' ? null : this.direction;
      if (this.searchQuery !== null && this.searchQuery.toString().trim().length > 0) {

        const query = this.searchQuery.toUpperCase().trim().split(/[\, ]/g).map(symbol => symbol.trim());
        return this.trades.filter(trade => {

          let queryResult = query.find(q => trade.symbol.indexOf(q) === 0);
          if (!direction) return queryResult

          return queryResult && trade.direction === direction;
        });
      }

      return this.trades.filter(trade => {

        if (!direction) return true;
        return trade.direction === direction;
      });
    }
  },
  created() {

    this.getTradesList().then().catch();

  },
  methods: {
    showTrade(tradeId) {

      eventBus.emit('showChangeableContent', {type: 'tradeView', tradeId})
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    formatDate(date) {

      if (!date) return '-';
      return DateTime.fromISO(date).toFormat('DD');
    },
    async getTradesList() {

      this.isLoading = true;
      this.trades = await TradesService.getTradesList({filter: this.filter, page: this.page});
      this.isLoading = false;
    },
    scrolled(position) {
      this.scrollPosition = position;
    },
    // Infinite scroll. The table component informs us when the pages is 80% scrolled to bottom
    scrollTopPosition(value) {

      if (value >= 0.8 /** && some loading bool **/) {

        // this.loadNextPageMethod();
      }
    },
    async changeSort(key) {

      if (key === this.sortKey) {
        this.sortDirection *= -1;
      } else {
        this.sortKey = key;
        this.sortDirection = -1;
      }

      this.tableKey++;

      // this.hasNextPage = true;
      // this.totalPages = 0;
      // this.total = 0;
      // this.page = 0;

      // await this.getDividendsData(true)
    },
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/general";

section {
  min-height: 95vh;
}

.symbolCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
}

.tradeLink {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
  background: var(--mbpGreenOpacity);
  width: fit-content;

  a {
    color: var(--mbpGreen);
    text-decoration: none;
  }
}


.addRemoveSymbol {
  flex: 0 0 55px !important;
  width: 55px;
  margin-left: auto;

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }

  i {
    display: none;
  }

  @media(max-width: 660px) {

    flex: 0 0 30px !important;
    width: 30px;
    height: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    i {
      display: block;
    }

    span {
      display: none;
    }
  }
}

.followupCount {
  padding: 2px 4px;
  color: var(--lightYellow);
  background: var(--lightYellowOpacity);
}


#searchWrapper {
  position: relative;
  z-index: 3;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    width: 160px;
    transition: all .1s ease-in;
    appearance: none;

    &.autoWidth {
      width: auto;
    }

    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}


#searchWrapper[dir="rtl"] {
  i {
    right: 10px;
  }

  input {
    padding-right: 30px;
  }
}


#searchWrapper[dir="ltr"] {
  i {
    left: 10px !important;
  }

  input {
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }
  }
}


</style>
