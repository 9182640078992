<template>
  <main>
    <div v-if="financials">

      <BigNote :note="dictionary.financials.note"/>

      <section>
        <div class="tableTitle">
          <h3>{{ dictionary.financials.incomeStatement }}</h3>

          <div class="row">
          <label>{{ dictionary.financials.periodChange }}</label>
          <toggle-switch
              :small="true" :state="incomeStatementChange !== 'yoy'" @stateChanged="incomeStatementChange === 'yoy' ? incomeStatementChange = 'q' : incomeStatementChange = 'yoy'"
              :off-text="dictionary.financials.yoy"
              :text="dictionary.financials.quarterly"/>
          </div>

        </div>

        <div class="table">
          <FinancialRow :title="''" :row="financials.incomeStatement" :key-name="'period'" :isText="true"
                        :is-header="true"/>

          <div class="financialTable">

            <FinancialRow v-for="(row, rowI) in incomeStatementData"
                          :key="`income-${rowI}`"
                          :title="dictionary.financials[row.title]"
                          :row="financials.incomeStatement"
                          :key-name="row.keyName"
                          :changeParam="incomeStatementChange"
                          :showChange="row.showChange"/>
          </div>
        </div>
      </section>

      <section>
        <div class="tableTitle">
          <h3>{{ dictionary.financials.balanceSheet }}</h3>

          <div class="row">
            <label>{{ dictionary.financials.periodChange }}</label>
            <toggle-switch
                :small="true" :state="balanceSheetChange !== 'yoy'" @stateChanged="balanceSheetChange === 'yoy' ? balanceSheetChange = 'q' : balanceSheetChange = 'yoy'"
                :off-text="dictionary.financials.yoy"
                :text="dictionary.financials.quarterly"/>
          </div>
        </div>

        <div class="table">
          <FinancialRow :title="''" :row="financials.incomeStatement" :key-name="'period'" :isText="true"
                        :is-header="true"/>

          <div class="financialTable">

            <FinancialRow v-for="(row, rowI) in balanceSheetData"
                          :key="`balance-${rowI}`"
                          :title="dictionary.financials[row.title]"
                          :row="financials.balanceSheet"
                          :changeParam="balanceSheetChange"
                          :key-name="row.keyName"
                          :showChange="row.showChange"/>
          </div>
        </div>
      </section>

      <section>
        <div class="tableTitle">
          <h3>{{ dictionary.financials.cashFlowStatement }}</h3>

          <div class="row">
            <label>{{ dictionary.financials.periodChange }}</label>
            <toggle-switch
                :small="true" :state="cashFlowChange !== 'yoy'" @stateChanged="cashFlowChange === 'yoy' ? cashFlowChange = 'q' : cashFlowChange = 'yoy'"
                :off-text="dictionary.financials.yoy"
                :text="dictionary.financials.quarterly"/>
          </div>
        </div>

        <div class="table">
          <FinancialRow :title="''" :row="financials.cashFlow" :key-name="'period'" :isText="true"
                        :is-header="true"/>

          <div class="financialTable">

            <FinancialRow v-for="(row, rowI) in cashFlowStatementData"
                          :key="`cashFlow-${rowI}`"
                          :title="dictionary.financials[row.title]"
                          :row="financials.cashFlow"
                          :changeParam="cashFlowChange"
                          :key-name="row.keyName"
                          :showChange="row.showChange"/>
          </div>
        </div>
      </section>

    </div>
  </main>
</template>

<script>

import FinancialsService from '../../services/financials';
import FinancialRow from "../../components/financials/FinancialRow";
import BigNote from "../../components/ui/BigNote";
import ToggleSwitch from "../../components/ui/ToggleSwitch";

export default {
  name: "FinancialsPartial",
  components: {ToggleSwitch, BigNote, FinancialRow},
  props: ['symbol'],
  data() {
    return {
      isLoading: true,
      financials: null,
      incomeStatementChange: 'q',
      balanceSheetChange: 'q',
      cashFlowChange: 'q',
      balanceSheetData: [{
        title: 'cashAndShortTermInvestment',
        keyName: 'cashAndShortTermInvestments',
        showChange: true
      }, {
        title: 'totalAssets',
        keyName: 'totalAssets',
        showChange: true
      }, {
        title: 'totalLiabilities',
        keyName: 'totalLiabilities',
        showChange: true
      }, {
        title: 'totalEquity',
        keyName: 'totalEquity',
        showChange: true
      }],
      cashFlowStatementData: [{
        title: 'netIncome',
        keyName: 'netIncome',
        showChange: true
      }, {
        title: 'operatingCashFlow',
        keyName: 'operatingCashFlow',
        showChange: true
      }, {
        title: 'netCashFromInvesting',
        keyName: 'netCashUsedForInvestingActivites',
        showChange: true
      }, {
        title: 'netCashFinancing',
        keyName: 'netCashUsedForInvestingActivites',
        showChange: true
      }, {
        title: 'netChangeInCash',
        keyName: 'netChangeInCash',
        showChange: true
      }, {
        title: 'freeCashFlow',
        keyName: 'freeCashFlow',
        showChange: true
      }],
      incomeStatementData: [{
        title: 'revenue',
        keyName: 'revenue',
        row: 'incomeStatements',
        showChange: true
      }, {
        title: 'operatingExpenses',
        keyName: 'operatingExpenses',
        row: 'incomeStatements',
        showChange: true
      }, {
        title: 'netIncome',
        keyName: 'netIncome',
        row: 'incomeStatements',
        showChange: true
      }, {
        title: 'eps',
        keyName: 'EPS',
        row: 'incomeStatements',
        showChange: true
      }, {
        title: 'ebitda',
        keyName: 'EBITDA',
        row: 'incomeStatements',
        showChange: true
      }, {
        title: 'netProfitMargin',
        keyName: 'netIncomeRatio',
        row: 'incomeStatements',
        showChange: true
      }]
    }
  },
  methods: {
    async getFinancialsForSymbol() {

      this.financials = await FinancialsService.financialsForSymbol(this.symbol);
    }
  },
  async mounted() {
    await this.getFinancialsForSymbol();
  }
}
</script>

<style scoped lang="scss">

@import "./src/assets/style/vars";
@import "../../assets/style/financials";

main {
  padding: 0 $minSpacing;
}

h3 {

  margin-bottom: 1rem;
}

.table {
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--notficationBorderColor);

  @media(max-width: 600px) {
    white-space: nowrap;
    overflow-x: scroll;
  }
}

.tableTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 500;
  cursor: pointer !important;
  user-select: none;

  * {
    cursor: pointer !important;
    user-select: none;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    label {
      font-weight: 400;
      opacity: .8;
    }

    .switch {
      width: 30px;
      height: 16px;
      border-radius: 10px;
      background: var(--grayOpacity);
      position: relative;

      .switchButton {
        border-radius: 50%;
        background: var(--bgColor);
        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .1);
        position: absolute;
        width: 14px;
        height: 14px;
        top: 1px;
        left: calc(100% - 16px);
        transition: all .2s ease-in;
      }

      .switchButton.q {
        left: 1px;
      }
    }
  }
}
</style>
