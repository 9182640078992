<template>
<label>
  <span class="outerLabel" v-if="label">{{label}}</span>
  <DropDown
      :full-width="fullWidth"
      :options="options"
      :clear="clear"
      ref="filter"
      :allowFreeTyping="false"
      @clear="$emit('clear', filterKey)"
      @select="$emit('select', {filter: filterKey, data: $event})"
    />
</label>
</template>

<script>
import DropDown from "../ui/DropDown";
import DropDownAutoComplete from "../ui/DropdownAutoComplete";
export default {
  name: "DividendPayoutRatio",
  components: {DropDownAutoComplete, DropDown},
  props: ['autocomplete', 'multi', 'clear', 'fullWidth', 'industries', 'label', 'filterKey'],
  data () {
    return {
      options: [{
        label: `100% ${this.dictionary.filters.andLess}`,
        value: '-100'
      }, {
        label: `80% ${this.dictionary.filters.andLess}`,
        value: '-80'
      }, {
        label: `50% ${this.dictionary.filters.andLess}`,
        value: '-50'
      }]
    }
  },
  methods: {
    clearFilter() {
      this.$refs.filter.clearSelection(false);
    }
  }
}
</script>

<style scoped>

</style>
