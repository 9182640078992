<template>
  <small-loader v-if="!data"/>
  <div class="chartArea" v-if="data">
    <h2>{{ data.labels.title }}</h2>
    <ChartNote :note="data.labels.note" v-if="data.labels.note"/>
    <StackChart :series="displayData" :yAxisFormat="yAxisFormat" :toolTipFormatter="toolTipFormatter" :labels="data.xAxisLabels" v-if="data"/>

  </div>
</template>

<script>
import PerformanceService from "../../services/performance";
import StackChart from "./StackChart";
import ChartNote from "./ChartNote";
import SmallLoader from "../ui/SmallLoader";

export default {
  name: "ExpensesChart",
  props: ['symbol'],
  components: {SmallLoader, ChartNote, StackChart},
  data() {
    return {
      data: null,
      yAxisFormat: (value) => {

      }
    }
  },
  methods: {
    formatCash: (n) => {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    }
  },
  computed: {
    yAxisFormat: (value) => {

      if (!value) return '';

      if (typeof value === 'object') return '';

      if (isNaN(value)) return value;

      const absoluteValue = Math.abs(value);
      if (absoluteValue < 1000) {
        return value; // Return the number as is if it's less than 1000
      } else if (absoluteValue < 1000000) {
        return (value / 1000).toFixed(1) + 'K'; // Convert to K (thousands)
      } else if (absoluteValue < 1000000000) {
        return (value / 1000000).toFixed(1) + 'M'; // Convert to M (millions)
      } else {
        return (value / 1000000000).toFixed(1) + 'B'; // Convert to B (billions) or higher
      }
    },
    toolTipFormatter() {
      return (params) => {

        let tooltip = `
                <div style="color: black; unicode-bidi: plaintext; font-family: 'Assistant', sans-serif; min-width: 150px">
                <div style="text-align: center"><b>${params[0].name}</b></div>
`;

        params.forEach(dataSet => {
          tooltip += dataSet.data !== null ? `<table cellpadding="0" cellspacing="0">
                <tr>
                    <td style="width: 18px;"><div class="legendTooltip" style="background: ${dataSet.color}"/></td>
                    <td>${dataSet.seriesName}</td>
                    <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">$${this.formatCash(dataSet.data)}</span></td>
                  </tr>
                </table>` : '';
        });

        tooltip += '</div>';
        return tooltip;
      }
    },
    displayData() {
      const colors = ['#2079f2', 'rgb(227, 158, 0)', '#9400FF', '#0ca3ab', 'rgba(201, 1, 31, 1)']

      return this.data.series.map((series, index) => {

        series.color = colors[index];
        return series;
      });
    }
  },
  async mounted() {
    this.data = await PerformanceService.getCompanyExpenses({symbol: this.symbol, query: {}})
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/performance";


</style>
