<template>

  <Modal>
    <template v-slot:title>
      <h3>{{dictionary.settingsPage.titles.verifyClosingOfAccount}}</h3>
    </template>

    <template v-slot:content>
      <div class="content">
        {{dictionary.settingsPage.notes.verifyClosingOfAccount}}
      </div>
    </template>

    <template v-slot:actions>
      <div class="actions">
        <MBPButton class="danger" @click="$emit('approve')" :label="dictionary.settingsPage.buttons.approveClosingOfAccount"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../../components/ui/Modal";
import MBPButton from "../../../components/ui/controls/MBPButton";

export default {
  name: "VerifyAccountClosingModal",
  components: {MBPButton, Modal},
  mounted() {
  }
}
</script>

<style scoped lang="scss">

</style>
