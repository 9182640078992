<template>
  <section>
  <SymbolBigPriceChart :symbol="symbol" :period="defaultPeriod"
                       :defaultChart="'candlestick'"
                       ref="chart"
                       v-if="nyTime && equityPrice && chartHeight"
                       :current-price="equityPrice.price"
                       :chart-height="chartHeight"
                       :chartOptions="['volume', 'zoom', 'legend', 'toolbar', 'periods']"
                       :toolbar="['candles','line','ma','compareIndexes', 'compareEquity']"/>
  </section>

</template>

<script>
import SymbolBigPriceChart from "../../components/charts/SymbolBigPriceChart";
import {useNewYorkTime} from "../../stores/newYorkTime";
import {getCurrentInstance, onMounted, ref} from "vue";
import extraEventData from "../../composables/eventTagExtraData";
import {useEquityPrices} from "../../stores/equityPrices";
export default {
  name: "ChartPartial",
  props: ['symbol', 'equityPrice', 'symbolInfo'],
  components: {SymbolBigPriceChart},
  data () {
    return {
      chartHeight: null,
      chartRefreshInterval: null,
    }
  },
  setup() {
    const equityPrices = useEquityPrices();
    const nyTime = useNewYorkTime();
    const app = getCurrentInstance();

    function startDataRefresh() {

      if (!app.ctx.chartRefreshInterval) {
        app.ctx.chartRefreshInterval = setInterval(() => {
          app.refs.chart.loadData(true);
        }, 60000);
      }
    }

    onMounted(() => {

      setTimeout(() => {

        if (nyTime.isMarketOpen) {

          if (app.refs.chart) {
            startDataRefresh();
          } else {
            setTimeout(() => {

              startDataRefresh();
            }, 5000);
          }
        }
      }, 10000);
    });

    const defaultPeriod = nyTime.isMarketOpen ? '1d' : '1m'
    return {extraEventData, nyTime, defaultPeriod, equityPrices}
  },
  created() {
    const navBarHeight = document.getElementById('navBar')?.offsetHeight || 0;
    const pageTabsHeight = document.getElementById('pageTabs')?.offsetHeight || 0;
    const marquee = document.querySelector('.marquee')?.offsetHeight || 0;
    const screenHeight = window.innerHeight;

    this.chartHeight = screenHeight - ((navBarHeight < 100 ? navBarHeight : 0) + pageTabsHeight + marquee);
  }
}
</script>

<style scoped lang="scss">

</style>
