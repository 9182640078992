<template>
  <label :for="labelId">
    {{modelValue}}
    <input type="checkbox" :name="name" :id="labelId" v-bind="$attrs" :value="value" :checked="defaultChecked || isChecked"  @change="$emit('update:modelValue', $event.target.checked)">
    {{label}}
  </label>
</template>

<script>
export default {
  name: "MBPCheckbox",
  props: ['labelId', 'label', 'value', 'name', 'values', 'modelValue', 'defaultChecked'],
  computed: {
    isChecked() {
      return this.values ? this.values.includes(this.value) : false;
    }
  },
}
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
}
</style>
