const PATH = '/';

export default {
    async getSectorFeed(symbol, query) {
        return axios.get(`${PATH}sector/${symbol}`, {params: query});
    },
    async getFeed(query) {

        return axios.get(`${PATH}feed`, {params: query});
    },
    async newsBySymbols({symbols, query}) {

        return axios.get(`${PATH}symbols/${symbols}`, {params: query});
    },
    async macroNews({symbols, query}) {

        return axios.get(`${PATH}macro`, {params: query});
    },
    async readNews({link}) {

        return axios.get(`${PATH}feed/read/${link}`,);
    },
    async createFilteredFeed(filterParams) {

        return axios.post(`${PATH}feed/filtered`, filterParams);
    },
    async getFeedByFilterId(filterId, query) {

        return axios.get(`${PATH}feed/filtered/${filterId}`, {params: query});
    }
}
