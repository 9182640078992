<template>

  <div class="priceBlock" :dir="uiDirection">

    <div class="priceBlockTitle">{{ title }}</div>
    <div class="priceBlockPrice">
      <Popper
          arrow
          :hover="true"
          :content="tooltip"
          v-if="tooltip"
      >
        <div class="tooltipIcon">
          <i class="ri-information-line"/>
        </div>
      </Popper>

      {{ price }}

    </div>



  </div>
</template>

<script>

import Popper from "vue3-popper";

export default {
  name: "symbolPagePriceBlock",
  props: ['title', 'price', 'tooltip'],
  components: { Popper }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";


</style>
