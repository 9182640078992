<template>
  <div class="sort" v-if="currentSortValue === sortValue" @click="$emit('sort')" :dir="uiDirection
">
    <i class="ri-arrow-down-s-line" v-if="currentSortDirection === -1"/>
    <i class="ri-arrow-up-s-line" v-if="currentSortDirection === 1"/>
  </div>

</template>

<script>
export default {
  name: "SortHoldings",
  props: ['sectorSymbol', 'currentSortValue', 'currentSortDirection', 'sortValue', 'showSign']
}
</script>

<style scoped lang="scss">

  .sort {
    cursor: pointer;
  }
</style>
