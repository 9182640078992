<template>

  <div class="filterPanel">
    <i :dir="uiDirection" class="close ri-close-line" style="cursor: pointer" @click="$emit('close')"/>

    <slot/>
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: ['title']
}
</script>

<style scoped lang="scss">


h1 {
  margin-top: 0;
  padding-top: 0;
}

.filterPanel {
  background: var(--secondaryBgColor);
  border-top: 1px solid var(--tableHoverColor);
  border-right: 1px solid var(--tableHoverColor);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @media(max-width: 660px) {
    height: 100%;
    border-radius: 0;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    padding: .5rem;
    margin: -.5rem;
    border-radius: 50%;

    &[dir="rtl"] {
      right: unset;
      left: 1rem
    }

    &:hover {
      background: var(--tableHoverColor);
    }
  }
}
</style>
