<template>
  <div class="row" :class="{header: isHeader}">
    <div class="title">
      {{ title }}
    </div>

    <div class="value" v-for="(value, vIndex) in row">
      {{!isText ? formatNumber(value[keyName], '0,00.000a').toUpperCase() : value[keyName]}}
      <span v-if="!isText" class="change" :key="`${keyName}-${vIndex}`" :class="{changePositive: value[changeKeyName] && value[changeKeyName] > 0, changeNegative: value[changeKeyName] && value[changeKeyName] < 0}">
        {{formatNumber(value[changeKeyName], '0.[00]', '%')}}
      </span>
    </div>

  </div>
</template>

<script>
import numeral from "numeral";

export default {
  name: "FinancialRow",
  props: ['title', 'row', 'keyName', 'isText', 'isHeader', 'showChange', 'changeParam'],
  computed: {
    changeKeyName () {

      return `${this.keyName}${this.changeParam === 'q' ? 'Q' : 'YoY'}Change`
    }
  },
  methods: {
    formatNumber(value, format, sign) {
      if (value === null || value === undefined) return '-';
      if (value.toString().includes(' - ')) {

        let splitRange = value.split(' - ').map(valuePart => numeral(valuePart).format(format))
        return splitRange.join(' - ')
      }
      return numeral(value).format(format) + (sign || '')
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/financials";

</style>
