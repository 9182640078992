<template>
  <p v-if="note" class="chartNote" :dir="uiDirection">
    <i class="ri-lightbulb-line"/>
    {{note}}
  </p>
</template>

<script>
export default {
  name: "ChartNote",
  props: ['note']
}
</script>

<style scoped lang="scss">
@import "../../assets/style/performance";

</style>
