<template>
  <h3 class="feedTitle" v-if="!isPartial">{{ dictionary.dailyBrief.indices.title }}</h3>

  <section v-for="(index, indexIdx) in indices">
    <b class="miniTitle">{{ index.labels[systemLanguage] }}</b>
    <div v-for="(symbol, sIdx) in index.symbols" class="row symbolRow" :dir="uiDirection">
      <div class="symbol">{{ symbol.name }}</div>
      <div class="price">
        <symbol-label :alwaysOn="true" :always-colored="true" :hide-pre-post="true" :dont-link="true" :priceOnly="true" :symbol="symbol" :prices="prices"/>
      </div>
    </div>
  </section>

</template>

<script>
import SymbolLabel from "../../components/symbols/symbol";

export default {
  name: "IndicesPartial",
  components: {SymbolLabel},
  props: ['prices', 'isPartial'],
  data() {
    return {
      indices: [
        {
          labels: {
            he: 'ארה״ב',
            en: 'US market'
          },
          symbols: [
            {name: 'S&P 500', symbol: 'GSPC', price: null, changeP: null},
            {name: 'NASDAQ', symbol: 'IXIC', price: null, changeP: null},
            {name: 'NASDAQ 100', symbol: 'NDX', price: null, changeP: null},
            {name: 'Dow Jones', symbol: 'DJI', price: null, changeP: null},
            {name: 'VIX', symbol: 'VIX', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Europe markets', he: 'אירופה'},
          symbols: [
            {name: 'EURONEXT 100', symbol: 'N100', price: null, changeP: null},
            {name: 'FTSE 100', symbol: 'FTSE', price: null, changeP: null},
            {name: 'DAX', symbol: 'GDAXI', price: null, changeP: null},
            {name: 'TA-125', symbol: 'TA125.TA', price: null, changeP: null},
            {name: 'CAC 40', symbol: 'FCHI', price: null, changeP: null},
            {name: 'IBEX 35', symbol: 'IBEX', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Asian markets', he: 'אסיה'},
          symbols: [
            {name: 'KOSPI Composite', symbol: 'KS11', price: null, changeP: null},
            {name: 'HANG SENG', symbol: 'HSI', price: null, changeP: null},
            {name: 'Nikkei 225', symbol: 'N225', price: null, changeP: null},
            {name: 'NIFTY 50', symbol: 'NSEI', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Crypto', he: 'קריפטו'},
          symbols: [
            {name: 'Bitcoin USD', symbol: 'BTCUSD', price: null, changeP: null},
            {name: 'Ethereum USD', symbol: 'ETHUSD', price: null, changeP: null},
            {name: 'XRP USD', symbol: 'XRPUSD', price: null, changeP: null},
            {name: 'Tether USD', symbol: 'USDTUSD', price: null, changeP: null},
            {name: 'Tether USD', symbol: 'USDTUSD', price: null, changeP: null},
            {name: 'BNB', symbol: 'BNBUSD', price: null, changeP: null},
            {name: 'Solana', symbol: 'SOLUSD', price: null, changeP: null},
            {name: 'USD', symbol: 'USDCUSD', price: null, changeP: null},
            {name: 'Cardano', symbol: 'ADAUSD', price: null, changeP: null},
            {name: 'Avalanche', symbol: 'AVAXUSD', price: null, changeP: null},
            {name: 'Dogecoin', symbol: 'DOGEUSD', price: null, changeP: null},
          ]
        },
        {
          labels: {en: 'Commodities', he: 'סחורות'},
          symbols: [
            {name: 'Crude Oil', symbol: 'CLUSD', price: null, changeP: null},
            {name: 'Brent', symbol: 'BZUSD', price: null, changeP: null},
            {name: 'Gold', symbol: 'GCUSD', price: null, changeP: null},
            {name: 'Silver', symbol: 'SIUSD', price: null, changeP: null},
            {name: 'Platinum', symbol: 'PLUSD', price: null, changeP: null},
            {name: 'Copper', symbol: 'HGUSD', price: null, changeP: null},
            {name: 'Natural Gas', symbol: 'NGUSD', price: null, changeP: null},
            {name: 'Corn', symbol: 'ZCUSX', price: null, changeP: null}
          ]
        },

      ]
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";
@import "../../assets/style/dailyBrief";
.miniTitle {
  padding: 5px 10px;
  border-bottom: 2px solid var(--notficationBorderColor);
  display: block;
  font-weight: 500;
  font-size: 18px;
  background: var(--tableHoverColor);
}

.symbolRow {
  justify-content: space-between;
  .symbol {
    width: 50%;
  }
}

section {
  margin-bottom: .5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
