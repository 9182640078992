const PATH = '/macro/top';

export default {
    async getTopTen() {

        return axios.get(`${PATH}`);
    },
    async getTopTenPro(category, tradingTime) {

        return axios.get(`${PATH}ro/${category}?tradingTime=${tradingTime}`);
    }
}
