import UserServices from '../services/user';
import {useWatchlist} from "../stores/watchlist";

export default function watchlistManager() {

    async function addSymbolToWatchlist (symbol) {

        const watchlist = await UserServices.addSymbolToWatchlist(symbol);
        this.watchlist.updateWatchlist(watchlist);
    }

    async function removeSymbolFromWatchlist (symbol) {

        const watchlist = await UserServices.removeSymbolFromWatchlist(symbol);
        this.watchlist.updateWatchlist(watchlist);
    }

    return {
        addSymbolToWatchlist,
        removeSymbolFromWatchlist,
        watchlist: useWatchlist()
    }
}
