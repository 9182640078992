<template>
  <main id="calendars">

    <nav role="navigation" id="calendarFilterPanel">
      <div class="calendarTabs" :dir="uiDirection">
        <div class="tab" :class="{active: calendarType === 'earnings'}"
             @click="calendarType = 'earnings'; loadCalendar()">
          {{ dictionary.calendars.selection.earnings }}
        </div>
        <div class="tab" :class="{active: calendarType === 'dividends'}"
             @click="calendarType = 'dividends'; loadCalendar()">
          {{ dictionary.calendars.selection.dividends }}
        </div>
        <div class="tab" :class="{active: calendarType === 'splits'}"
             @click="calendarType = 'splits'; loadCalendar()">
          {{ dictionary.calendars.selection.splits }}
        </div>
        <div class="tab" :class="{active: calendarType === 'ipos'}"
             @click="calendarType = 'ipos'; loadCalendar()">{{ dictionary.calendars.selection.ipos }}
        </div>
      </div>


    </nav>

    <section>

      <div id="calendarToolbar">
        <div id="searchWrapper" :dir="uiDirection">
          <i class="ri-search-2-line"/>
          <input type="search" :placeholder="dictionary.symbolSearch.inputLabel" v-model="query" @keyup="searchSymbols">
        </div>

        <div class="filters">
          <toggle-switch
              v-if="calendarType !== 'ipos'"
              :small="true" :state="myWatchlistOnly" @stateChanged="myWatchlistOnly = !myWatchlistOnly; loadCalendar()"
              :off-text="dictionary.calendars.labels.all"
                         :text="dictionary.calendars.labels.myWatchlistOnly"/>

          <DropDown :small="true" :options="periods" default-selected="today" @select="period = $event.value; loadCalendar()"/>

<!--          <label class="datePicker" for="periodPicker">-->
<!--            <i class="ri-calendar-line"/>-->
<!--            <select v-model="period" :dir="uiDirection" ref="periodPicker" @change="loadCalendar" id="periodPicker">-->
<!--              <option value="lastWeek">{{ dictionary.calendars.dates.lastWeek }}</option>-->
<!--              <option value="past7Days">{{ dictionary.calendars.dates.past7Days }}</option>-->
<!--              <option value="yesterday">{{ dictionary.calendars.dates.yesterday }}</option>-->
<!--              <option value="today">{{ dictionary.calendars.dates.today }}</option>-->
<!--              <option value="thisWeek">{{ dictionary.calendars.dates.thisWeek }}</option>-->
<!--              <option value="next7Days">{{ dictionary.calendars.dates.next7Days }}</option>-->
<!--              <option value="nextWeek">{{ dictionary.calendars.dates.nextWeek }}</option>-->
<!--              <option value="thisMonth">{{ dictionary.calendars.dates.thisMonth }}</option>-->
<!--              <option value="nextMonth">{{ dictionary.calendars.dates.nextMonth }}</option>-->
<!--            </select>-->
<!--          </label>-->
        </div>

      </div>

      <div  v-if="!isLoading">
      <EarningsCalendar v-if="calendarType === 'earnings'" :calendar="groupByDates"/>
      <SplitsCalendar v-if="calendarType === 'splits'" :calendar="groupByDates"/>
      <IPOcalendar v-if="calendarType === 'ipos'" :calendar="groupByDates"/>
      <DividendCalendar v-if="calendarType === 'dividends'" :calendar="groupByDates"/>
      </div>
    <BigLoader v-if="isLoading"/>
    </section>
    <section class="notFound" v-if="!isLoading && calendar.length === 0">
      <div class="notFoundIcon">
        <i class="ri-error-warning-line"/></div>
      <h2>{{ dictionary.calendars.noData }}</h2>
    </section>
  </main>
</template>

<script>

import CalendarsService from '@/services/calendars';
import EarningsCalendar from "../components/calendars/EarningsCalendar";
import {DateTime} from "luxon";
import SplitsCalendar from "../components/calendars/SplitsCalendar";
import IPOcalendar from "../components/calendars/IPOcalendar";
import BigLoader from "../components/ui/BigLoader";
import mixpanel from 'mixpanel-browser';
import DividendCalendar from "../components/calendars/DividendCalendar";
import ToggleSwitch from "../components/ui/ToggleSwitch";
import DropDown from "../components/ui/DropDown";

export default {
  name: "Calendars",
  components: {DropDown, ToggleSwitch, DividendCalendar, BigLoader, IPOcalendar, SplitsCalendar, EarningsCalendar},
  data() {
    return {
      periods: [{
        "value": "lastWeek",
        "label": this.dictionary.calendars.dates.lastWeek
      },
        {
          "value": "past7Days",
          "label": this.dictionary.calendars.dates.past7Days
        },
        {
          "value": "yesterday",
          "label": this.dictionary.calendars.dates.yesterday
        },
        {
          "value": "today",
          "label": this.dictionary.calendars.dates.today
        },
        {
          "value": "thisWeek",
          "label": this.dictionary.calendars.dates.thisWeek
        },
        {
          "value": "next7Days",
          "label": this.dictionary.calendars.dates.next7Days
        },
        {
          "value": "nextWeek",
          "label": this.dictionary.calendars.dates.nextWeek
        },
        {
          "value": "thisMonth",
          "label": this.dictionary.calendars.dates.thisMonth
        },
        {
          "value": "nextMonth",
          "label": this.dictionary.calendars.dates.nextMonth
        }],
      myWatchlistOnly: true,
      debounce: null,
      query: null,
      calendar: [],
      calendarType: 'earnings',
      period: [0, 6].includes(new Date().getDay()) ? 'thisWeek' : 'today',
      isLoading: true,
      isWeekend: [0, 6].includes(new Date().getDay())
    }
  },
  computed: {
    groupByDates() {


      let dates = {};

      this.calendar.forEach(event => {

        const dateString = DateTime.fromISO(event.date).setLocale(this.dateFormatLocale).toFormat('yyyy-MM-dd');
        const dateFormat = DateTime.fromISO(event.date).setLocale(this.dateFormatLocale).toFormat('DD');

        if (!dates[dateString]) {
          dates[dateString] = {
            date: dateString,
            format: dateFormat,
            events: []
          };
        }

        dates[dateString].events.push(event);
      });

      return Object.values(dates);
    }
  },
  methods: {
    async searchSymbols() {

      if (this.debounce) clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        // this.isLoading = true;
        const period = this.period;
        const calendarType = this.calendarType;

        try {
          mixpanel.track('calendar:search', {client: window.location.host, search: this.query, period: this.period});
        } catch (e) {

        }

        this.calendar = await CalendarsService.getCalendar({period, calendarType, query: this.query});
        // this.isLoading = false;
      }, 300);
    },
    async loadCalendar() {

      try {
        mixpanel.track('calendar:load', {client: window.location.host, type: this.calendarType, period: this.period});
      } catch (e) {

      }

      this.query = null;
      this.isLoading = true;
      const period = this.period;
      const calendarType = this.calendarType;
      const myWatchlistOnly = this.myWatchlistOnly;

      this.calendar = await CalendarsService.getCalendar({period, calendarType, myWatchlistOnly});
      this.isLoading = false;
    }
  },
  async beforeMount() {

    const query = this.$route.query;
    if (query && query.calendar) this.calendar = query.calendar;
    if (query && query.period) this.period = query.period;
    await this.loadCalendar();
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/calendar";
@import "../assets/style/vars";

#searchWrapper {
  position: relative;
  z-index: 3;
  max-width: 50%;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    margin: 5px;
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    transition: all .1s ease-in;
    width: calc(100% - 1rem);

    @media(max-width: 660px) {
      margin: 0;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--highlightColorOpacity);
    }
  }
}


#searchWrapper[dir="rtl"] {
  i {
    right: 10px;
  }

  input {
    padding-right: 30px;
  }
}

#searchWrapper[dir="ltr"] {
  i {
    left: 15px;
  }

  input {
    padding-left: 30px;
  }
}


#calendarToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--tableHoverColor);
  border-bottom: 2px solid var(--notficationBorderColor);
  padding: 5px 0;

  @media (max-width: 660px) {
    align-items: flex-start;
    padding: 5px;
  }

  .datePicker {
    box-sizing: border-box;
    margin: 0 1rem;
    select {
      width: 100%;
    }
  }

  #searchWrapper {
    width: 70%;
  }
}


.filters {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;

  @media(max-width: 660px) {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-start;
    flex: 1 0 fit-content;

    .datePicker {
      margin: 0 !important;
      width: 100%;
    }
  }
}


</style>
