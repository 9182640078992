<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
import {DateTime} from "luxon";

export default {
  name: "barlinechart",
  props: ['series', 'labels', 'xAxisValues', 'xAxisLabels', 'yAxisFormat', 'chartValueKey', 'toolTipFormatter'],
  data() {
    return {
      options: {}
    }
  },
  methods: {},
  beforeUnmount() {
    echarts.dispose(this.$refs.chart);
  },
  mounted() {

    const colors = ['#2079f2', 'rgb(227, 158, 0)', '#8843F2']

    if (this.$refs.chart && this.series) {

      let chart = echarts.init(this.$refs.chart);
      this.options = {
        title: {
          show: false,
        },
        grid: {
          top: '3%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          textStyle: {
            color: this.systemTheme === 'darkMode' ? '#ccc' : '#333'
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.toolTipFormatter,
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: this.xAxisLabels,
        },
        yAxis: [{
          splitLine: {
            lineStyle: {
              color: this.systemTheme === 'darkMode' ? '#333' : '#eee'
            }
          },
          type: 'value',
          // axisLabel: {
          //   formatter: this.yAxisFormat,
          //   align: 'right'
          // }
        }, {
          type: 'value'
        }],
        calculable: true,
        dataZoom: [
          {
            show: true,
            start: 40,
            end: 100
          },
          {
            type: 'inside',
            start: 40,
            end: 100
          },
        ],
        series: [...this.series.bars.map((series, index) => {
          return {
            color: colors[index],
            data: series,
            type: 'bar'
          }
        }),
          ...this.series.lines.map((series, index) => {
            return {
              color: colors[this.series.bars.length + index],
              data: series,
              type: 'line'
            }
          })]
      };

      new ResizeObserver(() => {
        chart.resize();
      }).observe(this.$refs.chart);

      chart.setOption(this.options);

    }
  }
}


</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
