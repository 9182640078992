<template>
<label>
  <span class="outerLabel" v-if="label">{{label}}</span>
  <DropDown
      :full-width="fullWidth"
      :options="options"
      :clear="clear"
      ref="filter"
      :allowFreeTyping="false"
      @clear="$emit('clear', filterKey)"
      @select="$emit('select', {filter: filterKey, data: $event})"
    />
</label>
</template>

<script>
import DropDown from "../ui/DropDown";
import DropDownAutoComplete from "../ui/DropdownAutoComplete";
export default {
  name: "DividendConsecutiveYearsPayout",
  components: {DropDownAutoComplete, DropDown},
  props: ['autocomplete', 'multi', 'clear', 'fullWidth', 'industries', 'label', 'filterKey'],
  data () {
    return {
      options: [{
        label: `5 ${this.dictionary.filters.years} ${this.dictionary.filters.andMore}`,
        value: '+5'
      }, {
        label: `10 ${this.dictionary.filters.years} ${this.dictionary.filters.andMore}`,
        value: '+10'
      }, {
        label: `15 ${this.dictionary.filters.years} ${this.dictionary.filters.andMore}`,
        value: '+15'
      }, {
        label: `20 ${this.dictionary.filters.years} ${this.dictionary.filters.andMore}`,
        value: '+20'
      }, {
        label: `40 ${this.dictionary.filters.years} ${this.dictionary.filters.andMore}`,
        value: '+40'
      }]
    }
  },
  methods: {
    clearFilter() {
      this.$refs.filter.clearSelection(false);
    }
  }
}
</script>

<style scoped>

</style>
