<template>
  <div class="imageThumbnail" :class="{border}">
    <a :href="setFileURL(imageName, 'image')" v-if="imageName" target="_blank"/>
    <img :src="setFileURL(imageName, 'image')" v-if="imageName" :alt="alt || 'Marketbit.pro'"/>

    <img v-if="base64" :src="base64" ref="image" :alt="alt || 'Marketbit.pro'"/>
  </div>
</template>

<script>
import setFileURL from "../../composables/setFileURL";

export default {
  name: "imageThumbnail",
  props: ['imageName', 'base64', 'alt', 'border'],
  setup() {

    return { setFileURL };
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";

</style>
