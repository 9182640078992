<template>
  <div class="logo" :class="{small, medium}" :style="setLogoBg(symbol)"/>
</template>

<script>
export default {
  name: "symbolLogo",
  props: ['symbol', 'small', 'medium'],
  methods: {
    setLogoBg(symbol) {

      return {backgroundImage: `url(https://financialmodelingprep.com/image-stock/${symbol}.png)`}
    },
  }
}
</script>

<style scoped lang="scss">
.logo {
  flex: 0 0 36px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: #131313;
  background-size: 22px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
}

.logo.small {
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  background-size: 10px;
}

.logo.medium {
  width: 26px;
  height: 26px;
  flex: 0 0 26px;
  background-size: 15px;
}

</style>
