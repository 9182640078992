<template>
  <div class="chart" :style="setHeight()" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
import {DateTime} from "luxon";

export default {
  name: "barchart",
  props: ['series', 'height', 'labels', 'rotateLabel', 'xAxisValues', 'xAxisLabels', 'yAxisFormat', 'chartValueKey', 'toolTipFormatter', 'legend', 'noZoom', 'positiveNegative'],
  data() {
    return {
      options: {}
    }
  },
  methods: {
    setHeight () {
      if (this.height) return {height: this.height + 'px'}
      return {};
    }
  },
  beforeUnmount() {
    echarts.dispose(this.$refs.chart);
  },
  mounted() {

    const positiveColor = 'rgb(13, 185, 128)';
    const negativeColor = 'rgba(201, 1, 31, 1)';

    const colors = ['#2079f2', 'rgb(227, 158, 0)', '#8843F2']

    if (this.$refs.chart && this.series) {

      let chart = echarts.init(this.$refs.chart);
      this.options = {
        title: {
          show: false,
        },
        grid: {
          top: this.legend && this.legend.length > 0 ? '10%' : '3%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.toolTipFormatter,
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: this.legend || [],
          textStyle: {
            color: this.systemTheme === 'darkMode' ? '#ccc' : '#333'
          },
        },
        xAxis: {
          type: 'category',
          data: this.xAxisLabels,
          axisLabel: {
            rotate: this.rotateLabel || 0
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: this.systemTheme === 'darkMode' ? '#333' : '#eee'
            }
          },
          axisLabel: {
            formatter: this.yAxisFormat,
            align: 'right'
          }
        },
        calculable: true,
        dataZoom: this.noZoom ? false : [
          {
            show: true,
            start: 30,
            end: 100
          },
          {
            type: 'inside',
            start: 30,
            end: 100
          },
        ],
        series: this.series.map((series, index) => {
          return {
            name: this.legend && this.legend[index],
            color: colors[index],
            data: this.positiveNegative ? series.map(data => {
              return {
                value: data,
                itemStyle: {
                  color: data >= 0 ? positiveColor : negativeColor
                }
              }
            }) : series,
            type: 'bar'
          }
        })
      };

      new ResizeObserver(() => {
        chart.resize();
      }).observe(this.$refs.chart);

      chart.setOption(this.options);

    }
  }
}


</script>

<style scoped>
.chart {
  height: 350px;
}
</style>
