<template>
  <small-loader v-if="!data"/>
  <div class="chartArea" v-if="data">
    <h2>{{ data.labels.title }}</h2>
    <Barchart :series="displayData" :labels="data.labels"
              :tool-tip-formatter="toolTipFormatter"
              :yAxisFormat="yAxisFormat"
              :legend="legend"
              :xAxisLabels="data.xAxisLabels" v-if="data"/>

    <div class="col" v-if="false">
    <div class="extraData">
      <h3>{{data.labels.revenue}}</h3>
      <div class="extraDataLine" v-if="data.extraData.lastQChange">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.lastQChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.lastQChange.value)">{{data.extraData.lastQChange.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.yearChange">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.yearChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.yearChange.value)">{{data.extraData.yearChange.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.threeYearsChange">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.threeYearsChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.threeYearsChange.value)">{{data.extraData.threeYearsChange.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.fiveYearsChange">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.fiveYearsChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.fiveYearsChange.value)">{{data.extraData.fiveYearsChange.value}}%</div>
      </div>
    </div>

    <div class="extraData">

      <h3>{{data.labels.netRevenue}}</h3>
      <div class="extraDataLine" v-if="data.extraData.lastQChange">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.lastQChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.lastQChangeNet.value)">{{data.extraData.lastQChangeNet.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.yearChangeNet">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.yearChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.yearChangeNet.value)">{{data.extraData.yearChangeNet.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.threeYearsChangeNet">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.threeYearsChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.threeYearsChangeNet.value)">{{data.extraData.threeYearsChangeNet.value}}%</div>
      </div>

      <div class="extraDataLine" v-if="data.extraData.fiveYearsChangeNet">
        <div class="bullet">&bull;</div>
        <div class="label">{{data.extraData.fiveYearsChange.label}}</div>
        <div class="value" :style="setValueColor(data.extraData.fiveYearsChangeNet.value)">{{data.extraData.fiveYearsChangeNet.value}}%</div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import PerformanceService from "../../services/performance";
import Barchart from "./BarChart";
import SmallLoader from "../ui/SmallLoader";

export default {
  name: "RevenueChart",
  props: ['symbol'],
  components: {SmallLoader, Barchart},
  data() {
    return {
      data: null,
      displayType: 'eps',
      yAxisFormat: (value) => {
        if (isNaN(value)) return value;

        const absoluteValue = Math.abs(value);
        if (absoluteValue < 1000) {
          return value; // Return the number as is if it's less than 1000
        } else if (absoluteValue < 1000000) {
          return (value / 1000).toFixed(1) + 'K'; // Convert to K (thousands)
        } else if (absoluteValue < 1000000000) {
          return (value / 1000000).toFixed(1) + 'M'; // Convert to M (millions)
        } else {
          return (value / 1000000000).toFixed(1) + 'B'; // Convert to B (billions) or higher
        }
      }
    }
  },
  methods: {
    setValueColor(value) {
      if (value < 0) return {color: 'var(--red)'};
      if (value > 0) return {color: 'var(--green)'};
    }
  },
  computed: {
    toolTipFormatter() {
      return (params) => {
        return `
                <div style="color: black; unicode-bidi: plaintext; font-family: 'Assistant', sans-serif; min-width: 150px">
                <div style="text-align: center"><b>${params[0].name}</b></div>

                <table cellpadding="0" cellspacing="0">
                <tr>
                    <td style="width: 18px;"><div class="legendTooltip" style="background: ${params[0].color}"/></td>
                    <td>${this.data.labels.revenue}</td>
                    <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">${this.data.series.revenueSeries[params[0].dataIndex].formattedValue.toUpperCase()}</span></td>
                  </tr>
                </table>

                     <table cellpadding="0" cellspacing="0">
                      <tr>
                          <td style="width: 18px;"><div class="legendTooltip" style="background: ${params[1].color}"/></td>
                          <td>${this.data.labels.netRevenue}</td>
                    <td>&nbsp;<span style="unicode-bidi: plaintext; direction: ltr">${this.data.series.netRevenueSeries[params[0].dataIndex].formattedValue.toUpperCase()}</span></td>
                      </tr>
                  </table>
                </div>
                `;
      }
    },
    legend () {

      return [this.data.labels.revenue, this.data.labels.netRevenue];
    },
    displayData() {

      return [this.data.series.revenueSeries.map(point => point.value), this.data.series.netRevenueSeries.map(point => point.value)];
    }
  },
  async mounted() {
    this.data = await PerformanceService.getCompanyRevnue({symbol: this.symbol, query: {}})
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/performance";

.col {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  @media(max-width: 660px) {
    flex-direction: column;
    gap: 2rem;
  }
}

</style>
