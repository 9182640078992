
import axios from "axios";
import eventBus from "./events";

let config = {
    baseURL: process.env.VUE_APP_API_BASE_URL + '/v1',
    withCredentials: true, // Check cross-site Access-Control
};


const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {

        if (location.hostname.includes('pro.marketbit') || location.hostname.includes('devpro.marketbit')) config.headers.ENTITY = 'mbpprm';
        if (location.href.includes('localhost:80')) {
            config.headers.ENTITY = 'mbpprm';
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {

        return response.data;
    },
    function(error) {

        if (error.response.data.error) {

            if (error.response.data.error === 'WATCHLIST_LIMIT') {

                eventBus.emit('toastMessage', error.response.data.warning);
            }
        }
        return Promise.reject(error);
    }
);

window.axios = _axios;
