<template>
  <div class="priceChange" @scroll="scrolled">


    <table style="width: 100%;" cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <th class="stickySymbol"  :class="{xAxisScrolled}" :dir="uiDirection"><div> &nbsp;</div></th>
          <th class="bidi" style="cursor: pointer" v-for="(period, pIdx) in periodsNames"
              @click="sortList(period)"
              :class="{compareColumn: pIdx === compareColumn}" @mouseover="setDifferences(pIdx)" @mouseleave="compareColumn = null">
            <i class="ri-arrow-down-s-line" v-if="sortKey === period.value && sortDir === -1"/>
            <i class="ri-arrow-up-s-line" v-if="sortKey === period.value && sortDir === 1"/>
            {{period.label}}</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(symbol, sIdx) in list" @click="$emit('rowClick', symbol)">
        <td :class="{xAxisScrolled}" class="stickySymbol" :style="setStyle()" :dir="uiDirection">
          {{ symbol[displayKey || 'symbol'] }}
          <div v-if="currentlyLoading === symbol.symbol" style="padding: 0 10px;">
            <small-loader :inline="true"/>
          </div>
        </td>
        <td class="bidi"
             :class="{compareColumn: kIdx === compareColumn, bestPerformer: bestPerformance.performance[kIdx] === symbol.symbol, worsePerformer: bestPerformance.worsePerformance[kIdx] === symbol.symbol, noneComparedColumn: compareColumn !== null && compareColumn !== kIdx}"
             :dir="uiDirection"
             v-for="(key, kIdx) in priceKeys"
             @mouseover="setDifferences(kIdx)" @mouseleave="compareColumn = null">
          {{ symbol[key] }}
        </td>
      </tr>
      </tbody>
    </table>

<!--    <div class="priceChangeRow sticky" style="margin-bottom: 0; font-weight: 500;">-->
<!--      <div class="priceChangeCell symbol"  :style="setStyle()" :dir="uiDirection">&nbsp;</div>-->
<!--      <div class="priceChangeCell bidi header" style="cursor: pointer" v-for="(period, pIdx) in periodsNames"-->
<!--           @click="sortList(period)"-->
<!--           :class="{compareColumn: pIdx === compareColumn}" @mouseover="setDifferences(pIdx)" @mouseleave="compareColumn = null">-->

<!--        <i class="ri-arrow-down-s-line" v-if="sortKey === period.value && sortDir === -1"/>-->
<!--        <i class="ri-arrow-up-s-line" v-if="sortKey === period.value && sortDir === 1"/>-->
<!--        {{period.label}}-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="priceChangeRow" v-for="(symbol, sIdx) in list" @click="$emit('rowClick', symbol)">-->
<!--      <div class="priceChangeCell symbol" :style="setStyle()" :dir="uiDirection">-->
<!--        {{ symbol[displayKey || 'symbol'] }}-->
<!--      </div>-->
<!--      <div v-if="currentlyLoading === symbol.symbol" style="padding: 0 10px;">-->
<!--        <small-loader :inline="true"/>-->
<!--      </div>-->


<!--      <div class="priceChangeCell bidi"-->
<!--           :class="{compareColumn: kIdx === compareColumn, bestPerformer: bestPerformance.performance[kIdx] === symbol.symbol, worsePerformer: bestPerformance.worsePerformance[kIdx] === symbol.symbol, noneComparedColumn: compareColumn !== null && compareColumn !== kIdx}"-->
<!--           :dir="uiDirection"-->
<!--           v-for="(key, kIdx) in priceKeys"-->
<!--           @mouseover="setDifferences(kIdx)" @mouseleave="compareColumn = null">-->
<!--        {{ symbol[key] }}-->
<!--      </div>-->
<!--    </div>-->
<!--    -->

  </div>
</template>

<script>
import SmallLoader from "../../components/ui/SmallLoader";
export default {
  name: "PriceChange",
  components: {SmallLoader},
  props: ['priceChange', 'displayKey', 'symbolColWidth', 'currentlyLoading', 'clickableSymbol'],
  data() {
    return {
      xAxisScrolled: false,
      sortDir: -1,
      sortKey: '1D',
      compareColumn: null,
      bestPerformer: null,
      priceKeys: ['1D', '1M', '6M', 'ytd', '1Y', '3Y', '5Y'],
      periodsNames: ['1d', '1m', '6m', 'ytd', '1y', '3y', '5y'].map(period => ({
        label: this.dictionary.chart.periods[period],
        value: period !== 'ytd' ? period.toUpperCase() : 'ytd'
      })),
    }
  },
  computed: {
    list () {
      if (!this.sortKey) return this.priceChange;

      return this.priceChange.sort((a, b) => {

        let first = this.sortDir === 1 ? a : b;
        let second = this.sortDir === 1 ? b : a;

        return parseFloat(first[this.sortKey].replace('%','')) - parseFloat(second[this.sortKey].replace('%',''))

      });
    },
    bestPerformance() {

      let performance = {};
      let worsePerformance = {};

      for (let i = 0; i < this.priceKeys.length; i++) {
        let bestPerformance = null;
        let worsePerformanceP = null;
        this.priceChange.forEach(symbol => {

          const change = parseFloat(symbol[this.priceKeys[i]].replace('%', ''));
          if (change > bestPerformance || bestPerformance === null) {
            bestPerformance = change;
            performance[i] = symbol.symbol;
          }

          if (change < worsePerformanceP || worsePerformanceP === null) {
            worsePerformanceP = change;
            worsePerformance[i] = symbol.symbol;
          }
        });
      }

      return {performance, worsePerformance};
    }
  },
  methods: {
    scrolled(ev){
      const scrollLeftPosition = ev.target.scrollLeft;
      this.xAxisScrolled = Math.abs(scrollLeftPosition) > 10;
    },
    sortList (period) {

      if (period.value !== this.sortKey) {
        this.sortDir = -1;
        this.sortKey = period.value;
      } else {
        this.sortDir *= -1;
      }
    },
    setStyle () {

      let style = {}
      if (this.symbolColWidth) style.flex =  '1 0 ' + this.symbolColWidth
      if (this.clickableSymbol) style.cursor = 'pointer';

      return style
    },
    setDifferences(index) {

      this.compareColumn = index;
      let bestPerformance = 0;

      this.priceChange.forEach(symbol => {

        const change = parseFloat(symbol[this.priceKeys[index]].replace('%', ''));
        if (change > bestPerformance) {
          bestPerformance = change;
          this.bestPerformer = symbol.symbol;
        }
      });
    }
  }

}
</script>

<style scoped lang="scss">

.priceChange {
  overflow-x: auto;
    white-space: nowrap;
  @media(max-width: 660px) {
    overflow-x: scroll;
  }
}

.priceChangeRow {
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:not(:first-of-type) {
    &:hover {
      background: var(--tableHoverColor);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}



.priceChangeCell {
  width: calc((100% - 40px) / 9);
  padding: 10px;

  &.bidi {
    display: flex;
    justify-content: center;
    unicode-bidi: plaintext;
    direction: ltr;
    box-sizing: border-box;

    &:not(.header) {
    &:hover {
      box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
      z-index: 3;
      outline: 1px solid var(--notficationBorderColor) !important;
    }
    }
  }

  &.symbol {
    flex: 0 0 120px !important;
    font-weight: 500;

    @media(max-width: 660px) {
      position: sticky;
      background: var(--bgColor);
      left: 0;
    }

    &[dir="rtl"] {
      left: unset;
      right: 0;
      text-align: right;
    }
  }

  &.compareColumn {
    background: var(--tableHoverColor);
    font-weight: 600 !important;
  }

  &.noneComparedColumn {
    opacity: .75;
  }

  &.bestPerformer {
    color: var(--green);
    background: var(--opacityGreen);
    font-weight: 600;
  }

  &.worsePerformer {
    color: var(--red);
    font-weight: 600;
    background: var(--opacityRed);
  }

  @media (max-width: 660px) {

    flex: 0 0 100px !important;
  }
}

.priceChangeRow {
  border-bottom: 1px solid var(--notficationBorderColor);
  border-radius: 0;
}

.sticky {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: var(--bgColor);
  border-bottom: 2px solid var(--notficationBorderColor);
  z-index: 4;
  border-radius: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

thead {
  position: sticky;
  top: 0;
  background: var(--bgColor);
}

th,td{
  padding: 10px;
  border-bottom: 2px solid var(--notficationBorderColor);
  &.compareColumn {
    background: var(--tableHoverColor);
    font-weight: 600 !important;
  }
}

td {
  border-bottom: 1px solid var(--notficationBorderColor);
  transition: all .15s ease-in;
  &.bestPerformer {
    color: var(--green);
    background: var(--opacityGreen);
    font-weight: 600;
  }

  &.worsePerformer {
    color: var(--red);
    font-weight: 600;
    background: var(--opacityRed);
  }
  &:not(&:first-of-type):hover {
    z-index: 4;
  }
}

.xAxisScrolled {
  box-shadow: 3px 0 2px 0 rgba(0, 0, 0, 0.05);
  border-right: 1px solid var(--notficationBorderColor);
  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--notficationBorderColor);
    box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.05);
  }
}

.stickySymbol {
  background: var(--bgColor);
  position: sticky;
  left: 0;
  border-right: 1px solid var(--tableBorderColor);
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 9;
  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--tableBorderColor);
    left: unset;
    right: 0;
  }
}

tbody {
  tr{
    transition: all .15s ease-in;

    &:hover {
      background: var(--tableHoverColor);

      .stickySymbol {

        background: var(--tableHoverColor);
      }
    }
  }
}

</style>
