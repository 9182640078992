<template>
  <div class="toast" ref="toast">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: ['message'],
  methods: {
    show(cb) {

      this.$refs.toast.classList.add('show');
      setTimeout(() => {

        this.$refs.toast.classList.remove('show');

        if (cb) cb();
      }, 3500);
    }
  }
}
</script>

<style scoped lang="scss">

.toast {
  position: fixed;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  border-radius: 8px;
  background: var(--dailyBriefBgColor);
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  transition: all .35s cubic-bezier(0.140, 0.785, 0.415, 0.935);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  z-index: 99999999999999999;
  opacity: 0;

  &.show {
    opacity: 1 !important;
    transform: translate(-50%, -5rem) scale(1.2) !important;
  }
}
</style>
