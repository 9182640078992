<template>
  <BigLoader v-if="isLoading"/>
  <main id="dailyBrief" :dir="uiDirection" :class="{p: isProUI}" v-if="dailyBrief && !isLoading">

    <div id="rightPane" :class="{p: isProUI}" :dir="uiDirection">
      <div id="header" :class="{p: isProUI}">
        <h2>
          <span v-if="!isProUI">{{ dictionary.dailyBrief.title }}</span>

          <span class="briefDate" :class="{p: isProUI}">{{ briefDate }}
           <div class="marketOpenIndicator" v-if="!nyTime.isPreMarket && !nyTime.isPostMarket"
                :class="{open: nyTime.isMarketOpen, closed: !nyTime.isMarketOpen}">
        <div class="indicator"/>
             {{ nyTime.isMarketOpen ? dictionary.tags.marketOpen : dictionary.tags.marketClose }} <span>(<i
               class="ri-time-line"/> {{ nyTime.time }})</span>
      </div>
         <div class="marketOpenIndicator" v-if="nyTime.isPreMarket || nyTime.isPostMarket"
              :class="{preMarket: nyTime.isPreMarket, postMarket: nyTime.isPostMarket}">
           <i v-if="nyTime.isPreMarket" class="ri-sun-line"/>
           <i v-if="nyTime.isPostMarket" class="ri-moon-line"/>
             {{ nyTime.isPreMarket ? dictionary.tags.preMarket : dictionary.tags.postMarket }} <span>(<i
             class="ri-time-line"/> {{ nyTime.time }})</span>
      </div>
        </span>
        </h2>
      </div>

      <p class="expl alert" v-if="userWatchlistList.length === 0">
        {{ dictionary.messages.shortInstructions }}

        <router-link to="/watchlist" class="portfolioLink" @click="openSearchPanel(); clickEvent('watchlist')"><i
            class="ri-folder-line"/>
          {{ dictionary.buttons.moveToPortfolio }}
        </router-link>
      </p>

      <p class="expl" v-if="!isProUI">{{ dictionary.dailyBrief.explanation }}</p>


      <section>
        <div v-if="isProUI" id="topSection">

          <div id="chart">
            <tabs
                :tabs="[{value: 'SPY', label: 'S&P500 (SPY)'}, {value: 'DIA', label: 'Dow Jones (DIA)'}, {value: 'QQQ', label: 'NASDAQ 100 (QQQ)'}, {value: 'IWM', label: 'Russell 2000 (IWM)'}]"
                :active-tab="chartIndexSymbol" :round="true" :scroll="true" :no-border="true" :no-margin="true"
                @tabClick="chartIndexSymbol = $event; chartKey++; $refs.chart.refresh();"></tabs>
            <symbol-big-price-chart ref="chart" :symbol="chartIndexSymbol"
                                    :show-legend="false" :period="'1m'"
                                    :key="chartKey"
                                    :chart-height="460"
                                    :defaultChart="'line'"
                                    :chart-options="['periods', 'toolbar']">
            </symbol-big-price-chart>

            <price-change :priceChange="dailyBrief.indexChange"/>
          </div>

          <div id="daily">
            <section>
              <h3>
                {{
                  isWeekend ? dictionary.dailyBrief.weeklyEventsTitle : dictionary.dailyBrief.selectedEvents.dailyEventsTitle
                }}

              </h3>
              <div class="tabs">
                <div v-for="(economicEventTab, indexIdx) in economicTabs" class="tab"
                     :class="{active: selectedEconomicIndex === economicEventTab.key}"
                     @click="selectedEconomicIndex = economicEventTab.key">{{ economicEventTab[systemLanguage] }}
                </div>
              </div>
              <div class="row header" :dir="uiDirection">
                <div class="expanded">

                  {{ dictionary.dailyBrief.selectedEvents.name }}
                </div>
                <div>
                  {{
                    isWeekend ? dictionary.dailyBrief.selectedEvents.date : dictionary.dailyBrief.selectedEvents.time
                  }}
                </div>
                <!--                <div>{{ dictionary.dailyBrief.selectedEvents.prev }}</div>-->
                <!--                <div>{{ dictionary.dailyBrief.selectedEvents.est }}</div>-->
              </div>
              <div
                  v-if="!dailyBrief.economic || !dailyBrief.economic[selectedEconomicIndex] || dailyBrief.economic[selectedEconomicIndex].length === 0"
                  class="noEvents">
                <b>{{ dictionary.dailyBrief.selectedEvents.noEvents }}</b>
              </div>
              <div v-for="(economic, economicIdx) in dailyBrief.economic[selectedEconomicIndex]" class="row"
                   :key="selectedEconomicIndex" :dir="uiDirection">
                <div class="expanded"><i v-if="economic.impact === 'High'" class="ri-flag-2-fill"
                                         style="color: var(--lightYellow)"/> {{ economic.name }}
                </div>
                <div>{{ formatDateTime(economic.datetime, isWeekend ? 'DDD HH:mm' : 'HH:mm') }}</div>
                <!--                <div>{{ economic.prev }}</div>-->
                <!--                <div :style="setEconomicStyle(economic)">{{ economic.estimate }}</div>-->
              </div>
            </section>

            <section>
              <h3>
                {{
                  isWeekend ? dictionary.dailyBrief.selectedEarnings.weeklyEarningsTitle : dictionary.dailyBrief.selectedEarnings.dailyEarningsTitle
                }}
              </h3>
              <div class="row header" :dir="uiDirection">
                <div>{{ dictionary.dailyBrief.selectedEarnings.symbol }}</div>
                <div>{{ dictionary.dailyBrief.selectedEarnings.reportTime }}</div>
                <!--                <div>EPS</div>-->
              </div>
              <div v-for="(earning, earningIdx) in dailyBrief.earnings" class="row" :dir="uiDirection">
                <div class="linkable">
                  <router-link :to="`/symbol/${earning.symbol}`"/>
                  {{ earning.symbol }}
                </div>
                <div :class="{preMarket: earning.releaseTime === 'bmo', afterMarket: earning.releaseTime === 'amc'}">
                  <i class="ri-sun-line" v-if="earning.releaseTime === 'bmo'"/>
                  <i class="ri-moon-line" v-else/>
                  {{ dictionary.calendars.labels[earning.releaseTime === 'bmo' ? 'preMarket' : 'afterMarket'] }}
                </div>
                <!--                <div>{{ earning.epsEstimate }}</div>-->
              </div>

              <router-link class="seeAllLink"
                           :to="{name: 'Calendars', query: {calendar: 'earnings', period: isWeekend ? 'thisWeek' : 'today'}}">
                {{ dictionary.dailyBrief.selectedEarnings.seeAllEarnings }}
              </router-link>
            </section>

          </div>

        </div>

        <div v-if="!isProUI">
          <h3>{{ dictionary.dailyBrief.indices.title }}</h3>

          <div class="tabs">
            <div v-for="(index, indexIdx) in indices" class="tab" :class="{active: indexIdx === selectedIndex}"
                 @click="selectedIndex = indexIdx">{{ index.labels[systemLanguage] }}
            </div>
          </div>


          <div v-for="(symbol, sIdx) in indices[selectedIndex].symbols" class="row symbolRow" :dir="uiDirection">
            <div class="symbol">{{ symbol.name }}</div>
            <div class="price">
              <symbol-label :alwaysOn="true" :priceOnly="true" :symbol="symbol" :prices="prices"/>
            </div>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; gap: 10px; justify-content: center; " v-if="false">

          <div v-for="(index, indxIdx) in indices" style="width: calc(33% - 10px)">
            <h4 style="text-align: center">{{ index.labels[systemLanguage] }}</h4>
            <div v-for="(symbol, sIdx) in index.symbols" class="row symbolRow" :dir="uiDirection">
              <div class="symbol" style="font-size: 14px;">{{ symbol.name }}</div>
              <div class="price">
                <symbol-label :alwaysOn="true" :small="true" :priceOnly="true" :symbol="symbol" :prices="prices"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="!isProUI">
        <h3>
          {{
            isWeekend ? dictionary.dailyBrief.weeklyEventsTitle : dictionary.dailyBrief.selectedEvents.dailyEventsTitle
          }}

        </h3>
        <div class="tabs">
          <div v-for="(economicEventTab, indexIdx) in economicTabs" class="tab"
               :class="{active: selectedEconomicIndex === economicEventTab.key}"
               @click="selectedEconomicIndex = economicEventTab.key">{{ economicEventTab[systemLanguage] }}
          </div>
        </div>
        <div class="row header" :dir="uiDirection">
          <div class="expanded">

            {{ dictionary.dailyBrief.selectedEvents.name }}
          </div>
          <div>
            {{ isWeekend ? dictionary.dailyBrief.selectedEvents.date : dictionary.dailyBrief.selectedEvents.time }}
          </div>
          <div>{{ dictionary.dailyBrief.selectedEvents.prev }}</div>
          <!--          <div>{{ dictionary.dailyBrief.selectedEvents.est }}</div>-->
        </div>
        <div
            v-if="!dailyBrief.economic || !dailyBrief.economic[selectedEconomicIndex] || dailyBrief.economic[selectedEconomicIndex].length === 0"
            class="noEvents">
          <b>{{ dictionary.dailyBrief.selectedEvents.noEvents }}</b>
        </div>
        <div v-for="(economic, economicIdx) in dailyBrief.economic[selectedEconomicIndex]" class="row"
             :key="selectedEconomicIndex" :dir="uiDirection">
          <div class="expanded"><i v-if="economic.impact === 'High'" class="ri-flag-2-fill"
                                   style="color: var(--lightYellow)"/> {{ economic.name }}
          </div>
          <div>{{ formatDateTime(economic.datetime, isWeekend ? 'DDD HH:mm' : 'HH:mm') }}</div>
          <div>{{ economic.prev }}</div>
          <!--          <div :style="setEconomicStyle(economic)">{{ economic.estimate }}</div>-->
        </div>
      </section>

      <section v-if="!isProUI">
        <h3>
          {{
            isWeekend ? dictionary.dailyBrief.selectedEarnings.weeklyEarningsTitle : dictionary.dailyBrief.selectedEarnings.dailyEarningsTitle
          }}
        </h3>
        <div class="row header" :dir="uiDirection">
          <div>{{ dictionary.dailyBrief.selectedEarnings.symbol }}</div>
          <div>{{ dictionary.dailyBrief.selectedEarnings.reportTime }}</div>
          <!--          <div>EPS</div>-->
        </div>
        <div v-for="(earning, earningIdx) in dailyBrief.earnings" class="row" :dir="uiDirection">
          <div class="linkable">
            <router-link :to="`/symbol/${earning.symbol}`"/>
            {{ earning.symbol }}
          </div>
          <div :class="{preMarket: earning.releaseTime === 'bmo', afterMarket: earning.releaseTime === 'amc'}">
            <i class="ri-sun-line" v-if="earning.releaseTime === 'bmo'"/>
            <i class="ri-moon-line" v-else/>
            {{ dictionary.calendars.labels[earning.releaseTime === 'bmo' ? 'preMarket' : 'afterMarket'] }}
          </div>
          <!--          <div>{{ earning.epsEstimate }}</div>-->
        </div>

        <router-link class="seeAllLink"
                     :to="{name: 'Calendars', query: {calendar: 'earnings', period: isWeekend ? 'thisWeek' : 'today'}}">
          {{ dictionary.dailyBrief.selectedEarnings.seeAllEarnings }}
        </router-link>
      </section>
    </div>

    <div id="sidePane" :class="{p: isProUI}" :dir="uiDirection">
      <div v-if="!isProUI"
           style="height: 50%; overflow-y: scroll; overflow-x: hidden; border-bottom: 5px solid var(--notficationBorderColor)"
           @scroll="handleNewsScroll" ref="scrollNewsContainer">
        <h3 class="feedTitle" v-if="!isProUI" :style="isProUI ? {marginBottom: 0} : {}">
          {{ !isProUI ? dictionary.feeds.macroFeed : dictionary.navBar.socialBuzz }}
        </h3>

        <!--        <SocialTrend v-if="isProUI" :is-mini="true"/>-->
        <Feed key="newsFeed"
              v-if="!isProUI"
              ref="newsFeed"
              type="news"
              :prices="prices.prices"
              @feedLoaded="feedLoaded"/>
      </div>
      <div class="halfContainer" :class="{p: isProUI}" ref="topContainer">

        <TopTen v-if="!isProUI" :isMini="isProUI" :prices="prices.prices" @scrollTop="scrollTopTop()"
                @topLoaded="topLoaded"/>

        <tabs
            v-if="isProUI"
            style="padding: 0px 1rem;"
            :tabs="[{value: 'news', label: dictionary.navBar.macroFeed}, {value: 'indices', label: dictionary.dailyBrief.indices.title}]"
            :active-tab="proContentTab" :round="true" :scroll="true" :no-border="true"
            @tabClick="proContentTab = $event;"></tabs>

        <div id="scrollabelProContent">
          <IndicesPartial v-if="isProUI && proContentTab === 'indices'" :isPartial="true" :prices="prices"/>
          <Feed key="newsFeed"
                ref="newsFeed"
                type="news"
                v-if="isProUI && proContentTab === 'news'"
                :prices="prices.prices"
                @feedLoaded="feedLoaded"/>
        </div>


      </div>

    </div>

  </main>
</template>

<script>
import DailyBrief from '../services/dailyBrief';
import SymbolLabel from "../components/symbols/symbol";
import Notification from "../components/feed/notification/Notification";
import {DateTime} from "luxon";
import BigLoader from "../components/ui/BigLoader";
import {useNewYorkTime} from "../stores/newYorkTime";
import Feed from "./FeedView";
import TopTen from "./TopTen";
import eventBus from "../services/events";
import SymbolBigPriceChart from "../components/charts/SymbolBigPriceChart";
import LineChart from "../components/charts/LineChart";
import PriceChange from "./symbolView/PriceChange";
import Tabs from "../components/ui/Tabs";
import SocialTrend from "./SocialTrend";
import {getCurrentInstance, onMounted} from "vue";
import IndicesPartial from "./dailyBrief/IndicesPartial";


export default {
  name: "DailyBriefView",
  components: {
    IndicesPartial,
    SocialTrend,
    Tabs, PriceChange, LineChart, SymbolBigPriceChart, TopTen, Feed, BigLoader, Notification, SymbolLabel
  },
  inheritAttrs: false,
  emits: ['joinFilteredFeed', 'feedLoaded', 'toggleNotifications'],
  props: ['prices', 'notificationsEnabled', 'notificationsAvailable', 'userWatchlistList'],
  data() {
    return {
      proContentTab: 'news',
      chartIndexSymbol: 'SPY',
      selectedIndex: 0,
      chartKey: 0,
      selectedEconomicIndex: 'us',
      economicTabs: [{
        he: 'ארה״ב',
        en: 'USA',
        key: 'us'
      }, {
        he: 'גרמניה',
        en: 'Germany',
        key: 'de'
      }, {
        he: 'סין',
        en: 'China',
        key: 'cn'
      }],
      indices: [
        {
          labels: {
            he: 'ארה״ב',
            en: 'US market'
          },
          symbols: [
            {name: 'S&P 500', symbol: 'GSPC', price: null, changeP: null},
            {name: 'NASDAQ 100', symbol: 'NDX', price: null, changeP: null},
            {name: 'Dow Jones', symbol: 'DJI', price: null, changeP: null},
            // {name: 'Russell 2000', symbol: 'RUT', price: null, changeP: null},
            {name: 'VIX', symbol: 'VIX', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Europe markets', he: 'אירופה'},
          symbols: [
            {name: 'FTSE 100', symbol: 'FTSE', price: null, changeP: null},
            {name: 'DAX', symbol: 'GDAXI', price: null, changeP: null},
            {name: 'CAC 40', symbol: 'FCHI', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Commodities', he: 'סחורות'},
          symbols: [
            {name: 'Crude Oil', symbol: 'CLUSD', price: null, changeP: null},
            {name: 'Brent', symbol: 'BZUSD', price: null, changeP: null},
            {name: 'Gold', symbol: 'GCUSD', price: null, changeP: null},
            {name: 'Silver', symbol: 'SIUSD', price: null, changeP: null},
            {name: 'Platinum', symbol: 'PLUSD', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Asian markets', he: 'אסיה'},
          symbols: [
            {
              name: 'KOSPI Composite',
              symbol: 'KS11',
              price: null,
              changeP: null
            },
            {name: 'HANG SENG', symbol: 'HSI', price: null, changeP: null},
            {name: 'Nikkei 225', symbol: 'N225', price: null, changeP: null},
            {name: 'NIFTY 50', symbol: 'NSEI', price: null, changeP: null}
          ]
        },
        {
          labels: {en: 'Crypto', he: 'קריפטו'},
          symbols: [
            {
              name: 'Bitcoin USD',
              symbol: 'BTCUSD',
              price: null,
              changeP: null
            },
            {
              name: 'Ethereum USD',
              symbol: 'ETHUSD',
              price: null,
              changeP: null
            },
            {name: 'XRP USD', symbol: 'XRPUSD', price: null, changeP: null},
            {
              name: 'Tether USD',
              symbol: 'USDTUSD',
              price: null,
              changeP: null
            }
          ]
        },
      ]
      ,
      symbols: [
        {name: 'S&P 500', symbol: 'GSPC', price: null, changeP: null},
        {name: 'NASDAQ 100', symbol: 'NDX', price: null, changeP: null},
        {name: 'Dow Jones', symbol: 'DJI', price: null, changeP: null},
        {name: 'Russell 2000', symbol: 'RUT', price: null, changeP: null},
        {name: 'VIX', symbol: 'VIX', price: null, changeP: null},
        {name: 'FTSE 100', symbol: 'FTSE', price: null, changeP: null},
        {name: 'DAX', symbol: 'GDAXI', price: null, changeP: null},
        {name: 'CAC 40', symbol: 'FCHI', price: null, changeP: null},
        {
          name: 'KOSPI Composite',
          symbol: 'KS11',
          price: null,
          changeP: null
        },
        {name: 'HANG SENG', symbol: 'HSI', price: null, changeP: null},
        {name: 'Nikkei 225', symbol: 'N225', price: null, changeP: null},
        {name: 'NIFTY 50', symbol: 'NSEI', price: null, changeP: null},
        {
          name: 'Bitcoin USD',
          symbol: 'BTCUSD',
          price: null,
          changeP: null
        },
        {
          name: 'Ethereum USD',
          symbol: 'ETHUSD',
          price: null,
          changeP: null
        },
        {name: 'XRP USD', symbol: 'XRPUSD', price: null, changeP: null},
        {
          name: 'Tether USD',
          symbol: 'USDTUSD',
          price: null,
          changeP: null
        },
        {name: 'Crude Oil', symbol: 'CLUSD', price: null, changeP: null},
        {name: 'Brent', symbol: 'BZUSD', price: null, changeP: null},
        {name: 'Gold', symbol: 'GCUSD', price: null, changeP: null},
        {name: 'Silver', symbol: 'SIUSD', price: null, changeP: null},
        {name: 'Platinum', symbol: 'PLUSD', price: null, changeP: null}
      ],
      chartInterval: null,
      refreshInterval: null,
      isLoading: true,
      dailyBrief: null,
      isWeekend: [0, 6].includes(new Date().getDay())
    }
  },
  setup() {
    const nyTime = useNewYorkTime();
    const app = getCurrentInstance();

    onMounted(() => {

      app.ctx.chartInterval = setInterval(() => {
        if (nyTime.isMarketOpen) {

          if (app.refs.chart) {

            app.refs.chart.refresh();
          } else {
            setTimeout(() => {

              app.refs.chart.refresh();
            }, 5000);
          }
        }
      }, 60000);
    });

    return {nyTime}
  },
  computed: {
    briefDate() {

      const weekStart = DateTime.now().setLocale(this.dateFormatLocale).startOf('week').toFormat('dd MMM');
      const weekEnd = DateTime.now().setLocale(this.dateFormatLocale).endOf('week').toFormat('dd MMM');
      const weekly = `${weekStart} - ${weekEnd}`;
      return this.isWeekend ? weekly : DateTime.now().setLocale(this.dateFormatLocale).toFormat('DDDD')
    }
  },
  methods: {
    feedLoaded(symbols) {

      // this.symbolsInView = symbols;
      // this.socket.joinSocketRooms(this.symbolsInView)
    },
    handleNewsScroll() {
      const container = this.$refs.scrollNewsContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        this.$refs.newsFeed.loadNext();
      }
    },
    formatDateTime(datetime, format = null) {

      return DateTime.fromISO(datetime).setLocale(this.dateFormatLocale).toFormat(format || 'DDD HH:mm')
    },
    openSearchPanel() {

      eventBus.emit('openSearchPanel');
    },
    setEconomicStyle(economic) {

      if (economic.prev === null || economic.estimate === null) return {}
      if (parseFloat(economic.prev) > parseFloat(economic.estimate)) return {color: 'red'}
      if (parseFloat(economic.prev) < parseFloat(economic.estimate)) return {color: 'green'}
    },
    async getBrief() {

      this.dailyBrief = await DailyBrief.getDailyBrief();
      this.isLoading = false;
    },
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.chartInterval);
  },
  async beforeMount() {
    await this.getBrief();
    this.refreshInterval = setInterval(() => {
      this.getBrief();
    }, 1000 * 60 * 3)
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/vars";
@import "../assets/style/dailyBrief";

.halfContainer {
  height: 50%;
  background: var(--bgColor);
  overflow-y: hidden;
  overflow-x: hidden;

  &.p {
    height: 100%;
  }
}

#dailyBrief {
  padding: $smallGap;
  background: #111;
  //background: var(--dailyBriefBgColor);
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: calc(100% - 20px);

  &.p {
    height: calc(100vh - 60px);
    @media(max-width: 660px) {
      overflow: unset;
      height: unset;
    }
  }

  @media(max-width: 660px) {
    overflow: unset;
    height: unset;
  }


  section {
    background: var(--cardBgColor);
    padding: 10px;
    margin-top: $smallGap * 3;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .15);
  }


  h2 {
    margin: 0;
    padding: 0;
    color: white;
  }
}

#dailyBrief[dir="ltr"] {
  padding: 10px 0 10px 10px;
}

h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--highlightColor);
}

.expl {
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
}

.expl.alert {
  color: white;
  font-size: 18px;
}


.notificationRow {
  border-bottom: 1px solid var(--notficationBorderColor);
}


.afterMarket {
  color: var(--afterMarketColor);
}

.seeAllLink {
  color: black;
  margin-top: 10px;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  display: block;
  background: var(--linkBtnBgColor);
  width: fit-content;
}

.linkable {
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}


.briefDate {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1rem;
  margin: 5px 0 15px;

  &.p {
    margin: 15px 0 -15px;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 660px) {
    padding: 5px 10px 0;
  }

  &.p {
    @media(max-width: 660px) {
      padding: 0;
      justify-content: center;
    }
  }
}

.marketOpenIndicator {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 5px;
  width: fit-content;
  border-radius: 8px;
  font-weight: 600;

  .indicator {
    border-radius: 50px;
    width: 18px;
    height: 18px;
  }
}

.marketOpenIndicator.closed {
  color: var(--red);
  background: var(--opacityRed);

  .indicator {
    background: var(--red);
  }
}

.marketOpenIndicator.open {
  color: var(--green);
  background: var(--opacityGreen);

  .indicator {
    background: var(--green);
  }
}

.marketOpenIndicator.preMarket {
  background: var(--lightYellowOpacity);
  color: var(--lightYellow);
}

.marketOpenIndicator.postMarket {
  background: var(--afterMarketColorOpacity);
  color: var(--afterMarketColor);
}

.portfolioLink {
  background: var(--highlightColor);
  color: white;
  display: block;
  width: fit-content;
  text-align: center;
  margin: 25px auto;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

.noEvents {
  padding: 5px;
}

#rightPane {
  width: 50%;
  overflow: auto;
  height: calc(100vh - 77px);
  margin: -15px;
  padding: 5px 15px 15px 15px;

  &.p {
    width: 72%;
    height: calc(100% - 5px);
    @media(max-width: 1460px) {
      width: 60%;
    }
    @media(max-width: 660px) {
      //padding: 5px 15px 15px 5px;
      width: 100%;
    }
  }

  @media (max-width: 660px) {

    width: 100%;
    overflow: unset;
    height: unset;
    margin: unset;
    padding: unset;
  }
}

#rightPane[dir="ltr"] {
  margin: -15px 0px -15px -10px;
}

#sidePane {

  overflow: hidden;
  height: calc(100vh - 32px);
  width: 50%;
  margin: -10.5px 15px 0 -10px;
  background: var(--feedBgColor);

  &.p {
    width: 28%;
    height: calc(100vh);
    @media(max-width: 1460px) {
      width: 40%;
    }
  }

  @media(max-width: 660px) {
    display: none;
  }
}

#sidePane[dir="ltr"] {

  margin: -10.5px 0 0 0;
}

#topSection {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  @media(max-width: 1450px) {
    display: block;
  }

  #chart {
    width: 60%;
    @media(max-width: 1450px) {
      width: 100%;
    }
  }

  #daily {
    width: 40%;
    @media(max-width: 1450px) {
      width: 100%;
    }
  }
}

#scrollabelProContent {
  overflow-y: auto;
  height: 92vh;
}

</style>
