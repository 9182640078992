<template>
  <section>

    <div style="padding: 0 1rem">
      <chart-note :note="dictionary.insiders.note"/>
    </div>

    <OverlayUI v-if="selectedInsider">
      <div id="insidersCard" ref="insidersCard">
        <i class="ri-close-line close" @click="selectedInsider = null"/>

        <h3>{{ selectedInsider.insidersName }}</h3>
        <small>{{ selectedInsider.role }}</small>


        <div class="info">
          <div class="block">
            <div class="title">{{ dictionary.insiders.ownershipWorth }}</div>
            {{ selectedInsider.ownershipWorth }}
          </div>
          <div class="block">
            <div class="title">{{ dictionary.insiders.ownershipRatio }}</div>
            {{ selectedInsider.ownershipRatio }}
          </div>
        </div>

        <div class="info" style="margin-top: 1rem;">
          <div class="block">
            <div class="title">{{ dictionary.insiders.totalBuyTransactions }}</div>
            {{ selectedInsider.numOfBuy }}
          </div>
          <div class="block">
            <div class="title">{{ dictionary.insiders.totalSellTransactions }}</div>
            {{ selectedInsider.numOfSell }}
          </div>
        </div>

      </div>
    </OverlayUI>


    <div class="table">
      <div class="headersRow">
        <div class="row">
          <div class="cell">{{ dictionary.insiders.owner }}</div>
          <div class="cell center">{{ dictionary.insiders.role }}</div>
          <div class="cell center">{{ dictionary.insiders.date }}</div>
          <div class="cell center">{{ dictionary.insiders.transaction }}</div>
          <div class="cell center">{{ dictionary.insiders.cost }}</div>
          <div class="cell center">{{ dictionary.insiders.shares }}</div>
          <div class="cell center">{{ dictionary.insiders.value }}</div>
          <div class="cell center">{{ dictionary.insiders.ownedShares }}</div>
          <div class="cell center">{{ dictionary.insiders.filingDate }}</div>
        </div>
      </div>


      <div v-for="(insider, iIndex) in insiders" class="mrow">
        <div class="topLine">
          <div class="owner" @click="showInsider(insider.cik)">
            <div class="name">
<!--              <i class="ri-information-line"/>-->
              {{ insider.name }}
            </div>
          </div>
          <div class="date">
            {{ formatDate(insider.date) }}
            <br/>

          </div>
          <div class="transactionType" :style="transactionType(insider.transactionDirection, insider.transaction)">
            {{ dictionary.insiders.transactions[insider.transaction] }}
          </div>
        </div>

        <div class="role">{{ insider.role }}

          <small>
            {{ dictionary.insiders.reportedAtDate }}<br/>
            {{ formatDate(insider.filingDate) }}
          </small>
        </div>

        <div class="info">
          <div class="block">
            <div class="title">{{ dictionary.insiders.cost }}</div>
            {{ insider.price }}
          </div>
          <div class="block">
            <div class="title">{{ dictionary.insiders.shares }}</div>
            {{ insider.securitiesTransacted }}
          </div>
          <div class="block">
            <div class="title">{{ dictionary.insiders.value }}</div>
            {{ insider.totalPrice }}
          </div>
          <div class="block">
            <div class="title">{{ dictionary.insiders.ownedShares }}</div>
            {{ insider.securitiesOwned }}
          </div>
        </div>
      </div>

      <div v-for="(insider, iIndex) in insiders">
        <div class="row transaction">
          <div class="cell" @click="showInsider(insider.cik, $event.target)"
               style="cursor: pointer; text-overflow: ellipsis; overflow: hidden;white-space: nowrap;">
            <i class="ri-information-line"/>
            {{ insider.name }}
          </div>
          <div class="cell center" style="text-overflow: ellipsis; overflow: hidden;white-space: nowrap;">
            {{ insider.role }}
          </div>
          <div class="cell center">{{ formatDate(insider.date) }}</div>
          <div class="cell center" :style="transactionType(insider.transactionDirection, insider.transaction)">
            {{ dictionary.insiders.transactions[insider.transaction] }}
          </div>
          <div class="cell center">{{ insider.price }}</div>
          <div class="cell center">{{ insider.securitiesTransacted }}</div>
          <div class="cell center">{{ insider.totalPrice }}</div>
          <div class="cell center">
            {{ insider.securitiesOwned }}
          </div>
          <div class="cell center">
            <a :href="insider.link" target="_blank" class="link">
              {{ formatDate(insider.filingDate) }}
            </a>

          </div>
        </div>
        <div class="row transaction toggleRow" :ref="`insider-${iIndex}`">
          <div class="info three">
            <div class="block">
              <div class="title">{{ dictionary.insiders.ownershipWorth }}</div>
              {{ insider.ownershipWorth }}
            </div>
            <div class="block">
              <div class="title">{{ dictionary.insiders.ownershipRatio }}</div>
              {{ insider.ownershipRatio }}
            </div>

            <div class="block">
              <div class="title">{{ dictionary.insiders.link }}</div>
              <a :href="insider.link" target="_blank">
                <i class="ri-link"/> {{ dictionary.insiders.clickToWatchLink }}
              </a>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>
</template>


<script>

import InsidersService from '../../services/insiders';
import {DateTime} from "luxon";
import OverlayUI from "../../components/ui/Overlay";
import ChartNote from "../../components/charts/ChartNote";

export default {
  name: "InsidersPartial",
  components: {ChartNote, OverlayUI},
  props: ['symbol'],
  data() {
    return {
      selectedInsider: null,
      isLoading: false,
      insiders: [],
      insidersSummary: null
    }
  },
  methods: {
    showInsider(cik, target) {

      return;

      this.selectedInsider = this.insidersSummary[cik];

      this.$nextTick(() => {
        if (window.innerWidth > 660 && target) {


          const clickedPosition = target.getBoundingClientRect();
          if (window.innerHeight >= this.$refs.insidersCard.clientHeight + clickedPosition.y + 40) {
            this.$refs.insidersCard.style.top = clickedPosition.y + 40 + 'px';
          } else {

            this.$refs.insidersCard.style.top = clickedPosition.y - this.$refs.insidersCard.clientHeight + 'px';
          }
        }
      })
    },
    toggleRow(index) {
      if (!this.insiders[index].extend) {
        this.insiders[index].extend = true;

        this.$refs[`insider-${index}`][0].style.height = `${this.$refs[`insider-${index}`][0].scrollHeight + 30}px`
      } else {
        this.insiders[index].extend = false;
        this.$refs[`insider-${index}`][0].style.height = `0px`
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).setLocale(this.dateFormatLocale).toFormat('DD')

    },
    transactionType(type, transactionType) {
      if (transactionType === 'option') {

        return {background: 'var(--opacityPurple)', color: 'var(--purple)'}
      } else {

        if (type === 'sell') {

          return {background: 'var(--opacityRed)', color: 'var(--red)'}
        } else {

          return {background: 'var(--opacityGreen)', color: 'var(--green)'}
        }
      }
    },
    async getInsiders() {

      let {insiders, insidersSummary} = await InsidersService.getInsidersBySymbol(this.symbol, {});
      this.insidersSummary = insidersSummary;
      this.insiders = insiders.map(insider => {

        insider.extend = false;
        return insider;
      })
    }
  },
  beforeMount() {
    this.getInsiders();
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";
@import "../../assets/style/tables";

.headersRow {
  top: 40px;
  box-shadow: 0 5px 15px 0 rgba(15, 15, 15, .05);
  font-size: 14px;
}

.transaction {
  font-size: 14px;

  .cell {
    padding: 10px 5px;
  }
}

.row {
  gap: 5px;
}

.cell {
  padding: 0;
  width: calc(100% / 8);

  &:nth-of-type(1) {
    width: 17%;
  }

  &:nth-of-type(2) {
    width: 17%;
  }

  &:nth-of-type(4) {
    width: 11%;
  }

  &:nth-of-type(5) {
    width: 9%;
  }

  &:nth-of-type(8) {
    width: 11%;
  }

}

section {
  @media (max-width: 660px) {
    white-space: nowrap;
    //overflow-x: scroll;

    .row {
      display: none;
    }

    .table {
      flex-wrap: nowrap;
      position: relative;
      overscroll-behavior: contain;
      width: 100%;

      .row {
        flex-wrap: nowrap;
        width: 100%;
        //.cell {
        //  width: calc(100% / 8) !important;
        //}
      }
    }
  }
}

.mrow {
  @media (min-width: 660px) {
    display: none;
  }
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: .5rem;


  .topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .name {
    font-weight: 500;
  }

  .owner {
    width: 45%;
  }

  .role {
    font-weight: 500;
    margin-top: -4px;
    margin-bottom: 10px;
  }

  .date, .transactionType {
    width: 25%;
    font-weight: 500;
  }

  .transactionType {
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 14px;
  }
}

.info {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 5px;

  .block {
    .title {
      font-weight: 400;
      font-size: 15px;
    }

    font-weight: 500;
    font-size: 18px;
  }
}

.info.three {
  grid-template-columns: 33% 33% 34%;
  width: 450px;
  margin: 1rem 0;
  column-gap: $minSpacing;
}

.toggleRow {
  overflow: hidden;
  height: 0;
  transition: $toggleTransition;
  padding: 0 1rem;
  background: var(--tableHoverColor);
  border-bottom: 1px solid var(--notficationBorderColor) 1px solid rgba(255, 255, 255, .5);
}

#insidersCard {
  position: absolute;
  width: 320px;
  background: var(--bgColor);
  border-radius: 8px;
  //border-top-right-radius: 8px;
  z-index: 999999999;
  padding: 1rem;

  h3 {
    color: var(--textColor);
    margin: .5rem 0 0;
  }

  small {
    margin-bottom: .5rem;
    display: block;
  }

  .figure {
    opacity: 0.9;
    display: inline-block;
    font-size: 14px;
  }
}

.close {
  padding: .5rem;
  margin: -1rem -.5rem -1rem;
  cursor: pointer;
  transition: $toggleTransition;
  border-radius: 50%;

  &:hover {
    color: var(--highlightColor);
    background: var(--highlightColorOpacity);
  }
}

.link {
  display: flex;
  align-items: center;
  color: var(--highlightColor);
  gap: 2px;
}
</style>
