<template>
  <big-loader v-if="isLoading"></big-loader>
  <main v-else>
    <h3 :class="{feedTitle: isMini}">{{dictionary.topTen.title}}</h3>
    <div class="tabs">
      <div class="tab" :class="{active: tab === 'nasdaq'}" @click="tab = 'nasdaq'; $emit('scrollTop')">{{dictionary.topTen.nasdaq}}</div>
      <div class="tab" :class="{active: tab === 'sp'}" @click="tab = 'sp'; $emit('scrollTop')">{{dictionary.topTen.sp}}</div>
      <div class="tab" :class="{active: tab === 'market'}" @click="tab = 'market'; $emit('scrollTop')">{{dictionary.topTen.market}}</div>
    </div>

    <div id="topTable">
    <section>
      <h3>{{dictionary.topTen.gainers}}</h3>
      <div v-for="(symbol, sIdx) in list.gainers" :key="`g-${tab}-${symbol.symbol}`" class="symbolRow">
        <symbol-label :symbol="symbol" :small="true" :prices="prices"/>
      </div>
    </section>

    <section>
      <h3>{{dictionary.topTen.losers}}</h3>
      <div v-for="(symbol, sIdx) in list.losers" :key="`l-${tab}-${symbol.symbol}`" class="symbolRow">
        <symbol-label :symbol="symbol" :small="true" :prices="prices"/>
      </div>
    </section>

    <section>
      <h3>{{dictionary.topTen.active}}</h3>
      <div v-for="(symbol, sIdx) in list.active" :key="`a-${tab}-${symbol.symbol}`" class="symbolRow">
        <symbol-label :symbol="symbol" :small="true" :prices="prices"/>
      </div>
    </section>
    </div>
  </main>
</template>

<script>

import TopTen from '../services/topTen';
import SymbolLabel from "../components/symbols/symbol";
import BigLoader from "../components/ui/BigLoader";

export default {
  name: "TopTen",
  components: {BigLoader, SymbolLabel},
  props: ['prices', 'isMini'],
  data () {
    return {
      tab: 'nasdaq',
      topTen: null,
      isLoading: true,
      loadingInterval: null
    }
  },
  computed: {
    list () {
      if (!this.topTen) return [];
      return this.topTen[this.tab]
    }
  },
  methods: {
    async loadTop () {

      this.topTen = await TopTen.getTopTen();
      let symbols = new Set();

      for (let k in this.topTen) {
        for (let j in this.topTen[k]) {

          this.topTen[k][j].forEach(symbol => {
            symbols.add(symbol.symbol)
          })
        }
      }

      this.$emit('topLoaded', Array.from(symbols));
      this.isLoading = false;
    }
  },
  beforeUnmount() {
    clearInterval(this.loadingInterval);
  },
  beforeMount() {
    this.loadTop().then().catch();
    this.loadingInterval = setInterval(() => {

      this.loadTop().then().catch()
    }, 1000 * 60 );
  }
}
</script>

<style scoped lang="scss">

  @import "../assets/style/vars";
  @import "../assets/style/calendar";
  @import "../assets/style/general";

.tab {
  width: 33%;

}

  .symbolRow {
    border-bottom: 1px solid var(--notficationBorderColor);
    padding: $smallGap;
    &:last-of-type {
      border-bottom: none;
    }
  }

  h2 {
    margin: 0 $smallGap $smallGap;
    padding: 0;
  }

  h3 {
    margin: $smallGap;
    margin-bottom: 0;
    color: var(--highlightColor);
    padding: 0;
  }

  #topTable {
    display: flex; align-items: flex-start; justify-content: space-between;
    section {
      width: 33%;
    }
  }

</style>
