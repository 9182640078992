<template>
  <div>
    <div class="row calendarHeadersRow">
      <div class="cell"> {{ dictionary.calendars.headers.symbol }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.ratio }}</div>
    </div>
    <section v-for="(day, dayIndex) in calendar">
      <div class="calendarDateRow">
        {{ day.format }}
      </div>
      <div v-for="(event, eventIdx) in day.events" :key="event._id" class="row">
        <div class="cell">{{event.symbol}}</div>
        <div class="cell center">{{event.ratio}}</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SplitsCalendar",
  props: ['calendar'],
  data() {
    return {}
  },
  computed: {

  },
  methods: {}
}
</script>

<style scoped lang="scss">

@import "../../assets/style/calendar";

.preMarket {
  //color: var(--highlightColor);
}

.afterMarket {
  color: var(--afterMarketColor);
}

.cell {
  width: 33%;
}
</style>
