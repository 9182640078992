<template>
  <main id="watchlistPage">

    <p class="expl" id="expl">
      {{ dictionary.messages.watchlist }}
    </p>
    <!--    <symbols-search :as-page="true"/>-->

    <section id="emptyPortfolio">
      <EmptyPortfolio v-if="!isLoading && watchlist.length === 0"/>
    </section>


    <div class="searchBar" id="searchBar" v-if="!isLoading && watchlist.length > 0">

      <div id="firstWrapper">
      <div id="searchWrapper" :dir="uiDirection">
        <i class="ri-search-2-line"/>
        <input type="search"
               ref="searchInput"
               :placeholder="dictionary.symbolSearch.inputLabel" v-model="searchQuery"/>

      </div>

      <toggle-switch :onoff="true" :state="allowNotifications"
                     :text="dictionary.settingsPage.notificationLabels.emailNotifications"
                     @stateChanged="allowNotificationsChange"/>
      </div>


      <div class="watchlistSize" :class="{listFull: watchlist.length === (isProUI ? 200 : 50)}"
           v-if="isProUI && !isLoading && watchlist.length > 0">
        {{ dictionary.watchlist.numberOfSymbols }}: {{ watchlist.length }}/{{ isProUI ? 200 : 50 }}
      </div>
    </div>

    <DataGridTable :data="watchlistSymbols"
                   v-if="!isLoading && watchlist.length > 0"
                   :loaded="isLoading"
                   :height-elements="['searchBar', 'expl']"
                   :default-sort="'symbol'"
                   :default-sort-direction="1"
                   :fixed-col="0">

      <template #thead="{thClick, sortKey, sortDirection}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sort-key="sortKey"
                  :center="thIndex > 4"
                  :sort-direction="sortDirection"
                  :sort-value="th.sortValue"
                  v-show="th.showIf === undefined || th.showIf()"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`wl-${rowIndex}`">
          <td>
            <div class="symbolTd">
              <router-link :to="`/symbol/${row.symbol}`"/>
              <SymbolLogo :symbol="row.symbol"/>
              {{ row.symbol }}
            </div>
          </td>
          <td class="" style="padding: 0 !important;">
            <div class="settings">

              <div :dir="uiDirection" class="addRemoveSymbol remove fixedSixty"
                   @click="removeSymbol(row, symbolIdx)">
                <span>{{ dictionary.buttons.removeFromPortfolioShort }}</span>
                <i class="ri-folder-reduce-line"/>
              </div>
              <!--              -->
              <!--              <div class="remove symbolOption" @click="removeSymbol(row, symbolIdx)">-->
              <!--                <i class="ri-folder-reduce-line"/>-->
              <!--              </div>-->
              <div class="symbolOption toggle" v-if="isProUI" :class="{optionOn: row.emailPush}"
                   @click="updateSymbolOption(row, 'emailPush')">
                <i class="ri-mail-line"/>
              </div>
              <div class="symbolOption toggle" v-if="isProUI" :class="{optionOn: row.soundNotification}"
                   @click="updateSymbolOption(row, 'soundNotification')">
                <i class="ri-volume-up-line"/>
              </div>
            </div>
          </td>
          <td v-show="hasEvents()">
            <div class="notificationTags" v-if="row.events.length > 0">
              <div class="eventRow">
                <div v-for="(event, eIndx) in row.events" class="tag event">
                  <Popper
                      arrow
                      :hover="true"
                      :content="`${event.label}${extraEventData(event)}`"
                  >
                    <i class="ri-calendar-line"/>{{ event.type.substr(0, 1) }}
                  </Popper>
                </div>
              </div>
            </div>
          </td>
          <td>
            <SymbolLabel :price-only="true" :key="`price-${row.symbol}`" :symbol="row"
                         :preMarketQuote="row.tradingData?.preMarket"
                         :postMarketQuote="row.tradingData?.postMarket" :prices="{price: row.price}"/>
            <PrePostQuote :symbol="row.symbol"
                          :quotes="{preMarket: row.tradingData?.preMarket, postMarket: row.tradingData?.postMarket}"/>
          </td>
          <td class="">
            <span style="display: inline-block; width: 60px">{{ row.tradingData.formattedVolume }}</span> <span
              v-if="row.tradingData.isAboveAvgVolume" class="unusualVolume">{{
              dictionary.symbolPage.unusualVolume
            }}</span>
          </td>
          <td>
            {{ row.tradingData.formattedMarketCap }}
          </td>
          <td class="bidi trendTd" :class="{red: row.eods?.['5D'] < 0, green: row.eods?.['5D'] > 0}">
            {{ row.eods?.['5D'] + '%' || '-' }}
          </td>
          <td class="bidi trendTd" :class="{red: row.eods?.['1M'] < 0, green: row.eods?.['1M'] > 0}">
            {{ row.eods?.['1M'] + '%' || '-' }}
          </td>
          <td class="bidi trendTd" :class="{red: row.eods?.['6M'] < 0, green: row.eods?.['6M'] > 0}">
            {{ row.eods?.['6M'] + '%' || '-' }}
          </td>
          <td class="bidi trendTd" :class="{red: row.eods?.['1Y'] < 0, green: row.eods?.['1Y'] > 0}">
            {{ row.eods?.['1Y'] + '%' || '-' }}
          </td>

        </tr>
      </template>
    </DataGridTable>

  </main>
</template>

<script>

import {useWatchlist} from "../stores/watchlist";
import {getCurrentInstance, ref} from "vue";
import UserService from '../services/user';
import SymbolLabel from "../components/symbols/symbol";
import eventBus from "../services/events";
import SymbolView from "./SymbolView";
import SymbolsSearch from "../components/ui/SymbolsSearch";
import ToggleSwitch from "../components/ui/ToggleSwitch";
import SymbolLogo from "../components/symbols/symbolLogo";
import EmptyPortfolio from "../components/ui/EmptyPortfolio";
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";
import Popper from "vue3-popper";
import PrePostQuote from "../components/symbols/PrePostQuote";

export default {
  name: "WatchlistView",
  components: {
    PrePostQuote,
    HeadCell,
    DataGridTable,
    EmptyPortfolio,
    SymbolLogo,
    ToggleSwitch,
    SymbolsSearch,
    SymbolView,
    SymbolLabel,
    Popper
  },
  setup() {

    const app = getCurrentInstance();
    const watchlistStore = useWatchlist();
    const wl = ref([]);

    watchlistStore.$subscribe((mutation, state) => {

      app.ctx.loadWatchlist();
      if (app.data.isInitialLoad) {
        app.data.isInitialLoad = false;
      } else {
      }
    });
  },
  data() {
    return {
      notificationsAllowed: false,
      watchlist: [],
      isLoading: true,
      searchQuery: null,
      isInitialLoad: true,
      userSettings: null,
      headers: [{
        label: this.dictionary.symbolPage.symbol,
        sortValue: 'symbol',
        width: 120
      },
        {
          label: '',
          width: 100,
        },
        // {
        //   label: this.dictionary.symbolPage.name,
        // },
        {
          label: '', showIf: this.hasEvents
        }, {
          label: this.dictionary.symbolPage.price,
          sortValue: 'changeP'
        }, {
          label: this.dictionary.symbolPage.volume,
          sortValue: 'tradingData.volume'
        },
        {
          label: this.dictionary.symbolPage.marketCap,
          sortValue: 'tradingData.marketCap'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['5d'],
          sortValue: 'eods.5D'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1m'],
          sortValue: 'eods.1M'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['6m'],
          sortValue: 'eods.6M'
        }, {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1y'],
          sortValue: 'eods.1Y'
        },
      ]
    }
  },
  computed: {
    allowNotifications() {

      return this.notificationsAllowed;
    },
    watchlistSymbols() {

      return !this.searchQuery || (this.searchQuery.toString().trim().length === 0) ? this.watchlist : this.watchlist.filter(symbol => {

        if (symbol.symbol.toLowerCase().includes(this.searchQuery.toLowerCase())) return true;
        if (symbol.name.toLowerCase().includes(this.searchQuery.toLowerCase())) return true;
      });
    }
  },
  methods: {
    async loadUserSettings() {

      this.userSettings = await UserService.getUserSettings();
      this.notificationsAllowed = this.userSettings.allowNotifications;

    },
    async allowNotificationsChange() {

      this.notificationsAllowed = !this.notificationsAllowed;
      await UserService.updateNotificationsSettings({
        allowNotifications: this.notificationsAllowed
      });
    },
    hasEvents() {

      return this.watchlist.some(symbol => symbol.events.length > 0)
    },
    allOn(option) {

      if (!this.isInitialLoad) return false;
      return this.watchlist.every(symbol => symbol[option] === true);
    },
    async updateSymbolOptionForAll(option) {

      const isAllOn = this.allOn(option);

      await UserService.updateSymbolOptionForAll(option, !isAllOn);
      this.watchlist = this.watchlist.map(symbol => {

        symbol[option] = !isAllOn;
        return symbol;
      });
    },
    searchSymbolClick() {

      eventBus.emit('searchSymbolClick')
    },
    async loadWatchlist() {

      this.isLoading = true;
      let watchlist = await UserService.getUsersWatchlist();
      watchlist = watchlist.map(symbol => {

        symbol.isLoading = false;
        return symbol;
      });

      this.watchlist = watchlist;
      this.isLoading = false;
    },
    async updateSymbolOption(symbol, option) {

      symbol[option] = !symbol[option];
      await UserService.updateSymbolOption(symbol.symbol, option, symbol[option]);
    },
    extraEventData(event) {
      if (event.ratio) return ` (${event.ratio})`
      if (event.epsEstimate) return ` (EPS est. $${event.epsEstimate})`
      if (event.dividendRate) return ` (Div. $${event.dividendRate})`
    },
    async removeSymbol(symbol, index) {

      try {

        if (!symbol.isLoading) {
          symbol.isLoading = true;
          await UserService.removeSymbolFromWatchlist(symbol.symbol)
          useWatchlist().removeSymbol(symbol.symbol);
          symbol.isLoading = false;
        }
      } catch (e) {

      }
    }
  },
  async mounted() {
    await Promise.all([this.loadUserSettings(), this.loadWatchlist()]);
    // await this.loadUserSettings();
    // await this.loadWatchlist();
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/general";

.settings {
  padding: 0 !important;
  display: flex;
  align-items: center;
  width: fit-content;
}

.symbolRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--notficationBorderColor);

  &:hover {
    background: var(--tableHoverColor);
  }

  &.header {
    &:hover {
      background: var(--bgColor);
    }
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .symbolName {
    font-weight: 600;
    font-size: 18px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .companyName {
    padding: 0 1rem;
    unicode-bidi: plaintext;
  }


  .companyName[dir="rtl"] {
    text-align: right;
  }

  .remove {
    padding: 1rem;
    cursor: pointer;
  }
}

.symbolContainer {
  display: flex;
  align-items: center;
  gap: 0;
}

.notificationTags {
  padding: 5px;
  background: black;
  width: fit-content;
  color: white;
  border-radius: 5px;
  margin: 0;
  margin-top: 0;
}

#emptyPortfolio {
  max-width: 720px;
  margin: 0 auto;
  @media (max-width: 660px) {
    width: 90%;
  }
}

#searchSymbol {
  cursor: pointer;
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  border-radius: 8px;
  color: var(--highlightColor);
  text-align: center;
  width: fit-content;
  font-weight: 500;
  margin: 5px auto;
}

.expl {
  background: var(--highlightColorOpacity);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
}

.watchlistSize {
  padding: $smallGap;
  font-weight: 600;
  text-align: center;

  &.listFull {
    color: var(--red);
  }
}


.symbolOption {
  padding: 1rem;
  cursor: pointer;
  text-align: center;

  i {
    display: block;
  }

  label {
    font-size: 12px;
  }

  &.toggle {
    color: var(--gray);
    background: var(--bgColor);
    transition: $toggleTransition;
    opacity: .5;
  }

  &.optionOn {
    opacity: 1;
    color: var(--lightYellow);
    background: var(--lightYellowOpacity);
  }
}

.settings {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#searchWrapper {
  position: relative;
  z-index: 3;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    width: 360px;
    transition: all .1s ease-in;

    &.autoWidth {
      width: auto;
    }

    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}


#searchWrapper[dir="rtl"] {
  i {
    right: 10px;
  }

  input {
    padding-right: 30px;
  }
}


#searchWrapper[dir="ltr"] {
  i {
    left: 10px !important;
  }

  input {
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.searchBar {
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--tableHoverColor);
  border-bottom: 1px solid var(--notficationBorderColor);
  width: 100%;
  position: relative;
  padding: 0 5px;

  #firstWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 660px) {
      flex-direction: column;
      gap: 5px;
      padding: 10px 0;
    }
  }
}

.addRemoveSymbol {
  flex: 0 0 55px !important;
  width: 55px;
  margin-left: auto;

  height: 49px;
  justify-content: center;
  align-items: center;

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }

  i {
    display: none;
  }

  @media(max-width: 660px) {

    flex: 0 0 49px !important;
    width: 49px;
    height: 49px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    i {
      display: block;
    }

    span {
      display: none;
    }
  }
}

</style>
