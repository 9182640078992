<template>
  <div id="content">
      <h1>
        {{ title[systemLanguage] }}
      </h1>
      <h2>
        {{ shouldKnow[systemLanguage] }}
      </h2>

      <ul>
        <li :dir="uiDirection" v-for="(item) of items" v-html="item[systemLanguage]"/>
      </ul>
    <MBPButton style="margin-top: 2rem" @click="$emit('close')" class="action large" :label="dictionary.tags.close"/>
  </div>
</template>

<script>
import MBPButton from "../ui/controls/MBPButton";

export default {
  name: "FirstLoginInstructions",
  components: {MBPButton},
  data() {
    return {
      title: {
        he: 'ברוכים הבאים ל-Marketbit.pro!',
        en: 'Welcome to Marketbit.pro!'
      },
      shouldKnow: {
        he: 'כדאי לדעת:',
        en: 'You should know:'
      },
      items: [{
        en: '<span class="emphasis">Email notifications:</span> Go to "My watchlist" screen and turn notifications ON. Then, turn notifications on/off for specific symbols by clicking the envelope icon.',
        he: `<span class="emphasis">התראות אי-מייל:</span> בעמוד ״רשימת המעקב״, הפעילו את אפשרות קבלת ההתראות. לאחר מכן, הדליקו וכבו התראות עבור מניה ספציפית על ידי לחיצה על סימול המעטפה `
      }, {
        en: '<span class="emphasis">System language:</span> You can set system language by going to the "Settings -> Account" page under "Language settings". Currently available languages: English & Hebrew.',
        he: '<span class="emphasis">הגדרות שפה:</span> ניתן להגדיר שפה בעמוד ״הגדרות״ תחת ״הגדרות שפה״. כרגע ניתן לבחור באנגלית ועברית. '
      }, {
        en: '<span class="emphasis">System color:</span> You can set the system to dark or light mode in the "Settings" page.',
        he: '<span class="emphasis">צבעי מערכת:</span> ניתן לבחור במצב כהה או בהיר תחת עמוד ה״הגדרות״.'
      }, {
        en: '<span class="emphasis">Push notifications:</span> The system is real-time. When your news feed gets new information your browser will present a notification, even if your on a different tab or window. <b>Make sure your browser is not blocking Marketbit.pro</b>.',
        he: '<span class="emphasis">התראות פוש:</span> המערכת מתעדכנת בזמן אמת. כשפיד החדשות שלך מתעדכן הדפדפן מציג התראה, גם אם אתם נמצאים בטאב אחר או חלון אחר. <b>יש לוודא שהדפדפן שלכם אינו חוסם את האתר</b>'
      }, {
        en: '<span class="emphasis">Mobile devices:</span> The platform is optimized for mobile browser use.',
        he: '<span class="emphasis">מכשירים ניידים:</span> המערכת מותאמת לשימוש בדפדפנים במכשירים ניידים',
      }, {
        en: '<span class="emphasis">What now?:</span> Create your watchlist, browse around and start trading like a pro. Make sure to use the "Contact us" form to give us a shout.',
        he: '<span class="emphasis">מה עכשיו?:</span> צרו את רשימת המעקב שלכם, עברו בין העמודים השונים ותתחילו לסחור ולהשקיע כמו מקצוענים. בכל עניין או שאלה דברו איתנו דרך טופס יצירת הקשר.'
      }]
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 38px;
}

h2 {
  margin-top: .5rem;
  font-size: 20px;
}

ul {
  max-width: 620px;
}

li {
  margin-bottom: 1rem;
  font-size: 18px;

  &[dir="rtl"] {
    direction: rtl;
    unicode-bidi: plaintext;
  }
}

#content {
  overflow-y: auto;
  height: 100%;
}

:deep {
  .emphasis {
    color: var(--lightYellow);
    font-weight: bold;
    display: block;
  }
}
</style>
