<template>
  <div>
    <MBPButton :label="dictionary?.settingsPage?.buttons.subscribe || subscribeLabel[user?.systemLanguage || 'en']" @click="openPayment()"
               :class="{mbp: !isSettings, large: largeCTA}" class="action mbp bold"/>
  </div>
</template>

<script>
import MBPButton from "../../components/ui/controls/MBPButton";
import UserService from "../../services/user";

export default {
  name: "PaymentButton",
  components: {MBPButton},
  props: ['user', 'isSettings', 'largeCTA', 'priceId', 'subscriptionType'],
  data () {
    return {
      subscribeLabel: {
        en: 'Subscribe',
        he: 'הרשמה'
      }
    }
  },
  mounted() {
    if (!document.getElementById('paddleJS')) {
      this.setPaddleScript();
    } else {
      try {
      this.setPaddlePayment();
      } catch (e) {

      }
    }
  },
  methods: {
    setPaddleScript() {
      const pURL = 'https://cdn.paddle.com/paddle/v2/paddle.js';
      const script = document.createElement('script');
      script.id = 'paddleJS';

      script.src = pURL;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        this.setPaddlePayment()
      }
    },
    async paymentSuccess(data) {

      try {
        await UserService.createSubscription(data.data.transaction_id, data.data.custom_data.subscription_type);
        location.reload();
      } catch (e) {
      }
    },
    openPayment() {

      Paddle.Checkout.open({
        customer: {
          email: this.user.email,
        },
        customData: {
          subscriptionType: this.subscriptionType || 'monthly'
        },
        settings: {
          theme: "light",
          allowLogout: false,
          showAddDiscounts: false
        },
        items: [
          {
            priceId: this.priceId,
            quantity: 1
          }
        ]
      });
    },
    setPaddlePayment() {

      if (Paddle === undefined) {

        this.setPaddleScript();
      } else {

        if (!window.location.href.includes("marketbit.pro")) Paddle.Environment.set("sandbox");
        Paddle.Setup({
          eventCallback: (data) => {
            if (data.name == "checkout.completed") {
              this.paymentSuccess(data);
            }
          },
          token: process.env.VUE_APP_PADDLE_TOKEN
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
