<template>

  <div class="buttonsGroup" :dir="uiDirection">
    <div class="button" v-for="(button, bIdx) in buttons" :key="`btn-${button.value}`"
         @click="$emit('selected', button)" :class="{active: active === button.value}">{{ button.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonsGroup",
  props: ['active', 'buttons']
}
</script>

<style scoped lang="scss">


.buttonsGroup {
  border-radius: 8px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--notficationBorderColor);

  .button {
    padding: 5px 8px;
    cursor: pointer;
    border-right: 1px solid var(--notficationBorderColor);
    transition: all .2s ease-in;
    font-weight: 500;

    &[dir="rtl"] {
      border-right: none;
      border-left: 1px solid var(--notficationBorderColor);
    }

    &:last-of-type {
      &[dir="rtl"] {

        border-left: none;
      }

      border-right: none;
    }

    &:hover {
      background: var(--tableHoverColor);
    }

    &.active {
      background: var(--highlightColorOpacity);
      color: var(--highlightColor);
      box-shadow: inset 1px 1px 8px 1px rgba(0, 0, 0, 0.35);
    }

    &.active:hover {
      //background: var(--highlightColorOpacityDark);
    }

  }
}
</style>
