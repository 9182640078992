import {defineStore} from 'pinia'

export const useEquityPrices = defineStore('equityPrices', {
    state: () => {
        return {
            prices: {
            },
            preMarketPrices: {
            },
            postMarketPrices: {
            }
        };
    },
    getters: {
        getPreMarketPriceBySymbol(symbol) {
            return this.preMarketPrices[symbol]
        },
        getPostMarketPriceBySymbol(symbol) {
            return this.postMarketPrices[symbol]
        },
      getPriceBySymbol(symbol) {
          return this.prices[symbol]
      }
    },
    actions: {
        updatePrice(key, symbolPrice) {

            this[key][symbolPrice.symbol] = symbolPrice;
        }
    },
})
