<template>
<label>
  <span class="outerLabel" v-if="label">{{label}}</span>
  <DropDown
      :full-width="fullWidth"
      :autocomplete="autocomplete"
      :options="industries"
      :alternative-options="industries"
      :multi="multi"
      :clear="clear"
      ref="filter"
      @clear="$emit('clear', filterKey)"
      @select="$emit('select', {filter: filterKey, data: $event})"
    />
</label>
</template>

<script>
import DropDown from "../ui/DropDown";
import DropDownAutoComplete from "../ui/DropdownAutoComplete";
export default {
  name: "IndustryFilter",
  components: {DropDownAutoComplete, DropDown},
  props: ['autocomplete', 'multi', 'clear', 'fullWidth', 'industries', 'label', 'multi', 'filterKey'],
  methods: {
    clearFilter() {
      this.$refs.filter.clearSelection(false);
    }
  }

}
</script>

<style scoped>

</style>
