<template>
  <section>
    <div id="termsAlert">

      <div class="content">
        <p v-html="terms"/>
      </div>

      <input type="button" :value="dictionary.buttons.approveTerms" id="accept" @click="acceptTerms()"/>

    </div>
  </section>
</template>

<script>

import UserService from '../../services/user';

export default {
  name: "Terms",
  props: ['terms'],
  data() {
    return {
      isSending: false
    }
  },
  methods: {
    async acceptTerms() {

      this.isSending = true;
      await UserService.acceptTerms();
      this.isSending = false;
      this.$emit('accepted');
    }
  }
}
</script>

<style scoped lang="scss">

section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: var(--highlightColorOpacity);
}

.content {
  @media (max-width: 660px) {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: .25rem;
  }
}

#termsAlert {
  box-sizing: border-box;
  background: var(--bgColor);
  border-radius: 8px;
  padding: 2rem;
  width: 620px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .25);

  @media(max-width: 660px) {
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}


#accept {
  background: var(--highlightColor);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}
</style>
