<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "StackChart",
  props: ['series', 'labels', 'toolTipFormatter'],
  data() {
    return {
      options: {}
    }
  },
  methods: {},
  beforeUnmount() {
    echarts.dispose(this.$refs.chart);
  },
  mounted() {

    const colors = ['#2079f2', 'rgb(227, 158, 0)', '#9400FF', '#0ca3ab', 'rgba(201, 1, 31, 1)']

    if (this.$refs.chart && this.series) {

      let chart = echarts.init(this.$refs.chart);

      this.options = {
        legend: {
          textStyle: {
            color: this.systemTheme === 'darkMode' ? '#ccc' : '#333'
          },
        },
        label: {
          show: false
        },
        grid: {
          top: '10%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.toolTipFormatter,
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: this.systemTheme === 'darkMode' ? '#333' : '#eee'
            }
          },
          axisLabel: {
            formatter: (value) => {
              if (isNaN(value)) return value;

              const absoluteValue = Math.abs(value);
              if (absoluteValue < 1000) {
                return value; // Return the number as is if it's less than 1000
              } else if (absoluteValue < 1000000) {
                return (value / 1000).toFixed(1) + 'K'; // Convert to K (thousands)
              } else if (absoluteValue < 1000000000) {
                return (value / 1000000).toFixed(1) + 'M'; // Convert to M (millions)
              } else {
                return (value / 1000000000).toFixed(1) + 'B'; // Convert to B (billions) or higher
              }
            },
            align: 'right'
          }
        },
        xAxis: {
          type: 'category',
          data: this.labels,
          axisTick: {
            show: false
          },
        },
        calculable: true,
        dataZoom: [
          {
            show: true,
            start: 40,
            end: 100
          },
          {
            type: 'inside',
            start: 40,
            end: 100
          },
        ],
        series: this.series.map((series, index) => {

          let seriesObject = {
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            name: series.name,
            data: series.data,
            label : {
              show : false
            },
            labelLine : {
              show : false
            },
            itemStyle : {

            }
          }

          if (colors[index]) seriesObject.color = colors[index];

          return seriesObject
        })
      };

      new ResizeObserver(() => {
        chart.resize();
      }).observe(this.$refs.chart);

      chart.setOption(this.options);

    }
  }
}

</script>

<style scoped>
.chart {
  height: 360px;
}
</style>
