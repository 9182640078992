<template>
  <section>
    <div id="termsAlert">
      <i class="ri-close-line close" :dir="uiDirection" @click="closeModal()"/>
      <h2>{{dictionary.contactUs.title}}</h2>
      <div class="content">
      <p>
        {{dictionary.contactUs.text}}
      </p>

      <form @submit.prevent="submitForm()">
        <label for="name">
          {{ dictionary.contactUs.name }}
          <input type="text" id="name" v-model="name"/>
        </label>

        <label for="email">
          <span>
          {{dictionary.contactUs.email}}
          <small class="fieldError" v-if="emailError">{{dictionary.errors.invalidEmail}}</small>
          </span>
          <input type="text" id="email" v-model="email"/>
        </label>

        <label for="message">
          <span>
          {{dictionary.contactUs.message}}
          <small class="fieldError" v-if="messageError">{{dictionary.errors.noContentMessage}}</small>
          </span>
          <textarea id="message" v-model="message"/>
        </label>

        <small>{{dictionary.contactUs.privacy}}</small>


        <div class="buttonWithLoader">
          <input type="submit" :value="dictionary.buttons.send" id="accept" :disabled="isSending"/>
          <small-loader v-if="isSending" :inline="true"/>
        </div>
      </form>

      </div>

    </div>
  </section>
</template>

<script>

import UserService from '../../services/user';
import SmallLoader from "./SmallLoader";
import mixpanel from 'mixpanel-browser';

export default {
  name: "ContactUs",
  components: {SmallLoader},
  data() {
    return {
      isSending: false,
      email: null,
      name: null,
      message: null,
      emailError: false,
      messageError: false
    }
  },
  methods: {
    closeModal() {

      try {
        mixpanel.track('user:contactUsNotSent', {client: window.location.host})
      } catch (e) {

      }

      this.email = null;
      this.name = null;
      this.message = null;
      this.emailError = false;
      this.messageError = false;
      this.validForm = true;
      this.$emit('close');
    },
    validateEmail(email) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    hasAValue(value) {

      return value && value.toString().trim().length > 0;
    },
    async submitForm() {

      this.validForm = true;
      this.emailError = false;
      this.messageError = false;

      if (!this.hasAValue(this.email) || (this.hasAValue(this.email) && !this.validateEmail(this.email))) {
        this.validForm = false;
        this.emailError = true;
      }

      if (!this.hasAValue(this.message)) {
        this.validForm = false;
        this.messageError = true;
      }


      if (!this.validForm) return;

      this.isSending = true;
      await UserService.sendContactUs({
        name: this.name || 'N\\A',
        email: this.email,
        message: this.message
      });
      this.isSending = false;
      this.$emit('sent');
    }
  }
}
</script>

<style scoped lang="scss">

section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: var(--highlightColorOpacityDark);
  backdrop-filter: blur(9px);
}

#termsAlert {
  box-sizing: border-box;
  background: var(--bgColor);
  border-radius: 8px;
  padding: 2rem;
  width: 620px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .25);

  h2 {
    margin: 0;
    @media(max-width: 660px) {
      padding-bottom: .5rem;
      margin-bottom: 1rem;
    }
  }

  .content {
    @media(max-width: 660px) {

    border-top: 1px solid var(--notficationBorderColor);
    overflow-x: hidden;
    overflow-y: auto;
    }
  }

  @media(max-width: 660px) {
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

form {
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1rem;
    font-size: 18px;

    span {
      display: flex;
      align-items: center;
      gap: 5px;

      small {
        margin-top: 5px;
        display: block;
      }
    }

    input, textarea {
      resize: none;
      box-sizing: border-box;
      padding: 5px 10px;
      border-radius: 50px;
      border: 2px solid var(--notficationBorderColor);
      width: 100%;
      font-size: 16px;
      transition: all .1s ease-in;

      @media(max-width: 660px) {
        margin-right: auto;
      }

      &:focus, &:active {
        border: 2px solid var(--highlightColor);
        background: var(--highlightColorOpacity);
      }
    }

    textarea {
      height: 150px;
      border-radius: 8px;
      font-family: 'Assistant';
    }
  }
}

.fieldError {
  margin: 2px 0 0 0;
  color: red;

}


#accept {
  background: var(--highlightColor);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

small {
  display: block;
  margin-bottom: 1rem;
  font-size: 15px;
}

.close {
  font-size: 22px;
  position: absolute;
  left: 2rem;
  cursor: pointer;
  background: var(--highlightColorOpacity);
  color: var(--highlightColor);
  border-radius: 50%;
  padding: 10px;
  margin: -10px;
  &[dir="ltr"] {
    left: unset;
    right: 2rem;
  }
}

.buttonWithLoader {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
