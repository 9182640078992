<template>
  <div>
    <div class="searchButton" v-if="isMobile()" @click="expanded = true">
      <i class="ri-search-2-line"/>
    </div>

    <transition name="fade"  mode="out-in">
    <div class="searchWrapper" v-if="!isMobile() || (isMobile() && expanded)" :dir="uiDirection">

      <div class="cols">
      <div class="expandable" :class="{expanded}">
        <i class="ri-search-2-line"/>
        <input type="search"
               ref="searchInput"
               :class="{expanded}"
               @blur="wrapperClick"
               :placeholder="dictionary.symbolSearch.inputLabel"
               @input="$emit('update:modelValue', $event.target.value)"/>
      </div>

      <MBPButton class="small" v-if="isMobile() && expanded" @click="close" :label="dictionary.buttons.close"/>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import MBPButton from "./MBPButton";
export default {
  name: "MBPSymbolSearch",
  components: {MBPButton},
  props: ['mobilePopup'],
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    isMobile () {

      return this.mobilePopup && window.innerWidth <= 660;
    },
    close () {
      console.log('c');
      this.expanded = false;
    },
    wrapperClick() {
      if (!this.expanded) {
        this.$refs.searchInput.focus();
        this.expanded = true;
      } else {
        this.expanded = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/general";

.cols {
  @media(max-width: 660px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.expanded {
  width: 100%;
}
.searchButton {
  padding: 5px;
  border-radius: 50px;
  border: 2px solid var(--notficationBorderColor);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchWrapper {
  position: relative;
  z-index: 3;

  @media(max-width: 660px) {
    position: fixed;
    left: 0;
    width: 100%;
    background: var(--tableHoverColor);
    z-index: 99999;
    bottom: 0;
    padding: 15px;
    box-shadow: 0 0 470px 790px rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    border-top: 1px solid var(--notficationBorderColor);

  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .expandable {
    position: relative;
    box-sizing: border-box;
    @media(min-width: 661px) {
      width: 360px;
    }
    &:before {
      content: '';
      position: fixed;
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    //width: 360px;
    width: 100%;
    transition: all .1s ease-in;

    &.expanded {
      width: 100%;
    }

    &.autoWidth {
      width: auto;
    }

    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}

.searchWrapper[dir="rtl"] {
  i {
    right: 10px;
  }

  input {
    padding-right: 30px;
  }
}

.searchWrapper[dir="ltr"] {
  i {
    left: 10px !important;
  }

  input {
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
</style>
