<template>
  <div>
    <div class="row calendarHeadersRow">
      <div class="cell"> {{ dictionary.calendars.headers.symbol }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.dividend }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.exDate }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.payableDate }}</div>
    </div>

    <section v-for="(day, dayIndex) in calendar">
      <div class="calendarDateRow">
        {{ day.format }}
      </div>
      <div v-for="(event, eventIdx) in day.events" :key="event._id" class="row">
        <div class="cell">
          <router-link :to="`/symbol/${event.symbol}`">{{ event.symbol }}</router-link>
        </div>
        <div class="cell center">{{event.dividend}}</div>
        <div class="cell center">{{formatDate(event.date)}}</div>
        <div class="cell center">{{formatDate(event.paymentDate)}}</div>
      </div>
    </section>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: "DividendCalendar",
  props: ['calendar'],
  data() {
    return {}
  },

  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).setLocale(this.dateFormatLocale).toFormat('DD')
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/calendar";


.cell {
  width: 33%;

  a{
    text-decoration: none;
    color: black;
  }
}
</style>
