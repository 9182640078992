<template>
  <section>
    <slot/>
    <div class="overlay" ref="overlay" @click="$emit('close')"/>
  </section>
</template>

<script>
export default {
  name: "OverlayUI",
  props: ['bg'],
  created() {
    if (this.bg) {

      this.$nextTick(() => {
        this.$refs.overlay.style.background = this.bg;
      });
    }
  }
}
</script>

<style scoped lang="scss">

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
  }
</style>
