<template>

  <div>
    <ReportEstimatesChart :symbol="symbol"/>
    <RevenueChart :symbol="symbol"/>
    <RevenueProductChart :symbol="symbol"/>
    <GeographicRevenueChart :symbol="symbol"/>
    <ExpensesChart :symbol="symbol"/>
    <EbitdaChart :symbol="symbol"/>
    <CashEquivalentsChart :symbol="symbol"/>
    <CashDebtChart :symbol="symbol"/>
    <DividendChart :symbol="symbol"/>
  </div>

</template>

<script>
import RevenueProductChart from "../../components/charts/ProductsChart";
import ReportEstimatesChart from "../../components/charts/ReportEstimatesChart";
import RevenueChart from "../../components/charts/RevenueChart";
import ExpensesChart from "../../components/charts/ExpensesChart";
import EbitdaChart from "../../components/charts/EbitdaChart";
import CashDebtChart from "../../components/charts/CashDebtChart";
import CashEquivalentsChart from "../../components/charts/CashEquivalentsChart";
import DividendChart from "../../components/charts/DividendChart";
import GeographicRevenueChart from "../../components/charts/GeographicRevenueChart";
export default {
  name: "PerformancePartial",
  components: {
    GeographicRevenueChart,
    DividendChart,
    CashEquivalentsChart,
    CashDebtChart, EbitdaChart, ExpensesChart, RevenueChart, ReportEstimatesChart, RevenueProductChart},
  props: ['symbol']
}
</script>

<style scoped>

</style>
