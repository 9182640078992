const PATH = '/trades';

export default {
    async getTradePageById(tradeId) {

        return axios.get(`${PATH}/${tradeId}`);
    },
    async getTradesList({filter, page}) {

        return axios.get(`${PATH}/list`, {params: {filter, page}});
    },
}
