const PATH = '/performance';

export default {
    async getCompanyEarnings({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/earnings`, {params: {query}});
    },
    async getCompanyRevnue({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/revenue`, {params: {query}});
    },
    async getGeographicRevenue({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/geographic`, {params: {query}});
    },
    async getCompanyProducts({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/products`, {params: {query}});
    },
    async getCompanyExpenses({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/expenses`, {params: {query}});
    },
    async getCompanyEbitda({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/ebitda`, {params: {query}});
    },
    async getCompanyCash({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/cash`, {params: {query}});
    },
    async getCompanyCashDebt({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/cashDebt`, {params: {query}});
    },
    async getCompanyDividend({symbol, query}) {

        return axios.get(`${PATH}/${symbol}/dividend`, {params: {query}});
    },
    /**
     * @param compare {Array}
     */
    async getSymbolPriceChart({symbol, period, compare = null}) {

        let params = {
            period
        };

        if (compare) params.compare = compare.join(',')
        return axios.get(`${PATH}/${symbol}/chart`, {
            params
        });
    },
    async getSectors() {

        return axios.get(`${PATH}/sectors`);
    }
}
