<template>
  <SymbolLabel :symbol="{symbol: 'TSLA', price: value.price, changeP: value.changeP}" :price-only="value.priceOnly" :always-on="value.alwaysOn"/>
</template>

<script>
import SymbolLabel from "../symbols/symbol";

export default {
  name: "PriceCell",
  components: {SymbolLabel},
  props: ['value']
}
</script>

<style scoped>

</style>
