<template>
  <section>
    <h1>Marketbit.pro</h1>
    <h2>Be the first to know</h2>

    <form id="login" @submit.prevent="login()">
      <input type="password" v-model="password" placeholder="Type password..."/>
      <input type="submit" value="Login" :disabled="!password || waiting"/>

      <p id="invalid" v-if="invalid">Invalid password</p>
    </form>
  </section>
</template>

<script>

import UserService from '../services/user';

export default {
  name: "LockView",
  data() {
    return {
      invalid: false,
      password: null,
      waiting: false
    }
  },
  methods: {
    async login() {

      if (!this.password && this.password.toString().trim().length === 0) {

        return false;
      }

      this.invalid = false;
      try {

        this.waiting = true;
        const result = await UserService.unlockClient(this.password);
        if (result) {
          location.reload();
        }
      } catch (e) {

        this.invalid = true;
      } finally {

        this.waiting = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">


section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #1e1c1d 0, #232122 100%);
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;

  #invalid {
    color: var(--red);
    background: var(--opacityRed);
    padding: 5px 10px;
    border-radius: 8px;
    text-shadow: 1px 1px 5px #1e1c1d;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 0;
    padding: 0;
    color: #0fa;

    @media (max-width: 660px) {
      font-size: 2rem;
    }
  }

  h2 {
    color: #fefefe;
    font-weight: 300;
    margin: 0 auto 5rem;
  }

  form {
    direction: ltr;
    display: block;
    margin: 0 auto;

    input {
      display: block;
      padding: 10px;
      border-radius: 30px;
      border: none;
      font-size: 16px;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
      box-sizing: border-box;

      &:active,
      &:focus {
        border-color: #00ffaa;
        outline: none;
      }
    }

    input[type="submit"] {
      cursor: pointer;
      box-sizing: border-box;
      width: 300px;
      background: var(--mbpGreenOpacity);
      color: var(--mbpGreen);
      position: relative;
      overflow: hidden;
      transition: all .2s ease-in;

      &:hover {
        background: rgba(0, 255, 170, 0.35);
        box-shadow: 0 5px 15px 0 rgba(0, 255, 170, 0.15);
      }
    }
  }
}
</style>
