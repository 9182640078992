<template>
  <div id="marqueeBar">
    <Vue3Marquee :pause-on-hover="true" :clone="true" :duration="90">
      <div v-for="(ticker, tIdx) in tickers" class="marqueeTicker">
        {{ ticker.name }}
        <symbol-label :hide-pre-post="true" :alwaysOn="true" :always-colored="true" :priceOnly="true" :symbol="ticker" :prices="prices.prices"/>
      </div>
    </Vue3Marquee>
  </div>
</template>

<script>
import {Vue3Marquee} from 'vue3-marquee'
import SymbolLabel from "../symbols/symbol";

export default {
  name: "marquee",
  props: ['prices'],
  components: {SymbolLabel, Vue3Marquee},
  data() {
    return {
      tickers: [
        {name: 'S&P 500', symbol: 'GSPC', price: null, changeP: null},
        {name: 'NASDAQ 100', symbol: 'NDX', price: null, changeP: null},
        {name: 'Dow Jones', symbol: 'DJI', price: null, changeP: null},
        // {name: 'Russell 2000', symbol: 'RUT', price: null, changeP: null},
        {name: 'VIX', symbol: 'VIX', price: null, changeP: null},
        {name: 'FTSE 100', symbol: 'FTSE', price: null, changeP: null},
        {name: 'DAX', symbol: 'GDAXI', price: null, changeP: null},
        {name: 'CAC 40', symbol: 'FCHI', price: null, changeP: null},
        {
          name: 'KOSPI Composite',
          symbol: 'KS11',
          price: null,
          changeP: null
        },
        {name: 'HANG SENG', symbol: 'HSI', price: null, changeP: null},
        {name: 'Nikkei 225', symbol: 'N225', price: null, changeP: null},
        {name: 'NIFTY 50', symbol: 'NSEI', price: null, changeP: null},
        {
          name: 'Bitcoin USD',
          symbol: 'BTCUSD',
          price: null,
          changeP: null
        },
        {
          name: 'Ethereum USD',
          symbol: 'ETHUSD',
          price: null,
          changeP: null
        },
        {name: 'XRP USD', symbol: 'XRPUSD', price: null, changeP: null},
        {
          name: 'Tether USD',
          symbol: 'USDTUSD',
          price: null,
          changeP: null
        },
        {name: 'Crude Oil', symbol: 'CLUSD', price: null, changeP: null},
        {name: 'Brent', symbol: 'BZUSD', price: null, changeP: null},
        {name: 'Gold', symbol: 'GCUSD', price: null, changeP: null},
        {name: 'Silver', symbol: 'SIUSD', price: null, changeP: null},
        {name: 'Platinum', symbol: 'PLUSD', price: null, changeP: null}
      ],
      tickers_: {
        US: [
          {name: 'S&P 500', symbol: 'GSPC', price: null, changeP: null},
          {name: 'NASDAQ 100', symbol: 'NDX', price: null, changeP: null},
          {name: 'Dow Jones', symbol: 'DJI', price: null, changeP: null},
          {name: 'Russell 2000', symbol: 'RUT', price: null, changeP: null},
          {name: 'VIX', symbol: 'VIX', price: null, changeP: null}
        ],
        Europe: [
          {name: 'FTSE 100', symbol: 'FTSE', price: null, changeP: null},
          {name: 'DAX', symbol: 'GDAXI', price: null, changeP: null},
          {name: 'CAC 40', symbol: 'FCHI', price: null, changeP: null}
        ],
        Asia: [
          {
            name: 'KOSPI Composite',
            symbol: 'KS11',
            price: null,
            changeP: null
          },
          {name: 'HANG SENG', symbol: 'HSI', price: null, changeP: null},
          {name: 'Nikkei 225', symbol: 'N225', price: null, changeP: null},
          {name: 'NIFTY 50', symbol: 'NSEI', price: null, changeP: null}
        ],
        Crypto: [
          {
            name: 'Bitcoin USD',
            symbol: 'BTCUSD',
            price: null,
            changeP: null
          },
          {
            name: 'Ethereum USD',
            symbol: 'ETHUSD',
            price: null,
            changeP: null
          },
          {name: 'XRP USD', symbol: 'XRPUSD', price: null, changeP: null},
          {
            name: 'Tether USD',
            symbol: 'USDTUSD',
            price: null,
            changeP: null
          }
        ],
        Commodities: [
          {name: 'Crude Oil', symbol: 'CLUSD', price: null, changeP: null},
          {name: 'Brent', symbol: 'BZUSD', price: null, changeP: null},
          {name: 'Gold', symbol: 'GCUSD', price: null, changeP: null},
          {name: 'Silver', symbol: 'SIUSD', price: null, changeP: null},
          {name: 'Platinum', symbol: 'PLUSD', price: null, changeP: null}
        ]
      }

    }
  }
}
</script>

<style scoped lang="scss">


#marqueeBar {
  direction: ltr;
  border-bottom: 2px solid #111;
  background: var(--bgColor);
  @media (max-width: 660px) {
    //margin-top: 45px;
    border-top: 1px solid #1e1c1d;
  }
}

.marqueeTicker {
  padding: 10px;
  font-weight: 500;
}
</style>
