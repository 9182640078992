const PATH = '/macro';

export default {
    async getDailyBrief() {

        return axios.get(`${PATH}/brief/daily`);
    },
    async sectorsPerformance() {

        return axios.get(`${PATH}/sectors`);
    },
    async getSectorsHoldings(symbol) {

        return axios.get(`${PATH}/sectors/${symbol}`);
    }
}
