<template>
  <th :class="{center}" :style="setStyle()">
    <div class="sort" :style="{cursor: sortValue ? 'pointer' : 'default'}" @click="$emit('sort')" :dir="uiDirection">
      <slot/>
      <i class="ri-arrow-down-s-line sortSign" :class="{active: sortKey === sortValue}" v-if="sortDirection === -1 && sortValue === sortKey"/>
      <i class="ri-arrow-up-s-line sortSign" :class="{active: sortKey === sortValue}" v-if="sortDirection === 1 && sortValue === sortKey"/>

    </div>


    <Popper
        arrow
        :hover="true"
        :content="tooltip"
        v-if="tooltip"
    >
      <div class="tooltipIcon">
        <i class="ri-information-line"/>
      </div>
    </Popper>
  </th>
</template>

<script>


import Popper from "vue3-popper";
export default {
  name: "HeadCell",
  props: ['sortKey', 'sortDirection', 'sortValue', 'center', 'width', 'tooltip', 'small'],
  components: { Popper },
  methods: {
    setStyle () {
      return this.width ? {width: `${this.width}px`, overflow: 'hidden'} : {};
    }
  }
}
</script>


<style scoped lang="scss">

@import "../../assets/style/general";

th {
  user-select: none;
  border-bottom: 2px solid var(--notficationBorderColor);
  background: var(--bgColor) !important;
  font-size: 15px;
  font-weight: 600;
}

.center {
  text-align: center !important;
}

.sort {
  padding: 4px 7px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.sortSign {
  color: #aaa;
  opacity: .3;
}

.active {
  color: var(--activeSortSignColor);
  opacity: 1;
}
</style>
