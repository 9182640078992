<template>
  <div class="imageOverlay">
    <img :src="image" alt="'Marketbit.pro'"/>

    <div style="text-align: center">
      <MBPButton :label="dictionary.buttons.close" @click="$emit('close')" class="action centered"/>
    </div>
  </div>
</template>

<script>
import MBPButton from "./controls/MBPButton";
export default {
  name: "ImageOverlay",
  components: {MBPButton},
  props: ['image'],
  methods: {
    close() {

    }
  }
}
</script>

<style scoped lang="scss">


.imageOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  z-index: 99;
  overflow-y: auto;

  img {
    margin: 1rem;
    width: calc(100% - 2rem);
  }
}
</style>
