import {ref} from "vue";

const useIntersectionEntry = () => {
    const observedElement = ref("");
    const elementClass = ref("");
    const callback = ref(() => {})

    const interOptions = (rootPar, thresholdPar) => {
        return {
            root: rootPar,
            threshold: thresholdPar,
        };
    };
    const interCallback = function (entries, cb) {
        if (entries) {
            const [entry] = entries;
            if (entry.isIntersecting) {
                callback.value()
            }
        }
    };
    const observer = new IntersectionObserver(interCallback, interOptions);
    return {
        observedElement,
        elementClass,
        interOptions,
        interCallback,
        observer,
        callback
    };
};
export default useIntersectionEntry;
