<template>
  <span>
    {{value}}
  </span>
</template>

<script>
export default {
  name: "StringCell",
  props: ['value']
}
</script>

<style scoped>

</style>
