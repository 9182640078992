<template>
  <div class="modalContainer">
    <OverlayUI bg="var(--highlightColorOpacityDark)" @close="$emit('close')">
      <div class="modal">
        <div class="modalContent">
          <div class="close" @click="$emit('close')"><i class="ri-close-line"/></div>
          <slot name="title"/>
          <slot name="content"/>
          <slot name="actions"/>
        </div>
      </div>
    </OverlayUI>
  </div>
</template>

<script>
import OverlayUI from "./Overlay";

export default {
  name: "Modal",
  components: {OverlayUI},
  beforeUnmount() {
    document.body.style.overflow = 'unset';
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  }
}
</script>

<style scoped lang="scss">

.modalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: blur(40px);
  overflow-y: auto;
}


.modal {
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--bgColor);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  min-width: 320px;

  .modalContent {
    position: relative;
    .close {
      position: absolute;
      cursor: pointer;
      padding: 10px;
      margin: -10px;
      border-radius: 50%;
    }
  }
}

.modal:deep(h3) {
  text-align: center;
  margin: 0 0 1rem !important;
  padding: 0 !important;
}

.modal:deep(.content) {
  text-align: center;
  font-size: 18px;
  margin: 0 0 1rem !important;
  padding: 0 !important;
}

.modal:deep(.actions) {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

</style>
