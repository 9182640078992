<template>
  <main v-if="news">

    <h1 style="direction: ltr">{{ news.title }}</h1>

    <section id="article">
      <div v-html="news.content"/>


      <image-thumbnail :image-name="news.file.name" v-if="news.file && news.file.type === 'image'"/>

    </section>
  </main>
</template>

<script>

import NewsService from '../services/news';
import ImageThumbnail from "../components/ui/imageThumbnail";

export default {
  name: "ReadView",
  components: {ImageThumbnail},
  data() {
    return {
      news: null,
    }
  },
  methods: {
    async getNewsArticle() {

      const link = this.$route.params.link;
      this.news = await NewsService.readNews({link});
    }
  },
  beforeMount() {
    this.getNewsArticle()
  }
}
</script>

<style scoped lang="scss">

#article {
  padding: 1rem;
  direction: ltr !important;
  background: var(--articleBgColor);
  margin: 0 1rem;
  border-radius: 8px;

  * {
    direction: ltr !important;
  }

  * + * {
    direction: ltr !important;
  }

  font-size: 18px;
  text-align: left;
  line-height: 32px;
}



html[data-theme='dark'] {
  #article:deep {

    .articleTable {
      white-space: nowrap;
      overflow-x: auto;
      table {
        cellspacing: 0;
        cellpadding: 0;
        width: 100%;
      }
      td,tr{
        p,span,div, b,em,strong{
          margin: 0;
          padding: 0;
        }
      }

      tr:hover{
        background: var(--tableHoverColor);
      }

      td{
        border-bottom: 1px solid var(--tableBorderColor);
      }
    }

    a {
      color: var(--lightYellow) !important;
    }

    * {
      a {
        color: var(--lightYellow) !important;
      }
    }

    a, span, li{
      font-weight: 400;
    }

    p,li{
      line-height: 36px;
    }

    li:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    p {
      font-weight: 400;
      img {
        max-width: 100%;
      }

      table {
        width: 100%;
      }
      a {
        color: var(--lightYellow) !important;
      }
    }
  }
}

h1 {
  font-size: 24px;
  padding: 1rem 1rem 0 1rem;
  line-height: 42px;
}
</style>
