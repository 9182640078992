<template>
  <div class="notificationDetails">
    <div class="sourceName" :class="{mbpAi: notification.sourceName === 'Marketbit.pro Trades AI'}" v-if="!isRow && feedType !== 'news'"><i v-if="notification.sourceName === 'Marketbit.pro Trades AI'" class="ri-sparkling-line"/> {{ notification.sourceName }}</div>
    <span v-if="!isRow && feedType !== 'news'" class="sourceName">@</span>
    <div class="sourceName" v-if="!isRow">{{ formatDateTime(notification.datetime) }}</div>
    <div class="translate" v-if="(notification.languages && notification.languages.he) || (isProUI && notification.languages && notification.languages.he && newsLanguages && newsLanguages.length > 1)" @click="$emit('translate')"><i class="ri-translate-2"/> {{translateLanguage}}</div>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: "NotificationDetails",
  props: ['notification', 'originalLanguage', 'isRow', 'feedType'],
  computed: {
    translateLanguage () {

      if (!this.originalLanguage) {
        return this.systemLanguage === 'he' ? 'ENG' : 'עב'
      } else {
        return this.systemLanguage === 'he' ? 'עב' : 'ENG'
      }
    }
  },
  methods: {
    formatDateTime(datetime) {

      return DateTime.fromISO(datetime).setLocale(this.dateFormatLocale).toFormat('DDD HH:mm')
    },
  }
}
</script>

<style scoped lang="scss">

.sourceName {
  color: var(--sourceNameColor);
  &.mbpAi {
    color: var(--green);
    font-weight: 600;
  }
}

.translate {
  cursor: pointer;
  font-size: 14px;
  margin-right: auto;
  background: var(--translateButtonBg);
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
