const PATH = '/users';

export default {
    async pingUser() {

        return axios.get(`${PATH}/ping`);
    },
    async shouldShowNotification(notificationId) {
        return axios.post(`${PATH}/systemNotification/should`, {notificationId});
    },
    async joinWaitingList(email) {
        return axios.post(`${PATH}/waitlist`, {email});
    },
    async seenSystemNotification(notificationId) {
        return axios.post(`${PATH}/systemNotification/seen`, {notificationId});
    },
    async unlockClient(password) {

        return axios.post(`${PATH}/unlock`, {password});
    },
    async premiumLogin(email, password) {

        return axios.post(`${PATH}/login`, {email, password});
    },
    async activateUser(code) {

        return axios.post(`${PATH}/activate`, {code});
    },
    async signupUser({firstName, lastName, email, affiliateCode = null, token, password = null, auth, refClient}) {

        return axios.post(`${PATH}/signup`, {
            firstName,
            lastName,
            email,
            token,
            affiliateCode,
            password,
            auth,
            refClient
        });
    },
    async tagAffiliationUser(affiliateCode) {

        return axios.post(`${PATH}/tagAffiliate`, {affiliateCode});
    },
    async premiumGoogleLogin(token, auth, refClient, affiliateCode) {

        return axios.post(`${PATH}/login/google`, {token, auth, refClient, affiliateCode});
    },
    async forgotPassword(email) {

        return axios.post(`${PATH}/forgotPassword`, {email});

    },
    async setNewPassword({code, password}) {

        return axios.put(`${PATH}/newPassword`, {code, password});
    },
    addSymbolToWatchlist(symbol) {

        return axios.post(`${PATH}/watchlist/${symbol}`);
    },
    removeSymbolFromWatchlist(symbol) {

        return axios.delete(`${PATH}/watchlist/${symbol}`);
    },
    updateSymbolOption(symbol, option, value) {
        return axios.put(`${PATH}/watchlist/${symbol}`, {option, value});
    },
    updateSymbolOptionForAll(option, value) {
        return axios.put(`${PATH}/watchlist/all`, {option, value});
    },
    getUsersWatchlist() {
        return axios.get(`${PATH}/watchlist`);
    },
    acceptTerms() {

        return axios.post(`${PATH}/acceptTerms`)
    },
    updateSystemLanguage(language) {

        return axios.put(`${PATH}/system/language`, {language})
    },
    sendContactUs({email, name, message}) {

        return axios.post(`${PATH}/contactUs`, {email, name, message})
    },
    createSubscription(transactionId, subscriptionType) {

        return axios.post(`${PATH}/subscription`, {transactionId, subscriptionType})
    },
    cancelSubscription() {

        return axios.post(`${PATH}/subscription/cancel`)
    },
    updateNotificationsSettings(settings) {

        return axios.put(`${PATH}/settings/user/notifications`, settings)
    },
    saveUserSettings(settings, section) {

        return axios.put(`${PATH}/settings/user/${section}`, settings);
    },
    getUserSettings() {
        return axios.get(`${PATH}/settings`);
    },
    getAffiliateStats() {

        return axios.get(`${PATH}/affiliate/me`);
    },
    logoutUser() {
        return axios.post(`${PATH}/logout`)
    }
}
